import React from "react";

const STOP_WORDS = new Set([
  "a",
  "an",
  "and",
  "are",
  "as",
  "at",
  "be",
  "by",
  "for",
  "from",
  "has",
  "he",
  "in",
  "is",
  "it",
  "its",
  "of",
  "on",
  "that",
  "the",
  "to",
  "was",
  "were",
  "will",
  "with",
]);

export const processTagValue = (value) => {
  if (value === null || value === undefined) {
    return "Untagged";
  }

  if (typeof value === "string") {
    const cleaned = value.replace(/[[\]"']/g, "").trim();
    return cleaned || "Untagged";
  }

  if (typeof value === "object" && !Array.isArray(value)) {
    if (value.value !== undefined) {
      return processTagValue(value.value);
    }
    return JSON.stringify(value);
  }

  return String(value);
};

export const getHighlightTerms = (pointId, value, evidence) => {
  if (!evidence || !evidence[pointId]) return [];

  const valueStr = Array.isArray(value)
    ? value.map((v) => String(v || "").trim()).filter(Boolean)
    : typeof value === "object"
      ? [String(value.value || "").trim()]
      : [String(value || "").trim()];

  const evidenceStr = String(evidence[pointId] || "");

  const terms = new Set();

  valueStr.forEach((phrase) => {
    if (phrase.includes(" ")) {
      terms.add(phrase);
    }
  });

  const singleWords = [
    ...valueStr.flatMap((v) => v.split(/\s+/)),
    ...evidenceStr.split(/\s+/),
  ].filter((word) => {
    const cleanWord = word.toLowerCase().trim();
    return cleanWord.length > 2 && !STOP_WORDS.has(cleanWord);
  });

  singleWords.forEach((word) => terms.add(word));

  return Array.from(terms);
};

export const highlightText = (text, terms) => {
  if (!text || !terms || terms.length === 0) return text || "";

  try {
    const sortedTerms = terms.sort((a, b) => b.length - a.length);

    const pattern = sortedTerms
      .map((term) => term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
      .join("|");

    const regex = new RegExp(`(${pattern})`, "gi");
    return String(text).replace(regex, '<mark class="bg-yellow-200">$1</mark>');
  } catch (error) {
    console.error("Error highlighting text:", error);
    return text || "";
  }
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
