import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";

const DistributionChart = React.memo(
  ({
    column,
    currentDistributions,
    globalColumnDistributions,
    backendFilters,
    distributionLimit,
    compactMode,
    onDistributionClick,
  }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const chartData = useMemo(() => {
      if (
        !currentDistributions ||
        Object.keys(currentDistributions).length === 0
      ) {
        return {
          chartData: [],
          totalFiles: 0,
          untaggedCount: 0,
          untaggedPercentage: 0,
          hasMore: false,
          maxTotalCount: 0,
        };
      }

      // Create a map to store individual value counts
      const valueCountMap = new Map();

      // Process each entry and split comma-separated values
      Object.entries(currentDistributions)
        .filter(([key]) => key !== "total" && key !== "Untagged")
        .forEach(([value, count]) => {
          const cleanValue = value.trim().replace(/^\[|\]$/g, "");
          const individualValues = cleanValue
            .split(",")
            .map((v) => v.trim())
            .filter((v) => v && v !== "");

          individualValues.forEach((val) => {
            valueCountMap.set(val, (valueCountMap.get(val) || 0) + count);
          });
        });

      // Find the maximum total count across all entries
      const maxTotalCount = Math.max(
        ...Object.values(globalColumnDistributions),
        0,
      );

      // Convert map to array and sort by totalCount (global count)
      const entries = Array.from(valueCountMap.entries()).sort((a, b) => {
        const totalCountA = globalColumnDistributions[a[0]] || 0;
        const totalCountB = globalColumnDistributions[b[0]] || 0;
        return totalCountB - totalCountA;
      });

      const totalFiles = Array.from(valueCountMap.values()).reduce(
        (sum, count) => sum + count,
        0,
      );
      const untaggedCount = currentDistributions["Untagged"] || 0;

      // Calculate start and end indices for current page
      const startIdx = currentPage * distributionLimit;
      const endIdx = startIdx + distributionLimit;

      // Get data for current page
      const data = entries.slice(startIdx, endIdx).map(([value, count]) => ({
        value,
        count,
        totalCount: globalColumnDistributions[value] || 0,
        percentage: ((count / totalFiles) * 100).toFixed(1),
        isSelected: backendFilters[column]?.includes(String(value)),
      }));

      return {
        chartData: data,
        totalFiles,
        untaggedCount,
        untaggedPercentage: ((untaggedCount / totalFiles) * 100).toFixed(1),
        hasMore: entries.length > endIdx,
        hasPrevious: currentPage > 0,
        totalPages: Math.ceil(entries.length / distributionLimit),
        maxTotalCount,
      };
    }, [
      currentDistributions,
      globalColumnDistributions,
      column,
      backendFilters,
      distributionLimit,
      currentPage,
    ]);

    // Handle page navigation
    const handlePageChange = (direction) => {
      if (direction === "more" && chartData.hasMore) {
        setCurrentPage((prev) => prev + 1);
      } else if (direction === "less" && chartData.hasPrevious) {
        setCurrentPage((prev) => prev - 1);
      }
    };

    return (
      <div className={`h-full w-full ${compactMode ? "max-h-[120px]" : ""}`}>
        <ResponsiveContainer width="100%" height={compactMode ? 80 : 65}>
          <BarChart
            data={chartData.chartData}
            margin={
              compactMode
                ? { top: 0, right: 0, bottom: 0, left: 0 }
                : { top: 5, right: 5, bottom: 5, left: 5 }
            }
          >
            <XAxis dataKey="value" hide />
            <YAxis hide domain={[0, chartData.maxTotalCount]} />
            <RechartsTooltip
              cursor={{ fill: "#F3F4F6" }}
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="bg-white p-2 border border-gray-200 rounded shadow-sm text-xs">
                      <p className="font-medium">{`${data.value}`}</p>
                      <p>{`Current view: ${data.count} (${data.percentage}%)`}</p>
                      <p>{`Total: ${data.totalCount}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar
              dataKey="totalCount"
              fill={(entry) =>
                entry.isSelected ||
                backendFilters[column]?.includes(entry.value)
                  ? "#4FA892"
                  : "#e5e7eb"
              }
              onClick={(data) =>
                onDistributionClick(column, data.payload.value)
              }
              cursor="pointer"
            >
              <Bar dataKey="count" fill="#4FA892" opacity={0.3} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <div className="flex items-center justify-between text-xs mt-1">
          {chartData.untaggedCount > 0 && (
            <div className="bg-red-50 text-red-600 px-2 py-1 rounded-md inline-block">
              {chartData.untaggedCount} untagged ({chartData.untaggedPercentage}
              %)
            </div>
          )}

          {(chartData.hasMore || chartData.hasPrevious) && (
            <div className="flex items-center gap-2">
              {chartData.hasPrevious && (
                <button
                  onClick={() => handlePageChange("less")}
                  className="text-primary hover:text-primary/80 transition-colors flex items-center gap-1"
                >
                  <span>Previous</span>
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </button>
              )}
              {chartData.hasMore && (
                <button
                  onClick={() => handlePageChange("more")}
                  className="text-primary hover:text-primary/80 transition-colors flex items-center gap-1"
                >
                  <span>Next</span>
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default DistributionChart;
