import React, { useContext, useEffect, useState } from "react";
import { metadataService } from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { MetricCard, TagDistributionChart } from "../usecaseUtils";
import MetadataTable from "../../MetadataTable/MetadataTable";

export default function UseCaseOverview({
  isOverviewOpen,
  setIsOverviewOpen,
  usecase_id,
  usecase_name,
  useCase,
}) {
  const [metricsData, setMetricsData] = useState(null);
  const [syncScore, setSyncScore] = useState(null);
  const [, setUsecaseFiles] = useState([]);
  const [, setIsLoadingFiles] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const { deasyUserConfig } = useContext(BaseContext);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [isLoadingSyncScore, setIsLoadingSyncScore] = useState(false);
  const [tagDistribution, setTagDistribution] = useState(null);
  const [tableFilters, setTableFilters] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoadingTagDistribution, setIsLoadingTagDistribution] =
    useState(false);

  useEffect(() => {
    const fetchInitialData = () => {
      setIsLoadingMetrics(true);
      setIsLoadingSyncScore(true);
      setIsLoadingTagDistribution(true);

      // Metrics fetch
      metadataService
        .getUsecaseMetrics(
          null,
          null,
          null,
          usecase_id,
          deasyUserConfig.deasyApiKey,
        )
        .then((response) => setMetricsData(response.data))
        .catch((error) => console.error("Failed to fetch metrics data:", error))
        .finally(() => setIsLoadingMetrics(false));

      // Sync score fetch
      metadataService
        .getSyncScore(usecase_id, deasyUserConfig.deasyApiKey)
        .then((response) => setSyncScore(response.data))
        .catch((error) => console.error("Failed to fetch sync score:", error))
        .finally(() => setIsLoadingSyncScore(false));

      // Tag distribution fetch
      metadataService
        .getTagDistribution(usecase_id, deasyUserConfig.deasyApiKey)
        .then((response) => setTagDistribution(response.data.tag_distribution))
        .catch((error) =>
          console.error("Failed to fetch tag distribution:", error),
        )
        .finally(() => setIsLoadingTagDistribution(false));
    };

    fetchInitialData();
  }, [usecase_id, deasyUserConfig.deasyApiKey]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (!showFiles) return;

      setIsLoadingFiles(true);
      try {
        const response = await metadataService.getUsecaseFiles(
          usecase_id,
          deasyUserConfig.deasyApiKey,
        );

        if (response?.data?.files) {
          setUsecaseFiles(response.data.files);
        }
      } catch (error) {
        console.error("Failed to fetch files:", error);
      } finally {
        setIsLoadingFiles(false);
      }
    };

    fetchFiles();
  }, [showFiles, usecase_id, deasyUserConfig.deasyApiKey]);

  const filterDistributionByTags = (distribution) => {
    if (!distribution || selectedTags.length === 0) return distribution;
    return Object.fromEntries(
      Object.entries(distribution).filter(([key]) =>
        selectedTags.includes(key),
      ),
    );
  };

  const TagSelector = ({ distribution, onTagsChange }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    if (!distribution) return null;

    const allTags = Object.keys(distribution).sort((a, b) => {
      const countA = distribution[a];
      const countB = distribution[b];
      return countB - countA; // Sort by count in descending order
    });

    const filteredTags = allTags
      .filter(
        (tag) =>
          tag.toLowerCase().includes(searchQuery.toLowerCase()) &&
          !selectedTags.includes(tag),
      )
      .slice(0, 5); // Limit dropdown to 5 suggestions

    const visibleTags = allTags.filter((tag) =>
      tag.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    const handleTagSelect = (tag) => {
      const newTags = [...selectedTags, tag];
      onTagsChange(newTags);
      setSearchQuery("");
      setShowDropdown(false);
    };

    const handleInputChange = (e) => {
      setSearchQuery(e.target.value);
      setShowDropdown(true);
    };

    const handleInputBlur = () => {
      setTimeout(() => setShowDropdown(false), 200);
    };

    const handleClearAll = () => {
      setSearchQuery("");
      setShowDropdown(false);
      onTagsChange([]);
    };

    return (
      <div className="mb-4 bg-gray-50 p-4 rounded-lg border border-gray-200">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-lg font-semibold text-gray-800">Search Tags</h3>
          <button
            onClick={handleClearAll}
            className={`text-sm ${
              selectedTags.length > 0
                ? "text-red-500 hover:text-red-700"
                : "text-gray-400 cursor-default"
            } flex items-center gap-2 px-3 py-1.5 rounded-md hover:bg-gray-100 transition-all duration-200`}
            disabled={selectedTags.length === 0}
          >
            <span>Clear filters</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              placeholder="Type to search tags..."
              className="w-full px-4 py-2.5 rounded-lg border border-gray-300 bg-white focus:border-primary focus:ring-2 focus:ring-primary/20 outline-none transition-all duration-200 pl-10"
            />
            <svg
              className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>

            {/* Dropdown suggestions */}
            {showDropdown && searchQuery && filteredTags.length > 0 && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                {filteredTags.map((tag) => (
                  <button
                    key={tag}
                    onClick={() => handleTagSelect(tag)}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:bg-gray-50 focus:outline-none first:rounded-t-lg last:rounded-b-lg"
                  >
                    {tag}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Selected tags */}
          {selectedTags.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {selectedTags.map((tag) => (
                <span
                  key={tag}
                  className="px-3 py-1 rounded-full bg-primary text-white text-sm font-medium flex items-center gap-2"
                >
                  {tag}
                  <button
                    onClick={() => {
                      const newTags = selectedTags.filter((t) => t !== tag);
                      onTagsChange(newTags);
                    }}
                    className="opacity-70 hover:opacity-100 transition-opacity duration-200"
                  >
                    <svg
                      className="w-3 h-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}

          {/* Tag options grid */}
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Available Tags
            </h4>
            <div className="max-h-48 overflow-y-auto">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                {visibleTags.map((tag) => (
                  <button
                    key={tag}
                    onClick={() =>
                      !selectedTags.includes(tag) && handleTagSelect(tag)
                    }
                    disabled={selectedTags.includes(tag)}
                    className={`px-3 py-1.5 text-sm rounded-md text-left truncate ${
                      selectedTags.includes(tag)
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-white hover:bg-gray-50 border border-gray-200 hover:border-primary/50 text-gray-700"
                    }`}
                    title={tag}
                  >
                    <span className="truncate">{tag}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-6">
      <button
        onClick={() => setIsOverviewOpen(!isOverviewOpen)}
        className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
      >
        <div className="flex items-center gap-3">
          <h2 className="text-xl font-semibold text-gray-800">
            Summary of{" "}
            <span className="text-primary">{usecase_name || "Use Case"}</span>
          </h2>
        </div>
        <span
          className="transform transition-transform duration-200 text-gray-400"
          style={{ transform: isOverviewOpen ? "rotate(180deg)" : "" }}
        >
          ▼
        </span>
      </button>
      {isOverviewOpen && (
        <div className="p-6 border-t space-y-6">
          <div className="grid grid-cols-5 gap-4">
            <MetricCard
              title="Total Files"
              value={
                isLoadingMetrics ? (
                  <div className="flex items-center justify-center gap-1">
                    <div className="w-3 h-3 border border-primary border-t-transparent rounded-full animate-spin" />
                    <span className="text-sm text-primary">Loading</span>
                  </div>
                ) : (
                  metricsData?.total_file_count.toLocaleString() || null
                )
              }
              onClick={() => setShowFiles(!showFiles)}
              isClickable={true}
              isActive={showFiles}
              isLoading={isLoadingMetrics}
            />
            <MetricCard
              title="Total Nodes"
              value={
                isLoadingMetrics ? (
                  <div className="flex items-center justify-center gap-1">
                    <div className="w-3 h-3 border border-primary border-t-transparent rounded-full animate-spin" />
                    <span className="text-sm text-primary">Loading</span>
                  </div>
                ) : (
                  metricsData?.total_node_count?.toLocaleString() || null
                )
              }
              info="The total number of text segments (nodes) across all files in this use case. Each node represents a distinct section of content."
              isLoading={isLoadingMetrics}
            />
            <MetricCard
              title="Unique Metadata Tags"
              value={
                isLoadingMetrics ? (
                  <div className="flex items-center justify-center gap-1">
                    <div className="w-3 h-3 border border-primary border-t-transparent rounded-full animate-spin" />
                    <span className="text-sm text-primary">Loading</span>
                  </div>
                ) : (
                  metricsData?.unique_metadata_tags || null
                )
              }
              info="The total number of distinct metadata tags used across all files in this use case."
              isLoading={isLoadingMetrics}
            />
            <MetricCard
              title="Total Tags Extracted"
              value={
                isLoadingMetrics ? (
                  <div className="flex items-center justify-center gap-1">
                    <div className="w-3 h-3 border border-primary border-t-transparent rounded-full animate-spin" />
                    <span className="text-sm text-primary">Loading</span>
                  </div>
                ) : (
                  metricsData?.total_metadata_tags || null
                )
              }
              info="The total count of all metadata tags extracted, including duplicates across different files."
              isLoading={isLoadingMetrics}
            />
            <MetricCard
              title="Dataset Sync Score"
              value={
                isLoadingSyncScore ? (
                  <div className="flex items-center justify-center gap-1">
                    <div className="w-3 h-3 border border-primary border-t-transparent rounded-full animate-spin" />
                    <span className="text-sm text-primary">Loading</span>
                  </div>
                ) : syncScore?.deasy_sync_percentage ? (
                  `${(syncScore.deasy_sync_percentage * 100).toFixed(0)}%`
                ) : null
              }
              info={
                <div className="space-y-2 text-left">
                  <p>Datasets sync between Deasy database and you Vector DB</p>
                  <ul className="list-disc pl-4 space-y-1">
                    <li>Higher score = Better synchronization with Deasy</li>
                    <li>
                      Lower score = More inconsistencies between local and Deasy
                      data
                    </li>
                  </ul>
                </div>
              }
              isLoading={isLoadingSyncScore}
            />
          </div>

          {/* MetadataTable Section */}
          {showFiles && (
            <div className="mb-6">
              <MetadataTable
                backendFilters={tableFilters}
                onFiltersChange={setTableFilters}
                usecase_id={usecase_id}
                compact={true}
              />
            </div>
          )}

          {/* Tag Distribution Charts */}
          {metricsData?.metadata_tag_counts_distribution && (
            <div className="space-y-6">
              <TagSelector
                distribution={metricsData.metadata_tag_counts_distribution}
                onTagsChange={setSelectedTags}
              />
              <div className="grid grid-cols-3 gap-6">
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-200">
                  <TagDistributionChart
                    distribution={filterDistributionByTags(
                      metricsData.metadata_tag_counts_distribution,
                    )}
                    title="Deasy Metadata Distribution"
                    isLoading={isLoadingMetrics}
                  />
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-200">
                  <TagDistributionChart
                    distribution={filterDistributionByTags(
                      Object.entries(tagDistribution || {}).reduce(
                        (acc, [key, value]) => {
                          if (!key.endsWith("_file_level")) {
                            acc[key] = value;
                          }
                          return acc;
                        },
                        {},
                      ),
                    )}
                    title="Vector Database Tag Distribution"
                    hideChunkFileOption={true}
                    useCase={useCase}
                    isLoading={isLoadingTagDistribution}
                  />
                </div>
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-200">
                  <TagDistributionChart
                    distribution={filterDistributionByTags(
                      syncScore?.tag_counters,
                    )}
                    title="Tag Sync Status"
                    isSyncChart={true}
                    isLoading={isLoadingSyncScore}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
