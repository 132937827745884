import { useEffect, useContext, useRef } from "react";
import { BaseContext } from "../contexts/BaseContext";
import { metadataService } from "../services/api";

export const useSavedTags = () => {
  const { deasyUserConfig, setDeasyUserConfig, setSavedTags } =
    useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const prevKeyRef = useRef(deasyApiKey);

  useEffect(() => {
    if (deasyApiKey !== prevKeyRef.current) {
      const fetchSavedTags = async () => {
        console.log(
          "fetching saved tags, key changed from",
          prevKeyRef.current,
          "to",
          deasyApiKey,
        );
        if (!deasyApiKey) {
          setSavedTags([]);
          return;
        }

        try {
          const tags = await metadataService.getSavedTags(deasyApiKey);
          setSavedTags(tags.data.tags || []);
        } catch (error) {
          console.error("Failed to fetch saved tags:", error);
          setSavedTags([]);
          setDeasyUserConfig((prev) => ({
            ...prev,
            deasyApiKey: "",
          }));
        }
      };

      fetchSavedTags();
      prevKeyRef.current = deasyApiKey;
    }
  }, [deasyApiKey, setSavedTags, setDeasyUserConfig]);
};
