import { createContext, useState, useEffect } from "react";
import { FaChartLine, FaDatabase } from "react-icons/fa";
import { auth } from "../config/firebase";
import { config } from "../config/config";
import { WorkflowType } from "../models/WorkflowModel";
import { prepareVectorDBConfiguration } from "../services/utils";
import { metadataService } from "../services/api";

export const BaseContext = createContext({
  deasyUserConfig: {},
  savedTags: [],
  setDeasyUserConfig: () => {},
  setSavedTags: () => {},
  vdbFilesCount: null,
  setVdbFilesCount: () => {},
  outOfData: false,
  setOutOfData: () => {},
});

export const BaseProvider = ({ children }) => {
  // Page configuration
  const activePageToData = {
    tokenDashboard: {
      title: "API keys and credits",
      helpDescription: "Manage your Deasy API keys and add credits",
      icon: <FaChartLine className="w-5 h-5" />,
    },
    vectorDatabase: {
      title: "Vector Database",
      helpDescription: "Configure your Vector Database",
      icon: <FaDatabase className="w-5 h-5" />,
    },
  };
  const disabledPages = config.features.disabledPages;
  const [deasyUserConfig, setDeasyUserConfig] = useState({});
  const [savedTags, setSavedTags] = useState([]);
  const [vdbFilesCount, setVdbFilesCount] = useState(null);
  const [loadingVdbFilesCount, setLoadingVdbFilesCount] = useState(false);

  const [activePage, setActivePage] = useState("");
  const [metadataHealth, setMetadataHealth] = useState(null);
  const [explorerLevelSelection, setExplorerLevelSelection] = useState("FILE");
  const [tagInEditor, setTagInEditor] = useState(null);
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(true);
  const [showUnique, setShowUnique] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(WorkflowType.CONNECT_DATA);
  const [useCaseStep, setUseCaseStep] = useState(0);
  const [useCases, setUseCases] = useState([]);
  const [refreshUseCases, setRefreshUseCases] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [useCaseScreen, setUseCaseScreen] = useState(null);

  const [isConfigRefreshing, setIsConfigRefreshing] = useState(false);
  const [outOfData, setOutOfData] = useState(false);

  const refreshUseCasesHook = () => {
    setRefreshUseCases((prev) => !prev);
  };

  const deasyApiKey = deasyUserConfig?.deasyApiKey;

  useEffect(() => {
    if (!deasyApiKey) return;
    const fetchUsecases = async () => {
      try {
        const response = await metadataService.listUsecases(deasyApiKey);
        setUseCases(response);
      } catch (error) {
        console.error("Failed to fetch usecases:", error);
        setUseCases([]);
      }
    };

    fetchUsecases();
  }, [deasyApiKey, refreshUseCases]);

  // Add effect to persist savedTags
  useEffect(() => {
    if (Array.isArray(savedTags)) {
      localStorage.setItem("savedTags", JSON.stringify(savedTags));
    }
  }, [savedTags]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/token") {
      setActivePage("tokenDashboard");
    } else if (path === "/") {
      setActivePage("");
    }
  }, []);

  // Add this effect to load tags when API key is available
  useEffect(() => {
    const loadTags = async () => {
      if (deasyUserConfig.deasyApiKey) {
        try {
          const response = await metadataService.getSavedTags(
            deasyUserConfig.deasyApiKey,
          );
          // Ensure tuned property is always a number
          const processedTags = (response.data.tags || []).map((tag) => ({
            ...tag,
            tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
          }));
          setSavedTags(processedTags);
        } catch (error) {
          console.error("Error loading tags:", error);
          setSavedTags([]);
        }
      }
    };

    loadTags();
  }, [deasyUserConfig.deasyApiKey]);

  // Utility functions
  const signOut = () => {
    auth.signOut();
  };

  const exportData = ({ type = "json" }) => {
    if (!savedTags?.length) return;

    if (type === "json") {
      const jsonData = savedTags.reduce((acc, tag) => {
        const { tag_id, username, created_at, updated_at, ...cleanedTag } = tag;
        acc[tag.name] = cleanedTag;
        return acc;
      }, {});

      const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tags.json";
      a.click();
    }
  };

  const [currentNode, setCurrentNode] = useState(null);
  const [uniqueTags, setUniqueTags] = useState([]);

  const [files, setFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [metadataReadinessStatus, setMetadataReadinessStatus] = useState(false);
  const [unsyncedVDBFilesCount, setUnsyncedVDBFilesCount] = useState(0);
  const getFiles = async ({ newOffset = offset, newLimit = limit }) => {
    try {
      setFetchingFiles(true);
      if (
        !(
          deasyUserConfig.deasyApiKey &&
          deasyUserConfig.vdbmConfig.LastActive &&
          deasyUserConfig.vdbmConfig.Configs?.[
            deasyUserConfig.vdbmConfig.LastActive
          ]
        )
      ) {
        return { entities: [], next_offset: null };
      }
      const vectorDBConfiguration =
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ];

      const response = await metadataService.listPaginated(
        deasyUserConfig.deasyApiKey, // deasyApiKey
        prepareVectorDBConfiguration(vectorDBConfiguration), // vectorDBConfiguration
        newLimit, // limit
        newOffset, // offset
        "file", // groupBy
        files, // entitiesAlreadyScrolled
      );
      const entities = response.entities;
      const next_offset = response.next_offset;

      if (response?.entities) {
        setFiles((prev) => [...new Set([...prev, ...entities])]);
        setOffset(next_offset);
        setLimit(newLimit);

        if (response.next_offset === null) {
          setOutOfData(true);
        }
      }
      return { entities, next_offset };
    } catch (err) {
      console.log("Error fetching files", err);
      return { entities: [], next_offset: null };
    } finally {
      setFetchingFiles(false);
    }
  };

  const handleSaveMetadata = async (formData) => {
    if (!formData.name || !formData.description || !formData.output_type) {
      console.log("Please fill out all fields");
      return;
    }

    try {
      const dataToSave = {
        ...formData,
        output_type: formData.output_type || "word",
        available_values: formData.available_values || [],
        tuned: typeof formData.tuned === "number" ? formData.tuned : 0,
      };

      if (formData.tag_id) {
        await metadataService.updateTag(dataToSave, deasyApiKey);
      } else {
        await metadataService.createTag(dataToSave, deasyApiKey);
      }
    } catch (error) {
      console.error("Error saving tag:", error);
    }
  };

  return (
    <BaseContext.Provider
      value={{
        // Getters
        activePage,
        activePageToData,
        metadataHealth,
        savedTags,
        tagInEditor,
        isTagEditorCollapsed,
        disabledPages,
        deasyUserConfig,
        explorerLevelSelection,
        currentNode,
        files,
        fetchingFiles,
        metadataReadinessStatus,
        unsyncedVDBFilesCount,
        showUnique,
        uniqueTags,
        vdbFilesCount,
        currentScreen,
        useCaseStep,
        useCases,
        selectedUseCase,
        useCaseScreen,
        loadingVdbFilesCount,
        isConfigRefreshing,
        outOfData,
        offset,
        // Setters
        setActivePage,
        setMetadataHealth,
        setSavedTags,
        setTagInEditor,
        setIsTagEditorCollapsed,
        setDeasyUserConfig,
        setExplorerLevelSelection,
        setCurrentNode,
        setFiles,
        setFetchingFiles,
        setMetadataReadinessStatus,
        setUnsyncedVDBFilesCount,
        setShowUnique,
        setUniqueTags,
        setVdbFilesCount,
        setCurrentScreen,
        setUseCaseStep,
        setUseCases,
        setSelectedUseCase,
        setUseCaseScreen,
        setLoadingVdbFilesCount,
        setIsConfigRefreshing,
        setOutOfData,
        setOffset,
        // Functions
        signOut,
        exportData,
        getFiles,
        refreshUseCasesHook,
        handleSaveMetadata,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
