import { useState, useMemo } from "react";
import {
  FaSpinner,
  FaSearch,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";
import TSOutputCard from "./TSOutputCard";

const TaggingStudioOutput = ({
  tsRunning,
  tagInEditor,
  setTagInEditor,
  setActiveTagEditorSection,
  taggingStudioOutput,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedFiles, setExpandedFiles] = useState(new Set());

  // Group results by filename
  const groupedResults = useMemo(() => {
    if (!taggingStudioOutput || !tagInEditor?.name) return {};

    return taggingStudioOutput.reduce((acc, [filename, results]) => {
      const filteredResults = results.filter((result) => {
        const resultKey = Object.keys(result)[0];
        const tagData = result[resultKey]?.[tagInEditor.name] || {};
        const value = String(tagData?.value || "");
        const evidence = String(tagData?.evidence || "");
        const searchLower = searchTerm.toLowerCase();

        return (
          value.toLowerCase().includes(searchLower) ||
          evidence.toLowerCase().includes(searchLower) ||
          filename.toLowerCase().includes(searchLower) ||
          resultKey.toLowerCase().includes(searchLower)
        );
      });

      if (filteredResults.length > 0) {
        acc[filename] = filteredResults;
      }
      return acc;
    }, {});
  }, [taggingStudioOutput, searchTerm, tagInEditor?.name]);

  const toggleFile = (filename) => {
    const newExpanded = new Set(expandedFiles);
    if (newExpanded.has(filename)) {
      newExpanded.delete(filename);
    } else {
      newExpanded.add(filename);
    }
    setExpandedFiles(newExpanded);
  };

  const toggleAll = () => {
    if (expandedFiles.size === Object.keys(groupedResults).length) {
      setExpandedFiles(new Set());
    } else {
      setExpandedFiles(new Set(Object.keys(groupedResults)));
    }
  };

  // Empty state component
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
      <AiFillAlert className="w-12 h-12 text-emerald-400 mb-2" />
      <h2 className="text-xl font-semibold text-gray-800">
        No Output Available
      </h2>
      <p className="text-sm text-gray-500 max-w-md">
        To get started, please:
        <ol className="mt-2 text-left list-decimal pl-4 space-y-1">
          <li>Select a tag from the list or create a new one</li>
          <li>Configure your tag settings</li>
          <li>Select the files you want to analyze</li>
          <li>Run the tag test</li>
        </ol>
      </p>
    </div>
  );

  // Loading state component
  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <FaSpinner className="w-8 h-8 text-emerald-500 animate-spin mb-4" />
      <h3 className="text-lg font-medium text-gray-900">Processing Files</h3>
      <p className="text-sm text-gray-500 mt-2">
        This may take a few moments...
      </p>
    </div>
  );

  return (
    <div className="w-full h-full bg-white border rounded-lg shadow">
      <div className="p-4 border-b border-gray-200 bg-gray-50 rounded-t-lg">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold text-gray-800">Results</h2>
          {Object.keys(groupedResults).length > 0 && (
            <button
              onClick={toggleAll}
              className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              {expandedFiles.size === Object.keys(groupedResults).length
                ? "Collapse All"
                : "Expand All"}
            </button>
          )}
        </div>

        {!tsRunning && taggingStudioOutput?.length > 0 && (
          <div className="relative">
            <input
              type="text"
              placeholder="Search results..."
              className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          </div>
        )}
      </div>

      <div className="overflow-y-auto max-h-[70vh] border-b border-gray-200">
        {tsRunning ? (
          <LoadingState />
        ) : !taggingStudioOutput ||
          !tagInEditor?.name ||
          taggingStudioOutput.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="divide-y divide-gray-200">
            {Object.entries(groupedResults).map(([filename, results]) => (
              <div key={filename} className="bg-white">
                <button
                  onClick={() => toggleFile(filename)}
                  className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 text-left"
                >
                  <span className="font-medium text-gray-700">{filename}</span>
                  {expandedFiles.has(filename) ? (
                    <FaChevronUp className="text-gray-400" />
                  ) : (
                    <FaChevronDown className="text-gray-400" />
                  )}
                </button>

                {expandedFiles.has(filename) && (
                  <div className="px-4 py-2 space-y-2">
                    {results.map((result, resultIndex) => {
                      const pointId = Object.keys(result)[0];
                      return (
                        <TSOutputCard
                          key={resultIndex}
                          value={result[pointId]?.[tagInEditor?.name]?.value}
                          evidence={
                            result[pointId]?.[tagInEditor?.name]?.evidence
                          }
                          filename={filename}
                          pointId={pointId}
                          setActiveTagEditorSection={setActiveTagEditorSection}
                          setTagInEditor={setTagInEditor}
                          tagInEditor={tagInEditor}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaggingStudioOutput;
