import FileSelectionDropdown from "./components/FileSelectionDropdown";
import TaggingStudioOutput from "./components/TaggingStudioOutput";
import ActionBar from "./components/ActionBar";
import { useState, useContext, useEffect } from "react";
import TagEditor from "../SavedTags/components/TagEditor/TagEditor";
import TagSelector from "../SavedTags/components/TagSelector";
import { BaseContext } from "../../../contexts/BaseContext";
import { TestTubeDiagonal } from "lucide-react";

const TaggingStudio = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isTagSelectorExpanded, setIsTagSelectorExpanded] = useState(false);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");

  const [taggingStudioOutput, setTaggingStudioOutput] = useState(null);
  const {
    savedTags,
    tagInEditor,
    setTagInEditor,
    isTagEditorCollapsed,
    setIsTagEditorCollapsed,
  } = useContext(BaseContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (savedTags) {
      setIsLoading(false);
    }
  }, [savedTags]);

  return (
    <div className="h-full p-6">
      {isTagSelectorExpanded && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsTagSelectorExpanded(false)}
          />
          <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50 w-[90%] h-[90%] max-w-7xl bg-white rounded-xl shadow-2xl overflow-hidden">
            <button
              onClick={() => setIsTagSelectorExpanded(false)}
              className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors duration-200 z-50"
              title="Collapse"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="h-full">
              <TagSelector
                expanded={true}
                tagInEditor={tagInEditor}
                setTagInEditor={setTagInEditor}
                isTagEditorCollapsed={isTagEditorCollapsed}
                setIsTagEditorCollapsed={setIsTagEditorCollapsed}
                isLoading={isLoading}
                setIsTagSelectorExpanded={setIsTagSelectorExpanded}
              />
            </div>
          </div>
        </>
      )}

      <div className="bg-white h-full rounded-xl shadow-sm border border-gray-200">
        <div className="w-full px-6 py-4 text-left font-medium flex items-center justify-between bg-gray-50 rounded-xl transition-colors duration-200">
          <div className="flex items-center gap-3">
            <TestTubeDiagonal size={24} className="text-primary" />
            <h2 className="text-xl font-semibold text-gray-800">
              Test Metadata
            </h2>
          </div>
        </div>

        <div className="transition-all duration-300 ease-in-out overflow-y-auto max-h-[90vh]">
          <div className="px-4 py-4 border-t border-gray-100">
            <div className="grid grid-cols-3 gap-x-8 mb-2">
              <div className="flex flex-col gap-y-1">
                <h3 className="text-lg font-medium">
                  1. Select and configure your tag
                </h3>
                <p className="text-sm text-gray-500">
                  Select and configure the tag to your liking.
                </p>
              </div>
              <div className="flex flex-col gap-y-1">
                <h3 className="text-lg font-medium">2. Select testing data</h3>
                <p className="text-sm text-gray-500">
                  Select the files you want to test the tag on.
                </p>
              </div>
              <div className="flex flex-col gap-y-1">
                <h3 className="text-lg font-medium">3. Fine tune your tag</h3>
                <p className="text-sm text-gray-500">
                  Fine tune your tag to your liking.
                </p>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-x-8">
              <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                <TagEditor
                  collapseDirection="vertical"
                  disableCollapse={true}
                  tagInEditor={tagInEditor}
                  setTagInEditor={setTagInEditor}
                  isTagEditorCollapsed={isTagEditorCollapsed}
                  setIsTagEditorCollapsed={setIsTagEditorCollapsed}
                  setIsTagSelectorExpanded={setIsTagSelectorExpanded}
                  activeTagEditorSection={activeTagEditorSection}
                  setActiveTagEditorSection={setActiveTagEditorSection}
                />
              </div>

              <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                <FileSelectionDropdown
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>

              <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                <TaggingStudioOutput
                  tagInEditor={tagInEditor}
                  setTagInEditor={setTagInEditor}
                  setActiveTagEditorSection={setActiveTagEditorSection}
                  taggingStudioOutput={taggingStudioOutput}
                  setTaggingStudioOutput={setTaggingStudioOutput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        selectedFiles={selectedFiles}
        tagInEditor={tagInEditor}
        setTaggingStudioOutput={setTaggingStudioOutput}
      />
    </div>
  );
};

export default TaggingStudio;
