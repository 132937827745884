import { useState } from "react";
import { createPortal } from "react-dom";

export const ValueSelectionModal = ({
  onClose,
  onSelect,
  onAddNew,
  availableValues = [],
}) => {
  const [newValue, setNewValue] = useState("");

  return createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold mb-4">Add Value</h3>

        {/* New Value Input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter new value..."
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <button
            className="mt-2 w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
            onClick={() => {
              if (newValue.trim()) {
                onAddNew(newValue.trim());
                onClose();
              }
            }}
          >
            Add New Value
          </button>
        </div>

        {/* Existing Values */}
        <div className="mt-6 border rounded-lg overflow-hidden">
          <h4 className="font-medium px-4 py-2 bg-gray-50 border-b">
            Existing Available Values
          </h4>
          <div className="max-h-60 overflow-y-auto">
            {availableValues.map((value, index) => (
              <button
                key={index}
                className="w-full px-4 py-2.5 text-left hover:bg-gray-50 text-gray-700 border-b last:border-b-0 transition-colors duration-150"
                onClick={() => {
                  onSelect(value);
                  onClose();
                }}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        <button
          className="mt-4 px-4 py-2 text-gray-600 hover:bg-gray-50 border border-gray-200 rounded-md w-full"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>,
    document.body,
  );
};
