import { useState, useMemo } from "react";
import { FaTrashAlt, FaCheck, FaEllipsisV } from "react-icons/fa";
import { useContext } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../services/api";
import TestTagButton from "../../../../utils/TestTagButton";
import { TableHeader, renderAvailableTagValues } from "./TagUtils";

const TagsTable = ({
  hideNewTagButton = false,
  forceExpanded = false,
  tagInEditor,
  setTagInEditor,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  compressedTagTable = false,
  onTagSelect,
  selectedTags = [],
  showDeleteButton = true,
  availableTags = [],
  isLoading = false,
  setIsTagSelectorExpanded = null,
}) => {
  let columns = ["Name", "Description", "Available Values", "Actions"];
  const tagTableCompressed = !forceExpanded && compressedTagTable;
  if (tagTableCompressed) {
    columns = [];
  }

  const { savedTags, setSavedTags, deasyUserConfig } = useContext(BaseContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleTagSelect = (tag) => {
    if (onTagSelect) {
      onTagSelect(tag);
      return;
    }

    setTagInEditor(tag);
    setIsTagEditorCollapsed(!isTagEditorCollapsed);
    if (setIsTagSelectorExpanded) {
      setIsTagSelectorExpanded(false);
    }
  };

  // Filter unique tags by name
  const uniqueTags = useMemo(() => {
    const uniqueTagMap = new Map();
    availableTags.forEach((tag) => {
      if (!uniqueTagMap.has(tag.name)) {
        uniqueTagMap.set(tag.name, tag);
      }
    });
    return Array.from(uniqueTagMap.values());
  }, [availableTags]);

  const filteredTags = useMemo(() => {
    if (!uniqueTags || uniqueTags.length === 0) {
      return [];
    }

    // Filter unique tags by search term
    return uniqueTags
      .filter(
        (tag) =>
          tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tag?.description?.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [uniqueTags, searchTerm]);

  const totalPages = Math.ceil(filteredTags.length / rowsPerPage);

  const handleDelete = async (tag) => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete the tag "${tag.name}"?`,
        )
      ) {
        return;
      }

      if (tag.tag_id) {
        await metadataService.deleteTag(
          tag.tag_id,
          deasyUserConfig.deasyApiKey,
        );
        const response = await metadataService.getSavedTags(
          deasyUserConfig.deasyApiKey,
        );
        setSavedTags(response.data.tags || []);
      } else {
        setSavedTags((prev) => prev.filter((t) => t.name !== tag.name));
      }

      if (
        tagInEditor?.tag_id === tag.tag_id ||
        tagInEditor?.name === tag.name
      ) {
        setTagInEditor({});
      }
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const toggleDropdown = (tagId, e) => {
    e.stopPropagation();
    setActiveDropdown(activeDropdown === tagId ? null : tagId);
  };

  return (
    <div className={`bg-white rounded-lg shadow-md flex flex-col h-full`}>
      <div className="flex-shrink-0">
        <TableHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          hideNewTagButton={hideNewTagButton}
        />
      </div>

      <div className={`flex-1 overflow-auto relative`}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              {columns.map((header) => (
                <th
                  key={header}
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 ${
                    ["Name", "Available Values"].includes(header)
                      ? "max-w-[25%] min-w-[15%] w-fit"
                      : ""
                  }`}
                >
                  {header === "Actions" ? "" : header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative z-0">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-12">
                  <div className="flex flex-col items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-2"></div>
                    <span className="text-gray-500">Loading tags...</span>
                  </div>
                </td>
              </tr>
            ) : !Array.isArray(savedTags) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  Error loading tags
                </td>
              </tr>
            ) : filteredTags.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  No tags found
                </td>
              </tr>
            ) : (
              filteredTags
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tag) => {
                  const isSelected = selectedTags.some(
                    (t) => t.tag_id === tag.tag_id,
                  );
                  return (
                    <tr
                      key={tag.tag_id || tag.name}
                      onClick={() => handleTagSelect(tag)}
                      className={`cursor-pointer transition-all duration-200 ${
                        isSelected
                          ? "bg-primary-50 hover:bg-primary-100 border-l-4 border-primary"
                          : "hover:bg-gray-50"
                      }`}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex items-center">
                          {isSelected && (
                            <FaCheck className="text-primary mr-2 flex-shrink-0" />
                          )}
                          <span
                            className={`text-sm ${isSelected ? "font-medium text-primary-900" : "text-gray-900"}`}
                          >
                            {tag.name}
                          </span>
                        </div>
                      </td>
                      {!tagTableCompressed && (
                        <>
                          <td className="px-3 py-1 text-left">
                            {tag.description || ""}
                          </td>
                          <td className="px-6 pr-0 py-1 text-left">
                            {renderAvailableTagValues(tag)}
                          </td>
                          <td className="p-2 whitespace-nowrap text-right text-sm font-medium flex items-center justify-center">
                            {showDeleteButton && (
                              <>
                                <button
                                  onClick={(e) =>
                                    toggleDropdown(tag.tag_id || tag.name, e)
                                  }
                                  className="p-1 rounded-full hover:bg-gray-100"
                                  title="More options"
                                >
                                  <FaEllipsisV className="w-2 h-4 text-gray-500" />
                                </button>

                                {activeDropdown ===
                                  (tag.tag_id || tag.name) && (
                                  <div className="absolute right-0 mt-1 w-24 shadow-lg rounded-md bg-white ring-1 ring-black ring-opacity-5 z-[9999]">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(tag);
                                        setActiveDropdown(null);
                                      }}
                                      className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center gap-2 rounded-t-md"
                                      role="menuitem"
                                    >
                                      <FaTrashAlt className="w-3.5 h-3.5" />
                                      <span>Delete</span>
                                    </button>
                                    <TestTagButton
                                      tagId={tag.tag_id || tag.name}
                                      className="bg-white w-full border-none rounded-none px-4 py-2"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex-shrink-0 bg-white px-4 py-2 flex items-center justify-between border-t border-gray-200 rounded-b-lg">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              className="border border-gray-300 rounded-md text-sm p-1"
            >
              {[50, 100, 200, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setPage(Math.max(0, page - 1))}
              disabled={page === 0}
              className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {Math.max(1, totalPages)}
            </span>
            <button
              onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
              disabled={page >= totalPages - 1}
              className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsTable;
