import { useContext } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../../services/api";
import { toast } from "react-hot-toast";

export const UseCaseSelect = ({ onCreateNew, onUseCaseSelect }) => {
  const { deasyUserConfig, useCases, refreshUseCasesHook } =
    useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const handleDeleteUseCase = async (useCaseId, e) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this use case?")) {
      try {
        await metadataService.deleteUsecase(useCaseId, deasyApiKey);
        refreshUseCasesHook();
        toast.success("Use case deleted successfully");
      } catch (error) {
        toast.error("Failed to delete use case");
        console.error("Delete use case error:", error);
      }
    }
  };

  return (
    <div className="px-6 pt-2">
      {useCases.length === 0 ? (
        <div className="bg-white rounded-lg border border-gray-200 p-8 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No Data Slices Yet
          </h3>
          <p className="text-gray-500 mb-4">
            Create your first data slice to start organizing your data.
          </p>
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto rounded-lg">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "20%" }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "35%" }}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Number of files
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Last Updated
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "15%" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {useCases.map((useCase) => (
                  <tr
                    key={useCase.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => onUseCaseSelect(useCase)}
                  >
                    <td
                      className="px-6 py-4 text-sm font-medium text-gray-900 text-left"
                      style={{ width: "20%" }}
                    >
                      <div className="truncate">{useCase.name}</div>
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "35%" }}
                    >
                      <div className="truncate">{useCase.description}</div>
                    </td>
                    <td
                      className="px-6 py-4 text-left"
                      style={{ width: "10%" }}
                    >
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          useCase.status === "active"
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {useCase.status}
                      </span>
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "10%" }}
                    >
                      {useCase.dataPoints.toLocaleString()}
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "10%" }}
                    >
                      {new Date(useCase.lastUpdated).toLocaleString()}
                    </td>
                    <td
                      className="px-6 py-4 text-sm font-medium text-right"
                      style={{ width: "15%" }}
                    >
                      <div className="flex justify-end items-center gap-2">
                        <button
                          onClick={() => onUseCaseSelect(useCase)}
                          className="inline-flex items-center px-2 py-1 border border-primary text-primary hover:bg-primary hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          Export
                        </button>
                        <button
                          onClick={(e) => handleDeleteUseCase(useCase.id, e)}
                          className="inline-flex items-center px-2 py-1 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
