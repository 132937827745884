import {
  AiOutlinePlusCircle,
  AiFillPlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { Position, Handle } from "reactflow";
import { useContext, useState } from "react";
import { NodeInfoModal } from "./NodeInfoModal";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { useDrop } from "react-dnd";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import TestTagButton from "../../../../../utils/TestTagButton";
import { createPortal } from "react-dom";
import { ValueSelectionModal } from "./AvailableValueSelector";

export const findNodeStats = (stats, nodePath) => {
  if (!stats || !nodePath) return null;

  let current = stats;

  for (const pathPart of nodePath) {
    if (!current) return null;

    // For root node
    if (pathPart === "root") {
      return current;
    }

    // Look for matching node in children
    if (current.children) {
      current = current.children.find((child) => child.name === pathPart);
    } else {
      return null;
    }
  }

  return current;
};

const DeleteConfirmationModal = ({ onConfirm, onCancel, nodeName }) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
        <h3 className="text-lg font-semibold mb-4">Delete {nodeName}</h3>
        <p className="mb-6 text-gray-600">
          Are you sure you want to delete this node and all its children? This
          action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-md bg-danger text-white hover:bg-danger/90"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const handleConfirmNodeDelete = (setShowDeleteModal, data) => {
  if (data.onDeleteNode) {
    data.onDeleteNode(data.id);
  }
  setShowDeleteModal(false);
};

// Custom node components
export const QuestionNode = ({ data }) => {
  const showControls = !data.hideControls;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTunedModal, setShowTunedModal] = useState(false);
  const { handleSaveGraph } = useContext(GraphContext);
  const { savedTags } = useContext(BaseContext);
  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "question");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [showValueModal, setShowValueModal] = useState(false);

  const handleTagClick = (e) => {
    // Prevent all default behaviors and propagation
    e.preventDefault();
    e.stopPropagation();
    if (!showModal) {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${
        isOver ? "bg-gray-100 rounded-lg p-2" : ""
      } overflow-visible`}
      style={{ position: "relative", zIndex: 0 }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex gap-2 pb-1">
        {showControls && (
          <>
            <button
              className="text-danger"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteModal(true);
              }}
            >
              <AiOutlineMinusCircle size={24} style={{ strokeWidth: 1 }} />
            </button>
            <TestTagButton tagId={data.label} />
          </>
        )}
      </div>
      <div
        className="flex flex-col items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="rounded-sm border-2 border-gray-500 bg-gray-100 px-4 py-1 cursor-pointer hover:bg-gray-200 transition-colors"
          onClick={handleTagClick}
        >
          <div className="font-medium text-gray-700">{data.label}</div>
          <div className="text-sm text-gray-500 text-center italic">Tag</div>
        </div>

        {showControls && (
          <div className="relative mt-2">
            <button
              className="text-primary hover:scale-110 transition-all"
              onClick={(e) => {
                e.stopPropagation();
                setShowValueModal(true);
              }}
            >
              <AiOutlinePlusCircle size={24} />
            </button>

            {showValueModal && (
              <ValueSelectionModal
                onClose={() => setShowValueModal(false)}
                onSelect={(value) => {
                  data.onAddAvailableValue?.(data, value);
                }}
                onAddNew={(value) => {
                  data.onAddAvailableValue?.(data, value);
                }}
                availableValues={
                  savedTags.find((t) => t.name === data.label)
                    ?.available_values || []
                }
              />
            )}
          </div>
        )}

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>

      {showModal && (
        <NodeInfoModal
          nodeData={data}
          nodeType="question"
          onClose={handleModalClose}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          nodeName={data.label}
          onConfirm={() => {
            handleConfirmNodeDelete(setShowDeleteModal, data);
            handleSaveGraph();
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}

      {showTunedModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Fine-tuned Tag</h3>
              <p className="mb-6 text-gray-600">
                This tag has been fine-tuned to improve its accuracy and
                performance.
              </p>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
                  onClick={() => setShowTunedModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

export const ValueNode = ({ data }) => {
  const {
    selectedNodeData,
    hierarchyStats,
    selectedNodes,
    setSelectedNodes,
    activeOverlappingNode,
  } = useContext(GraphContext);
  const { isConfigRefreshing } = useContext(BaseContext);
  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;

  const showControls = !data.hideControls;
  const isSelected = selectedNodes.some((node) => node.id === data.id);

  const nodeStats = findNodeStats(hierarchyStats, data.nodePath);

  const handleAddClick = () => {
    data.onAddNode(data, "value");
  };

  const handleRemoveClick = () => {
    data.onRemoveAvailableValue(data);
  };

  const handleNodeClick = (e, nodeStats) => {
    e.stopPropagation();
    // Prevent event bubbling to parent nodes
    e.preventDefault();
    setSelectedNodes((prev) => {
      const newSelection = [...prev];
      const nodeIndex = newSelection.findIndex((node) => node.id === data.id);

      if (nodeIndex !== -1) {
        // Remove just this node
        return newSelection.filter((node) => node.id !== data.id);
      }
      // If node is not selected, select only this node
      else {
        // Add only the clicked node
        return [
          ...newSelection,
          {
            id: data.id,
            data: {
              nodePath: data.nodePath,
              label: data.label,
            },
            type: data.type,
            stats: {
              fileCount: nodeStats?.file_count || 0,
              percentage: nodeStats?.percentage || 0,
            },
          },
        ];
      }
    });
  };

  // Add click handler to the container div to prevent bubbling
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "value");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  // console.log("Valuenode",data)
  return (
    <div
      ref={drop}
      onClick={handleContainerClick}
      className={`flex flex-col items-center ${isOver ? "bg-primary/10 rounded-lg p-2" : ""}`}
    >
      <div className="flex flex-col items-center">
        <div className="flex gap-2">
          {showControls && data.label !== "<ANY>" && (
            <button
              className={`text-danger hover:scale-110 transition-all ${
                nodeIsSelected
                  ? "bg-danger text-white rounded-full shadow-lg animate-pulse"
                  : "hover:bg-danger/10 rounded-full"
              }`}
              onClick={handleRemoveClick}
            >
              {nodeIsSelected ? (
                <AiOutlineMinusCircle size={28} className="p-0.5" />
              ) : (
                <AiOutlineMinusCircle size={24} />
              )}
            </button>
          )}
        </div>
        <div
          onClick={(e) => handleNodeClick(e, nodeStats)}
          className={`rounded-sm border border-gray-300 bg-white px-4 py-1 cursor-pointer ${data.id === activeOverlappingNode ? "ring-8" : ""}
            ${isSelected ? "ring-2 ring-primary ring-offset-2" : ""}`}
        >
          <div className="font-medium text-gray-600">{data.label}</div>
          <div className="text-sm text-gray-400 italic">Value</div>
          {data.isOutOfSync && data.label !== "<ANY>" && (
            <div
              className="mt-1 text-xs text-warning bg-warning/10 px-2 py-1 rounded
              shadow-lg border border-warning/50"
            >
              This value is not included in the parent's Available Values and
              will not be triggered
            </div>
          )}
          {data.label !== "<ANY>" && (
            <div className="text-sm text-gray-500 text-center">
              {isConfigRefreshing ? (
                <div className="flex items-center justify-center gap-1">
                  <div className="w-3 h-3 border-2 border-gray-300 border-t-transparent rounded-full animate-spin" />
                </div>
              ) : (
                <>
                  <div>
                    {nodeStats ? nodeStats.file_count.toLocaleString() : "0"}
                  </div>
                  <div>
                    {nodeStats ? nodeStats.percentage.toFixed(1) + "%" : "0.0%"}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex gap-2">
          {showControls && data.label !== "<ANY>" && (
            <button
              className={`text-primary hover:scale-110 transition-all ${
                nodeIsSelected
                  ? "bg-primary text-white rounded-full shadow-lg animate-pulse"
                  : "hover:bg-primary/10 rounded-full"
              }`}
              onClick={handleAddClick}
            >
              {nodeIsSelected ? (
                <AiFillPlusCircle size={28} className="p-0.5" />
              ) : (
                <AiOutlinePlusCircle size={24} />
              )}
            </button>
          )}
        </div>
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  );
};

export const RootNode = ({ data }) => {
  const {
    setCurrentNode,
    vdbFilesCount,
    loadingVdbFilesCount,
    isConfigRefreshing,
  } = useContext(BaseContext);
  const { setSidePanelOpen, selectedNodeData } = useContext(GraphContext);

  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;
  const showControls = !data.hideControls;

  const handleAddRootChild = () => {
    setCurrentNode(data);
    setSidePanelOpen(true);
    data.onAddNode(data, "root");
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "root");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const totalFiles = vdbFilesCount?.total_files || 0;
  const taggedCount = data.datasetSize || 0;

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${isOver ? "bg-gray-100 rounded-lg p-2" : ""}`}
    >
      <div className="rounded-xl border-3 border-primary bg-primary bg-opacity-10 border border-primary/90 px-8 py-3 shadow-lg transform hover:scale-105 transition-all">
        {loadingVdbFilesCount || isConfigRefreshing ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-4 h-4 border-2 border-primary border-t-transparent rounded-full animate-spin" />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div className="text-2xl font-bold text-primary text-center">
              {totalFiles.toLocaleString()}
            </div>
            <span className="text-base text-primary/80 font-semibold block text-center">
              Total Files
            </span>
            <div className="text-lg font-bold text-primary/70 text-center mt-2">
              {taggedCount.toLocaleString()}
            </div>
            <span className="text-sm text-primary/60 font-semibold block text-center">
              Tagged Files
            </span>
          </>
        )}
      </div>
      <div className="flex flex-row gap-2 mt-1">
        {showControls && (
          <button
            className={`text-primary hover:scale-110 transition-all flex items-center gap-2 ${
              nodeIsSelected
                ? "bg-primary text-white rounded-full shadow-lg animate-pulse px-4 py-2"
                : "hover:bg-primary/10 rounded-full px-4 py-2"
            }`}
            onClick={handleAddRootChild}
            title={`Add new node to "${data.label}" tag`}
          >
            {nodeIsSelected ? (
              <>
                <AiFillPlusCircle size={18} />
                <span className="text-sm font-medium">Add Metadata</span>
              </>
            ) : (
              <>
                <AiOutlinePlusCircle size={18} />
                <span className="text-sm font-medium">Add Metadata</span>
              </>
            )}
          </button>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export const nodeTypes = {
  question: QuestionNode,
  value: ValueNode,
  root: RootNode,
};
