// Components
const ProgressBar = ({ value, active }) => (
  <div className="w-full bg-gray-200 rounded-full h-4">
    <div className="relative w-full">
      <div
        className={`h-4 rounded-full transition-all duration-300 ease-in-out bg-primary ${
          value < 1 && active ? "animate-pulse" : ""
        }`}
        style={{ width: `${Math.min(100, value * 100)}%` }}
      />
      <div className="absolute inset-0 flex items-center justify-center text-xs font-medium text-gray-700">
        {Math.round(value * 100)}%
      </div>
    </div>
  </div>
);

// Helper functions
const formatTimeRemaining = (elapsedMs, progress) => {
  if (progress <= 0) return null;

  const estimatedTotalMs = elapsedMs / progress;
  const remainingMs = estimatedTotalMs - elapsedMs;
  const remainingDays = Math.floor(remainingMs / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (remainingMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const remainingMins = Math.floor(
    (remainingMs % (1000 * 60 * 60)) / (1000 * 60),
  );

  return [
    remainingDays && `${remainingDays}d`,
    remainingHours && `${remainingHours}h`,
    `${remainingMins}m`,
  ]
    .filter(Boolean)
    .join(" ");
};

const formatElapsedTime = (elapsedMs) => {
  const elapsedDays = Math.floor(elapsedMs / (1000 * 60 * 60 * 24));
  const elapsedHours = Math.floor(
    (elapsedMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const elapsedMins = Math.floor((elapsedMs % (1000 * 60 * 60)) / (1000 * 60));

  return [
    elapsedDays && `${elapsedDays}d`,
    elapsedHours && `${elapsedHours}h`,
    `${elapsedMins}m`,
  ]
    .filter(Boolean)
    .join(" ");
};

const formatDateTime = (dateString) => {
  return new Date(dateString).toLocaleString([], {
    dateStyle: "short",
    timeStyle: "short",
  });
};

const getCurrentTimeInUTC = () => {
  return new Date(
    new Date().getTime() + new Date().getTimezoneOffset() * 60000,
  );
};

export {
  ProgressBar,
  formatTimeRemaining,
  formatElapsedTime,
  formatDateTime,
  getCurrentTimeInUTC,
};
