import { useContext, useState } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { FiPlay } from "react-icons/fi";
import { metadataService } from "../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../services/utils";
import { toast } from "react-hot-toast";

const ActionBar = ({ selectedFiles, tagInEditor, setTaggingStudioOutput }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];

  const handleTestTagging = async () => {
    if (!tagInEditor?.name || selectedFiles.length === 0) {
      alert("Please select a tag and at least one file to test");
      return;
    }

    setIsLoading(true);
    try {
      const result = await metadataService.classify(
        prepareVectorDBConfiguration(vectorDBConfiguration), // vectorDBConfiguration
        prepareEndpointManagerConfig(llmEndpointConfiguration), // llmEndpointConfiguration
        selectedFiles, // filenames
        { [tagInEditor?.name]: tagInEditor }, // tags
        deasyApiKey, // deasyApiKey
        true, // soft_run
        true, // overwrite
        {}, // currentTree
        null, // jobId
        false, // generateFileLevelTags
        null, // usecaseId
        true, // blockingCall
      );
      setTaggingStudioOutput(result.data.output.file_results);
      toast.success("Tagging test completed successfully", { duration: 3000 });
    } catch (error) {
      console.error("Error testing tag:", error);
      toast.error("An error occurred while testing the tag", {
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !tagInEditor?.name || selectedFiles.length === 0;

  return (
    <div className="fixed bottom-6 right-6 z-30">
      <button
        className={`btn flex items-center gap-2 rounded-lg shadow-lg transition-all duration-200 ${
          isLoading || isDisabled
            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
            : "bg-primary hover:bg-primary-dark text-white font-medium"
        }`}
        onClick={handleTestTagging}
        disabled={isLoading || isDisabled}
      >
        {isLoading ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
        ) : (
          <FiPlay className="w-10 h-10" />
        )}
        <span className="text-xl">Test Tag</span>
      </button>
    </div>
  );
};

export default ActionBar;
