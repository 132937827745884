import React, { useState, useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import {
  ChevronDown,
  ChevronUp,
  Settings,
  Database,
  Brain,
} from "lucide-react";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import { metadataService } from "../../../services/api";
import { prepareVectorDBConfiguration } from "../../../services/utils";
import { auth } from "../../../config/firebase";
import { DB_OPTIONS } from "../DeasyConfig/ConfigElements/utils";

const WorkflowContextBanner = () => {
  const {
    deasyUserConfig,
    setDeasyUserConfig,
    refreshHierarchyStats,
    setVdbFilesCount,
    setLoadingVdbFilesCount,
    isConfigRefreshing,
    setIsConfigRefreshing,
    setFiles,
    setOffset,
    setOutOfData,
  } = useContext(BaseContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
  } = deasyUserConfig;

  // Handle hover events
  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  const fetchTotalVdbFiles = async (vectorDBConfiguration) => {
    try {
      setLoadingVdbFilesCount(true);

      // Validate configuration first
      const dbOption = DB_OPTIONS.find(
        (opt) => opt.value === vectorDBConfiguration?.type,
      );
      const hasRequiredFields = dbOption?.fields
        .filter((field) => !field.toLowerCase().includes("key"))
        .every((field) => vectorDBConfiguration?.[field]);

      if (!dbOption || !hasRequiredFields) {
        return;
      }

      const response = await metadataService.getVdbTotalFiles(
        prepareVectorDBConfiguration({
          ...vectorDBConfiguration,
          user: auth.currentUser.email,
        }),
        deasyUserConfig.deasyApiKey,
      );

      setVdbFilesCount({
        total_files: response.data.total_files,
        profile: vdbmLastActive,
      });
    } catch (error) {
      console.error("Error fetching total VDB files:", error);
    } finally {
      setLoadingVdbFilesCount(false);
    }
  };

  // Handle config changes
  const handleConfigChange = async (type, value) => {
    setIsConfigRefreshing(true);
    const newConfig = {
      ...deasyUserConfig,
      [type]: {
        ...deasyUserConfig[type],
        LastActive: value,
      },
    };
    setDeasyUserConfig(newConfig);

    try {
      if (type === "vdbmConfig") {
        await fetchTotalVdbFiles(newConfig.vdbmConfig.Configs[value]);
        setFiles([]);
        setOffset(0);
        setOutOfData(false);
      }
      await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
    } catch (error) {
      console.error("Error refreshing after config change:", error);
    } finally {
      setIsConfigRefreshing(false);
    }
  };

  const getVdbLogo = (type) => {
    switch (type) {
      case "QdrantVectorDBManager":
        return "/images/vdb-logos/qdrant.png";
      case "PineconeVectorDBManager":
        return "/images/vdb-logos/pinecone.png";
      case "PSQLVectorDBManager":
        return "/images/vdb-logos/postgresql.png";
      case "S3DataSourceManager":
        return "/images/vdb-logos/s3.png";
      default:
        return null;
    }
  };

  const getLlmLogo = (type) => {
    switch (type) {
      case "openai":
        return "/images/llm-logos/openai.png";
      case "deasy_compute":
        return "/images/llm-logos/deasy_shrimp.png";
      default:
        return null;
    }
  };

  return (
    <div
      className={`relative bg-white border-b border-gray-200 transition-all duration-300 ease-in-out
        ${isExpanded ? "shadow-lg pb-6" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Main banner content */}
      <div className="px-6 py-3 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Settings size={16} className="text-gray-400" />
            <span className="text-sm text-gray-600">Active Configurations</span>
            {isConfigRefreshing && (
              <CircularProgress size={16} className="text-primary ml-2" />
            )}
          </div>

          {/* Current selections - only visible when not expanded */}
          {!isExpanded && (
            <div className="flex items-center gap-4 text-sm text-gray-500">
              <div className="flex items-center gap-2">
                <img
                  src={getVdbLogo(vdbmConfigs[vdbmLastActive]?.type)}
                  alt={vdbmConfigs[vdbmLastActive]?.type}
                  className="w-4 h-4"
                />
                <span>{vdbmLastActive}</span>
              </div>
              <span className="text-gray-300">|</span>
              <div className="flex items-center gap-2">
                <img
                  src={getLlmLogo(llmConfigs[llmLastActive]?.llmType)}
                  alt={llmConfigs[llmLastActive]?.llmType}
                  className="w-4 h-4"
                />
                <span>{llmLastActive}</span>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center gap-1 text-gray-400">
          <span className="text-xs">
            {isExpanded ? "Release to collapse" : "Hover to expand"}
          </span>
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
      </div>

      {/* Expanded content */}
      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out
          ${isExpanded ? "max-h-48 opacity-100" : "max-h-0 opacity-0"}`}
      >
        <div className="grid grid-cols-2 gap-6">
          {/* Vector DB Configuration */}
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Database size={16} className="text-primary" />
              <label className="text-sm font-medium text-gray-700">
                Vector Database Configuration
              </label>
            </div>
            <div className="relative">
              <Select
                value={vdbmLastActive}
                onChange={(e) =>
                  handleConfigChange("vdbmConfig", e.target.value)
                }
                size="small"
                fullWidth
                className="bg-white"
                disabled={isConfigRefreshing}
              >
                {Object.entries(vdbmConfigs).map(([configName, config]) => (
                  <MenuItem key={configName} value={configName}>
                    <div className="flex items-center gap-2">
                      <img
                        src={getVdbLogo(config.type)}
                        alt={config.type}
                        className="w-4 h-4"
                      />
                      <span>{configName}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {isConfigRefreshing && (
                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                  <CircularProgress size={16} className="text-primary" />
                </div>
              )}
            </div>
          </div>

          {/* LLM Configuration */}
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Brain size={16} className="text-primary" />
              <label className="text-sm font-medium text-gray-700">
                LLM Configuration
              </label>
            </div>
            <div className="relative">
              <Select
                value={llmLastActive}
                onChange={(e) =>
                  handleConfigChange("llmConfig", e.target.value)
                }
                size="small"
                fullWidth
                className="bg-white"
                disabled={isConfigRefreshing}
              >
                {Object.entries(llmConfigs).map(([configName, config]) => (
                  <MenuItem key={configName} value={configName}>
                    <div className="flex items-center gap-2">
                      <img
                        src={getLlmLogo(config.llmType)}
                        alt={config.llmType}
                        className="w-4 h-4"
                      />
                      <span>{configName}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {isConfigRefreshing && (
                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                  <CircularProgress size={16} className="text-primary" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowContextBanner;
