import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { BaseContext } from "../../contexts/BaseContext";
import { GraphProvider } from "../../contexts/GraphContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const AppLayout = ({ children }) => {
  const [selectedMainItem, setSelectedMainItem] = useState("workflows");
  const { setActivePage, activePage } = useContext(BaseContext);

  useEffect(() => {
    // Set initial navigation state based on active page
    if (activePage === "tokenDashboard") {
      setSelectedMainItem("tokens");
    } else if (activePage === "vectorDatabase") {
      setSelectedMainItem("connections");
    } else if (activePage === "workflows") {
      setSelectedMainItem("workflows");
    } else if (activePage === "metadata") {
      setSelectedMainItem("metadata");
    } else if (activePage === "metadata-studio") {
      setSelectedMainItem("metadata-studio");
    } else if (activePage === "progress-tracking") {
      setSelectedMainItem("progress-tracking");
    }
  }, [activePage]);

  const handleMainNavigation = (item) => {
    setSelectedMainItem(item);
    if (item === "connections") {
      setActivePage("vectorDatabase");
    } else if (item === "tokens") {
      setActivePage("tokenDashboard");
    } else if (item === "workflows") {
      setActivePage("workflows");
    } else if (item === "metadata") {
      setActivePage("metadata");
    } else if (item === "metadata-studio") {
      setActivePage("metadata-studio");
    } else if (item === "progress-tracking") {
      setActivePage("progress-tracking");
    } else {
      setActivePage("");
    }
  };

  return (
    <div className="flex h-screen bg-white overflow-auto">
      <MainNavigation
        selectedItem={selectedMainItem}
        onItemSelect={handleMainNavigation}
      />

      <div className="flex-1 bg-gray-50 flex flex-row overflow-auto h-screen">
        {selectedMainItem === "workflows" ? (
          <DndProvider backend={HTML5Backend}>
            <GraphProvider>
              <div className="flex-1 h-screen">{children}</div>
            </GraphProvider>
          </DndProvider>
        ) : (
          <GraphProvider>
            <div className="flex-1 h-screen">{children}</div>
          </GraphProvider>
        )}
      </div>
    </div>
  );
};

export default AppLayout;
