import React, { useState, useContext, useEffect, useCallback } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { unstructuredService } from "../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../services/utils";
import { v4 as uuidv4 } from "uuid";
import { useJobProgress } from "./useJobProgress";
import { LLM_OPTIONS } from "./LLMConfig";

const UnstructuredProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
  showActions = true,
}) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];
  const vectorDBConfiguration = deasyUserConfig.vdbmConfig.Configs[name];

  const [syncedPercentage, setSyncedPercentage] = useState(0);
  const [synced, setSynced] = useState(0);
  const [notSynced, setNotSynced] = useState(0);
  const [outOfSync, setOutOfSync] = useState(0);

  const [syncing, setSyncing] = useState(false);
  const [jobId, setJobId] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUpdatingSyncStats, setIsUpdatingSyncStats] = useState(false);

  const { progress: syncProgress } = useJobProgress({
    jobId,
    isRunning: syncing,
    apiKey: deasyApiKey,
    onComplete: async () => {
      setSyncing(false);
      setJobId(null);
      await updateSyncStats();
    },
    onError: () => {
      setSyncing(false);
      setJobId(null);
    },
    successMessage: "Syncing files completed successfully",
  });

  const runSync = async () => {
    setSyncing(true);
    const newJobId = uuidv4();
    unstructuredService.ingestUnstructuredData(
      deasyApiKey,
      prepareEndpointManagerConfig(llmEndpointConfiguration),
      prepareVectorDBConfiguration(vectorDBConfiguration),
      newJobId,
    );
    setJobId(newJobId);
  };

  const updateSyncStats = useCallback(async () => {
    setIsUpdatingSyncStats(true);
    try {
      const result = await unstructuredService.getSyncStats(
        deasyApiKey,
        prepareVectorDBConfiguration(vectorDBConfiguration),
      );
      setSyncedPercentage(result.data.synced_percentage);
      setSynced(result.data.synced);
      setNotSynced(result.data.not_synced);
      setOutOfSync(result.data.out_of_sync);
    } catch (error) {
      console.error("Error updating sync stats", error);
    } finally {
      setIsUpdatingSyncStats(false);
    }
  }, [deasyApiKey, vectorDBConfiguration]);

  useEffect(() => {
    let isMounted = true;

    const fetchStats = async () => {
      if (isDropdownOpen) {
        setIsUpdatingSyncStats(true);
        try {
          if (isMounted) {
            await updateSyncStats();
          }
        } finally {
          if (isMounted) {
            setIsUpdatingSyncStats(false);
          }
        }
      }
    };

    fetchStats();

    return () => {
      isMounted = false;
    };
  }, [isDropdownOpen, updateSyncStats]);

  return (
    <div
      className={`group relative p-6 rounded-xl border-2 transition-all cursor-pointer text-left shadow-sm hover:shadow-md
        ${
          isActive
            ? "border-primary bg-primary-50/70"
            : "border-gray-100 hover:border-emerald-200 bg-white"
        }`}
      onClick={() => onSelect(name)}
    >
      {showActions && (
        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="flex gap-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(name);
              }}
              className="p-1.5 text-gray-500 hover:text-emerald-600 hover:bg-emerald-50 rounded-lg transition-colors"
              title="Edit profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(name);
              }}
              className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              title="Delete profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      <div className="mb-4">
        <h3 className="font-semibold text-gray-900 text-lg">{name}</h3>
        <span className="text-sm text-gray-500 font-medium">{type}</span>
      </div>

      <div className="space-y-2">
        {Object.entries(details).map(([key, value]) => {
          if (key === "Type") return null;
          return (
            <div key={key} className="text-sm flex items-center">
              <span className="text-gray-500 font-medium min-w-[100px]">
                {key}:{" "}
              </span>
              <span className="text-gray-700">
                {key.toLowerCase().includes("key") ? "••••••" : value}
              </span>
            </div>
          );
        })}
        <details
          className="group/details"
          open={isDropdownOpen}
          onToggle={(e) => setIsDropdownOpen(e.target.open)}
        >
          <summary
            className="text-sm flex items-center gap-2 cursor-pointer list-none"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 transition-transform group-open/details:rotate-90"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-gray-700 font-medium">Sync Status</span>
          </summary>

          <div
            className="pt-3 text-sm"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="text-sm flex items-center gap-3">
              <span className="text-gray-500 font-medium min-w-[90px]">
                Sync Percentage:
              </span>
              <div className="relative flex-1 h-3 bg-gray-100 rounded-full overflow-hidden">
                <div
                  className={`h-full transition-all duration-500 ${
                    isUpdatingSyncStats
                      ? "bg-gray-400 animate-pulse"
                      : syncing
                        ? "bg-gray-400"
                        : "bg-emerald-500"
                  }`}
                  style={{ width: `${syncedPercentage * 100}%` }}
                />
              </div>
              <span className="text-sm font-medium text-gray-600 text-right">
                {isUpdatingSyncStats ? (
                  <div className="flex gap-1">
                    <span className="animate-bounce">.</span>
                    <span
                      className="animate-bounce"
                      style={{ animationDelay: "0.1s" }}
                    >
                      .
                    </span>
                    <span
                      className="animate-bounce"
                      style={{ animationDelay: "0.2s" }}
                    >
                      .
                    </span>
                  </div>
                ) : (
                  Math.round(syncedPercentage * 100) + "%"
                )}
              </span>
            </div>

            {/* Stats Row */}
            <div className="flex gap-4 mb-4 mt-2">
              <div
                className="flex-1 bg-green-50 rounded-lg p-2"
                title="Files synced and loaded to Deasy"
              >
                <div className="text-green-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    synced.toLocaleString()
                  )}
                </div>
                <div className="text-xs text-green-500">Files synced</div>
              </div>

              <div
                className="flex-1 bg-orange-50 rounded-lg p-2"
                title="Files not yet OCR'd and loaded to Deasy"
              >
                <div className="text-orange-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    notSynced.toLocaleString()
                  )}
                </div>
                <div className="text-xs text-orange-500">Files not synced</div>
              </div>
              <div
                className="flex-1 bg-red-50 rounded-lg p-2"
                title="Files that exist in Deasy but are no longer present in the external source"
              >
                <span className="text-red-600 font-medium">
                  {isUpdatingSyncStats ? (
                    <div className="flex gap-1">
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.1s" }}
                      >
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "0.2s" }}
                      >
                        .
                      </span>
                    </div>
                  ) : (
                    outOfSync.toLocaleString()
                  )}
                </span>
                <div className="text-xs text-red-500">Files out of sync</div>
              </div>
            </div>

            {/* Progress Bar and Sync Button */}
            <div className="space-y-0.5">
              <div className="flex items-center gap-3">
                <div className="flex-1">
                  {syncing && (
                    <div className="relative h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-emerald-500 transition-all duration-500"
                        style={{ width: `${syncProgress * 100}%` }}
                      />
                    </div>
                  )}
                </div>
                <button
                  className={`px-3 py-1 rounded-lg transition-colors font-medium ${
                    syncing ||
                    isUpdatingSyncStats ||
                    (syncedPercentage === 1 &&
                      notSynced === 0 &&
                      outOfSync === 0)
                      ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                      : "bg-emerald-50 text-emerald-600 hover:bg-emerald-100"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      syncing ||
                      isUpdatingSyncStats ||
                      (syncedPercentage === 1 &&
                        notSynced === 0 &&
                        outOfSync === 0)
                    )
                      return;
                    runSync();
                  }}
                  title={
                    syncedPercentage === 1
                      ? "Connector is fully synced"
                      : "Sync connector with Deasy"
                  }
                >
                  {syncing ? "Syncing..." : "Sync Connector"}
                </button>
              </div>
              {syncing && (
                <div className="text-center text-xs font-medium text-gray-600">
                  {Math.round(syncProgress * 100)}%
                </div>
              )}
            </div>
          </div>
        </details>
      </div>

      {isActive && (
        <div className="absolute top-4 right-4 group-hover:opacity-0 transition-opacity pointer-events-none">
          <span className="px-2.5 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full ring-1 ring-emerald-200">
            Active
          </span>
        </div>
      )}
    </div>
  );
};

const VDBProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
  showActions = true,
}) => {
  const provider = LLM_OPTIONS.find((opt) => opt.value === type)?.value;
  const isDeasyCompute = provider === "deasy_compute";
  return (
    <div
      className={`group relative p-6 rounded-xl border-2 transition-all cursor-pointer text-left shadow-sm hover:shadow-md
        ${
          isActive
            ? "border-primary bg-primary-50/70"
            : "border-gray-100 hover:border-emerald-200 bg-white"
        }`}
      onClick={() => onSelect(name)}
    >
      {showActions && !isDeasyCompute && (
        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="flex gap-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(name);
              }}
              className="p-1.5 text-gray-500 hover:text-emerald-600 hover:bg-emerald-50 rounded-lg transition-colors"
              title="Edit profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(name);
              }}
              className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              title="Delete profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      <div className="mb-4">
        <h3 className="font-semibold text-gray-900 text-lg">{name}</h3>
        <span className="text-sm text-gray-500 font-medium">{type}</span>
      </div>

      {true && (
        <div className="space-y-2">
          {Object.entries(details).map(([key, value]) => {
            if (key === "Type") return null;
            return (
              <div key={key} className="text-sm flex items-center">
                <span className="text-gray-500 font-medium min-w-[100px]">
                  {key}:{" "}
                </span>
                <span className="text-gray-700">
                  {key.toLowerCase().includes("key") ? "••••••" : value}
                </span>
              </div>
            );
          })}
        </div>
      )}

      {isActive && (
        <div className="absolute top-4 right-4 group-hover:opacity-0 transition-opacity pointer-events-none">
          <span className="px-2.5 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full ring-1 ring-emerald-200">
            Active
          </span>
        </div>
      )}
    </div>
  );
};

export const ProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
  showActions = true,
}) => {
  if (type === "S3") {
    return (
      <UnstructuredProfileCard
        type={type}
        name={name}
        isActive={isActive}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
        details={details}
        showActions={showActions}
      />
    );
  } else {
    return (
      <VDBProfileCard
        type={type}
        name={name}
        isActive={isActive}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
        details={details}
        showActions={showActions}
      />
    );
  }
};
