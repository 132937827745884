import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Paper, Switch, Chip } from "@mui/material";
import { FaTimes, FaGripVertical } from "react-icons/fa";

const MetadataColumnSelector = ({
  availableColumns,
  selectedColumns,
  setSelectedColumns,
  showAllColumns,
  setShowAllColumns,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter out file_name from available columns
  const selectableColumns = availableColumns.filter(
    (col) => col !== "file_name",
  );

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedColumns(items);
  };

  const addColumn = (column) => {
    if (!selectedColumns.includes(column)) {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  const removeColumn = (column) => {
    setSelectedColumns(selectedColumns.filter((c) => c !== column));
  };

  const handleShowAllToggle = (e) => {
    const newShowAll = e.target.checked;
    setShowAllColumns(newShowAll);

    if (!newShowAll) {
      setSelectedColumns([]);
    }
  };

  const filteredAvailableColumns = selectableColumns.filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Get selected columns without file_name for display
  const displaySelectedColumns = selectedColumns.filter(
    (col) => col !== "file_name",
  );

  return (
    <Paper className="mb-4" elevation={0} sx={{ backgroundColor: "#f9fafb" }}>
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Switch
                checked={showAllColumns}
                onChange={handleShowAllToggle}
                size="small"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#4FA892",
                    "&:hover": {
                      backgroundColor: "rgba(79, 168, 146, 0.08)",
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#4FA892",
                  },
                }}
              />
              <span className="text-sm text-gray-700">Show all metadata</span>
            </div>
            <input
              type="text"
              placeholder="Search metadata..."
              className="px-3 py-1.5 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={showAllColumns}
            />
          </div>
          <div className="text-xs text-gray-500 ml-3">
            {showAllColumns
              ? selectableColumns.length
              : displaySelectedColumns.length}{" "}
            columns selected
          </div>
        </div>
      </div>

      <div className="max-h-[200px] overflow-y-auto p-4">
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium text-gray-500 mb-1 text-left">
            {showAllColumns ? "All Available Columns" : "Selected Columns"}
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="selected-columns" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-wrap gap-2 min-h-[40px] p-2 bg-white rounded-md border border-gray-200"
                >
                  {(showAllColumns
                    ? selectableColumns
                    : displaySelectedColumns
                  ).map((column, index) => (
                    <Draggable
                      key={column}
                      draggableId={column}
                      index={index}
                      isDragDisabled={showAllColumns}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="inline-flex"
                        >
                          <Chip
                            label={column}
                            onDelete={
                              showAllColumns
                                ? undefined
                                : () => removeColumn(column)
                            }
                            deleteIcon={
                              !showAllColumns && <FaTimes size={10} />
                            }
                            icon={
                              !showAllColumns && (
                                <FaGripVertical
                                  size={10}
                                  className="text-gray-400"
                                />
                              )
                            }
                            sx={{
                              backgroundColor: "#f8f9fa",
                              color: "grey",
                              opacity: showAllColumns ? 0.7 : 1,
                              "& .MuiChip-deleteIcon": {
                                color: "#6B7280",
                                "&:hover": { color: "#EF4444" },
                              },
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {!showAllColumns && (
            <>
              <div className="text-xs font-medium text-gray-500 mt-4 mb-1 text-left">
                Available Columns
              </div>
              <div className="flex flex-wrap gap-2">
                {filteredAvailableColumns
                  .filter((column) => !selectedColumns.includes(column))
                  .map((column) => (
                    <Chip
                      key={column}
                      label={column}
                      onClick={() => addColumn(column)}
                      sx={{
                        backgroundColor: "white",
                        border: "1px solid #e5e7eb",
                        color: "#374151",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f3f4f6",
                        },
                      }}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default MetadataColumnSelector;
