import React, { useState, useContext, useRef, useEffect } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaTrash,
  FaEdit,
  FaSearch,
} from "react-icons/fa";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { graphService } from "../../../../../../services/api";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { motion, AnimatePresence } from "framer-motion";

const GraphSelector = ({ handleRefresh }) => {
  const dropdownRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editingGraph, setEditingGraph] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    graphs,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    setGraphs,
  } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsExpanded(false);
        setEditingGraph(null);
        setIsCreatingNew(false);
      }
    };

    if (isExpanded) {
      document.addEventListener("mousedown", handleClickOutside);
      const handleEscape = (e) => {
        if (e.key === "Escape") {
          setIsExpanded(false);
          setEditingGraph(null);
          setIsCreatingNew(false);
        }
      };
      document.addEventListener("keydown", handleEscape);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleEscape);
      };
    }
  }, [isExpanded]);

  const handleCreateGraph = async () => {
    setIsExpanded(true);
    setIsCreatingNew(true);
    const newGraphName = `Graph ${graphs.length + 1}`;
    const newDiscoveryGraph = {
      graph_id: String(uuidv4()),
      graph_name: newGraphName,
      graph_description: "",
      graph_data: {},
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    setEditingGraph(newDiscoveryGraph);
  };

  const handleDeleteGraph = async (graphId, e) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this graph?")) {
      await graphService.deleteGraph(graphId, deasyUserConfig.deasyApiKey);
      setGraphs((prev) => prev.filter((g) => g.graph_id !== graphId));
      if (selectedDiscoveryGraph?.graph_id === graphId) {
        setSelectedDiscoveryGraph(null);
      }
    }
  };
  const handleEditGraph = async (e) => {
    e.preventDefault();
    try {
      await graphService.createUpdateGraph(
        deasyUserConfig.deasyApiKey,
        editingGraph.graph_id,
        editingGraph.graph_name,
        editingGraph.graph_description,
        editingGraph.graph_data,
      );

      if (isCreatingNew) {
        setSelectedDiscoveryGraph(editingGraph);
        setGraphs((prev) => [...prev, editingGraph]);
      } else {
        setGraphs((prev) =>
          prev.map((g) =>
            g.graph_id === editingGraph.graph_id ? editingGraph : g,
          ),
        );
      }
      setEditingGraph(null);
      setIsCreatingNew(false);
      setIsExpanded(false);
      handleRefresh();
    } catch (error) {
      console.error("Error updating graph:", error);
    }
  };

  const handleStartEdit = (graph, e) => {
    e.stopPropagation();
    setEditingGraph({ ...graph });
  };

  const filteredGraphs = graphs.filter((graph) => {
    const query = searchQuery.toLowerCase();
    return (
      graph.graph_name.toLowerCase().includes(query) ||
      graph.graph_description.toLowerCase().includes(query)
    );
  });

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <div className="flex items-center gap-2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex-1 flex items-center justify-between px-3 py-2 text-gray-700 hover:bg-gray-50 rounded-md border border-gray-200 transition-colors duration-200"
        >
          <span className="font-medium">
            {selectedDiscoveryGraph?.graph_name || "Select a Graph"}
          </span>
          {isExpanded ? (
            <FaChevronUp className="ml-2" />
          ) : (
            <FaChevronDown className="ml-2" />
          )}
        </button>
        <button
          onClick={handleCreateGraph}
          className="px-3 py-2 bg-white text-primary border border-primary rounded-md hover:bg-primary hover:text-white transition-colors duration-200 flex items-center gap-1"
        >
          <FaPlus size={12} />
          <span>New Graph</span>
        </button>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <>
            <div
              className="fixed inset-0 bg-black/5 z-40"
              onClick={() => {
                setIsExpanded(false);
                setEditingGraph(null);
                setIsCreatingNew(false);
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-xl ring-1 ring-black/5"
            >
              <AnimatePresence>
                {editingGraph && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    className="p-3 border-b bg-gray-50/80 backdrop-blur-sm"
                  >
                    <form onSubmit={handleEditGraph} className="space-y-2">
                      <input
                        autoFocus
                        type="text"
                        value={editingGraph.graph_name}
                        onChange={(e) =>
                          setEditingGraph({
                            ...editingGraph,
                            graph_name: e.target.value,
                          })
                        }
                        className="w-full px-2 py-1 text-sm border rounded"
                        placeholder="Graph Name"
                      />
                      <textarea
                        value={editingGraph.graph_description}
                        onChange={(e) =>
                          setEditingGraph({
                            ...editingGraph,
                            graph_description: e.target.value,
                          })
                        }
                        className="w-full px-2 py-1 text-sm border rounded"
                        placeholder="Description"
                        rows={2}
                      />
                      <div className="flex gap-2">
                        <button
                          type="submit"
                          className="px-2 py-1 text-sm bg-primary text-white rounded hover:bg-emerald-700"
                        >
                          {isCreatingNew ? "Create" : "Save"}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setEditingGraph(null);
                            setIsCreatingNew(false);
                            if (isCreatingNew) setIsExpanded(false);
                          }}
                          className="px-2 py-1 text-sm border rounded hover:bg-gray-50"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>

              <div className="p-3 border-b">
                <div className="relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search graphs..."
                    className="w-full pl-10 pr-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
                  />
                </div>
              </div>

              <div className="max-h-[20vh] overflow-y-auto w-[40vw] shadow-inner">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Graph Name
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Updated
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredGraphs.map((graph) => (
                      <tr
                        key={graph.graph_id}
                        onClick={() => {
                          setSelectedDiscoveryGraph(graph);
                          setIsExpanded(false);
                        }}
                        className={`cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
                          selectedDiscoveryGraph?.graph_id === graph.graph_id
                            ? "bg-emerald-50"
                            : ""
                        }`}
                      >
                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          {graph.graph_name}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-500">
                          {graph.graph_description}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(graph.created_at), "MMM d, yyyy")}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                          {format(new Date(graph.updated_at), "MMM d, yyyy")}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium">
                          <div className="flex gap-2">
                            <button
                              onClick={(e) => handleStartEdit(graph, e)}
                              className="p-1 rounded-full text-gray-400 hover:text-blue-500 hover:bg-blue-50/50 transition-colors duration-200"
                              title="Edit graph"
                            >
                              <FaEdit className="h-3 w-3" />
                            </button>
                            <button
                              onClick={(e) =>
                                handleDeleteGraph(graph.graph_id, e)
                              }
                              className="p-1 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50/50 transition-colors duration-200"
                              title="Delete graph"
                            >
                              <FaTrash className="h-3 w-3" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {filteredGraphs.length === 0 && (
                      <tr>
                        <td
                          colSpan="5"
                          className="px-4 py-4 text-center text-gray-500"
                        >
                          No graphs found matching your search.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GraphSelector;
