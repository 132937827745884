import React, { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import ConnectDataScreen from "./screens/ConnectDataScreen";
import ViewMetadataTab from "./screens/DataDiscovery/ViewMetadata/ViewMetadataTab";
import { ExtractMetadataTab } from "./screens/DataDiscovery/ExtractMetadataTab";
import DataSliceManagementScreen from "./screens/UseCase/DataSliceManagementScreen";
import WorkflowContextBanner from "./WorkflowContextBanner";
import WorkflowProgress from "../../utils/WorkflowProgress";
import { WorkflowType } from "../../../models/WorkflowModel";

const WorkflowScreens = () => {
  const { currentScreen, metadataTree } = useContext(BaseContext);

  const getTitle = () => {
    switch (currentScreen) {
      case WorkflowType.VIEW_METADATA:
        return "View Metadata";
      case WorkflowType.EXTRACT_METADATA:
        return "Extract Metadata";
      case WorkflowType.CONNECT_DATA:
        return "Connect your data";
      case WorkflowType.DATA_SLICE_MANAGEMENT:
        return "Data Slices";
      default:
        return "";
    }
  };

  const getDescription = () => {
    switch (currentScreen) {
      case WorkflowType.VIEW_METADATA:
        return "Explore and analyze your metadata structure.";
      case WorkflowType.EXTRACT_METADATA:
        return "Define and organize how your metadata should be structured. Extract and process metadata from your datasets.";
      case WorkflowType.CONNECT_DATA:
        return "Connect your data to Deasy to start powering your workflows.";
      case WorkflowType.DATA_SLICE_MANAGEMENT:
        return "Create and manage data slices to power your use cases.";
      default:
        return "";
    }
  };

  const renderScreen = () => {
    // Common header and progress bar wrapper
    const ScreenWrapper = ({ children }) => (
      <div className="flex flex-col h-full">
        <div className="flex flex-row gap-3 px-6 py-3 items-end mt-1 border-b">
          <h1 className="text-lg font-bold items-end text-secondary">
            {getTitle()}
          </h1>
          <p className="text-gray-600">{getDescription()}</p>
        </div>
        <WorkflowProgress currentStep={currentScreen} />
        {children}
      </div>
    );

    if (
      [WorkflowType.VIEW_METADATA, WorkflowType.EXTRACT_METADATA].includes(
        currentScreen,
      )
    ) {
      const content = (() => {
        switch (currentScreen) {
          case WorkflowType.VIEW_METADATA:
            return <ViewMetadataTab />;
          case WorkflowType.EXTRACT_METADATA:
            return (
              <ExtractMetadataTab
                tagTreeData={metadataTree}
                rootDatasetSize={1000}
              />
            );
          default:
            return null;
        }
      })();

      return (
        <ScreenWrapper>
          <div className="flex-1 px-6 py-5">{content}</div>
        </ScreenWrapper>
      );
    }

    // For other screens
    switch (currentScreen) {
      case WorkflowType.CONNECT_DATA:
        return (
          <ScreenWrapper>
            <ConnectDataScreen />
          </ScreenWrapper>
        );
      case WorkflowType.DATA_SLICE_MANAGEMENT:
        return (
          <ScreenWrapper>
            <DataSliceManagementScreen />
          </ScreenWrapper>
        );
      default:
        return (
          <ScreenWrapper>
            <ConnectDataScreen />
          </ScreenWrapper>
        );
    }
  };

  return renderScreen();
};

const Workflows = () => (
  <div className="flex flex-col h-full overflow-x-hidden">
    <WorkflowContextBanner />
    <div className="flex-1 min-h-0 overflow-auto">
      <WorkflowScreens />
    </div>
  </div>
);

export default Workflows;
