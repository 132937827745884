import { tokenService } from "../services/api";
import { metadataService } from "../services/api";
import { config } from "../config/config";
import { DB_OPTIONS } from "../components/MainContent/DeasyConfig/ConfigElements/utils";

export const prepareVectorDBConfiguration = (vectorDBConfiguration) => {
  // Base configuration that's common for all DB types
  const baseConfig = {
    classname: vectorDBConfiguration.type,
    type: vectorDBConfiguration.type,
    // Only add keys if they exist in the configuration
    ...(vectorDBConfiguration.filename_key && {
      filename_key: vectorDBConfiguration.filename_key,
    }),
    ...(vectorDBConfiguration.text_key && {
      text_key: vectorDBConfiguration.text_key,
    }),
  };

  // Add tags_key only for PostgreSQL and only if it exists
  if (
    vectorDBConfiguration.type === "PSQLVectorDBManager" &&
    vectorDBConfiguration.tags_key
  ) {
    baseConfig.tags_key = vectorDBConfiguration.tags_key;
  }

  // Add fields based on DB type
  const dbOption = DB_OPTIONS.find(
    (opt) => opt.value === vectorDBConfiguration.type,
  );

  if (dbOption) {
    dbOption.fields.forEach((field) => {
      switch (field) {
        case "apiKey":
          baseConfig.api_key = vectorDBConfiguration.apiKey;
          break;
        case "collection":
          baseConfig.collection_name = vectorDBConfiguration.collection;
          break;
        case "url":
          baseConfig.url = vectorDBConfiguration.url;
          break;
        case "bucketName":
          baseConfig.bucket_name = vectorDBConfiguration.bucketName;
          break;
        case "awsAccessKeyId":
          baseConfig.aws_access_key_id = vectorDBConfiguration.awsAccessKeyId;
          break;
        case "awsSecretAccessKey":
          baseConfig.aws_secret_access_key =
            vectorDBConfiguration.awsSecretAccessKey;
          break;
        // Handle PostgreSQL specific fields
        case "database_name":
          baseConfig.database_name = vectorDBConfiguration.database_name;
          break;
        case "password":
          baseConfig.password = vectorDBConfiguration.password;
          break;
        case "db_user":
          baseConfig.db_user = vectorDBConfiguration.db_user;
          break;
        case "port":
          baseConfig.port = vectorDBConfiguration.port || "5432";
          break;
        default:
          // For any other fields, pass them through as-is
          baseConfig[field] = vectorDBConfiguration[field];
          break;
      }
    });
  }

  // Add dimension threshold if it exists
  if (vectorDBConfiguration.dimensionThreshold) {
    baseConfig.dimension_threshold = vectorDBConfiguration.dimensionThreshold;
  }

  return baseConfig;
};

export const prepareEndpointManagerConfig = (endpointManagerConfig) => {
  return {
    model: endpointManagerConfig.llmModel,
    embedding_model: "text-embedding-3-small",
    api_key: endpointManagerConfig.llmApiKey,
    vendor: endpointManagerConfig.llmType.toLowerCase(),
    // seed: 42,
    // max_tokens: 1000,
    temperature: 0,
    rpm_completion: endpointManagerConfig?.requestsPerMinute ?? null,
    tpm_completion: endpointManagerConfig?.tokensPerMinute ?? null,
    rpm_embedding: endpointManagerConfig?.embeddingRequestsPerMinute ?? null,
    tpm_embedding: endpointManagerConfig?.embeddingTokensPerMinute ?? null,
  };
};

export const prepareTagForTS = (tag) => {
  const tagName = tag.name;
  return {
    [tagName]: {
      name: tagName,
      description: tag.description,
      available_values: tag.available_values,
      examples: tag.examples,
      output_type: tag.output_type,
    },
  };
};

export const fetchUserSecrets = async (setSavedTags, setDeasyUserConfig) => {
  // Initialize default values
  const defaults = {
    deasyApiKey: "",
    vectorDBConfiguration: config.vectorDB.default,
    deasyUserConfig: config.deasyUserConfig,
  };
  let deasyApiKey = defaults.deasyApiKey;
  let vectorDBConfiguration = defaults.vectorDBConfiguration;

  try {
    // Load and parse user config
    const userConfigResult = await tokenService.getSecret("deasyUserConfig");
    const deasyUserConfig =
      JSON.parse(userConfigResult.data.secret) || defaults.deasyUserConfig;

    setDeasyUserConfig(deasyUserConfig);

    // Load API key and tags
    try {
      deasyApiKey = deasyUserConfig.deasyApiKey;
      const savedTags = deasyApiKey
        ? (await metadataService.getSavedTags(deasyApiKey)).data.tags || []
        : [];
      setSavedTags(savedTags);
    } catch (error) {
      setSavedTags([]);
    }

    return {
      deasyApiKey,
      vectorDBConfiguration,
      userConfig: deasyUserConfig,
    };
  } catch (err) {
    // Reset to defaults on error
    setDeasyUserConfig(defaults.deasyUserConfig);
    setSavedTags(defaults.savedTags);

    return {
      deasyApiKey: defaults.deasyApiKey,
      vectorDBConfiguration: defaults.vectorDBConfiguration,
      userConfig: defaults.deasyUserConfig,
    };
  }
};

export const isValidDBConfigured = (deasyUserConfig) => {
  return Boolean(
    deasyUserConfig.deasyApiKey &&
      deasyUserConfig.vdbmConfig.Configs?.[
        deasyUserConfig.vdbmConfig.LastActive
      ],
  );
};
