import React, { createContext, useContext, useState, useEffect } from "react";
import { metadataService } from "../services/api";
import { auth } from "../config/firebase";
import { BaseContext } from "./BaseContext";
import { graphService } from "../services/api";
import { prepareVectorDBConfiguration } from "../services/utils";

export const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [graphData, setGraphData] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [hierarchyStats, setHierarchyStats] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [activeDragNode, setActiveDragNode] = useState(null);
  const [activeOverlappingNode, setActiveOverlappingNode] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [fetchingGraphs, setFetchingGraphs] = useState(false);
  const [selectedDiscoveryGraph, setSelectedDiscoveryGraph] = useState(null);
  const { deasyUserConfig } = useContext(BaseContext);
  const discoveryGraphData = selectedDiscoveryGraph?.graph_data || {};
  const setDiscoveryGraphData = (data) => {
    setSelectedDiscoveryGraph((prev) => ({
      ...prev,
      graph_data: data,
    }));
  };

  useEffect(() => {
    const fetchGraphs = async () => {
      setFetchingGraphs(true);
      try {
        const graphs = await graphService.getGraphs(
          deasyUserConfig.deasyApiKey,
        );
        const fetchedGraphs = graphs.data.data.graphs;
        setGraphs(fetchedGraphs);
        if (fetchedGraphs.length > 0 && !selectedDiscoveryGraph) {
          setSelectedDiscoveryGraph(fetchedGraphs[0]);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setGraphs([]);
        }
      } finally {
        setFetchingGraphs(false);
      }
    };

    if (deasyUserConfig.deasyApiKey) {
      fetchGraphs();
    }
  }, [setGraphs, deasyUserConfig.deasyApiKey, selectedDiscoveryGraph]);

  const refreshHierarchyStats = async (apiKey) => {
    try {
      // Build conditions for all nodes in the tree
      const buildTreeConditions = (data) => {
        const conditions = [];

        const processNode = (node, tagId) => {
          if (!node || typeof node !== "object") return;

          // If node has TagAvailableValues, it's a tag node
          if (node.TagAvailableValues) {
            conditions.push({
              tag_id: tagId,
              values: node.TagAvailableValues,
            });
          }

          // Process child nodes
          Object.entries(node).forEach(([key, value]) => {
            if (key !== "TagAvailableValues" && typeof value === "object") {
              processNode(value, key);
            }
          });
        };

        Object.entries(data).forEach(([key, value]) => {
          processNode(value, key);
        });

        return conditions;
      };

      const conditions = buildTreeConditions(discoveryGraphData);

      const activeVdbConfig =
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ];

      const requestData = {
        vector_db_config: prepareVectorDBConfiguration({
          ...activeVdbConfig,
          user: auth.currentUser.email,
        }),
        endpoint_manager_config:
          deasyUserConfig.llmConfig?.Configs?.[
            deasyUserConfig.llmConfig?.LastActive
          ],
        conditions: conditions,
        current_tree: JSON.stringify(discoveryGraphData) || [],
      };

      const response = await metadataService.getHierarchyCountDistributions(
        requestData,
        apiKey,
      );

      // Store previous stats for comparison
      const previousStats = hierarchyStats;
      const newStats = response.data?.hierarchy;

      // Find updated nodes by comparing hierarchies
      const findUpdatedNodes = (oldNode, newNode, path = []) => {
        const updates = [];

        if (!oldNode || !newNode) return updates;

        // Check if current node was updated
        if (
          oldNode.file_count !== newNode.file_count ||
          oldNode.percentage !== newNode.percentage
        ) {
          updates.push({
            path: [...path],
            name: newNode.name,
            oldCount: oldNode.file_count,
            newCount: newNode.file_count,
            oldPercentage: oldNode.percentage,
            newPercentage: newNode.percentage,
          });
        }

        // Recursively check children
        if (newNode.children) {
          newNode.children.forEach((newChild) => {
            const oldChild = oldNode.children?.find(
              (c) => c.name === newChild.name,
            );
            updates.push(
              ...findUpdatedNodes(oldChild, newChild, [...path, newChild.name]),
            );
          });
        }

        return updates;
      };

      const updatedNodes = findUpdatedNodes(previousStats, newStats);

      setHierarchyStats(newStats);
      return updatedNodes; // Return the updates if needed elsewhere
    } catch (error) {
      console.error("Error refreshing hierarchy stats:", error);
      return [];
    }
  };

  const handleSaveGraph = async () => {
    await graphService.createUpdateGraph(
      deasyUserConfig.deasyApiKey,
      selectedDiscoveryGraph.graph_id,
      selectedDiscoveryGraph.graph_name,
      selectedDiscoveryGraph.graph_description,
      selectedDiscoveryGraph.graph_data,
    );
  };

  const value = {
    graphData,
    setGraphData,
    sidePanelOpen,
    setSidePanelOpen,
    selectedNodeData,
    setSelectedNodeData,
    hierarchyStats,
    setHierarchyStats,
    refreshHierarchyStats,
    selectedNodes,
    setSelectedNodes,
    graphs,
    setGraphs,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    discoveryGraphData,
    setDiscoveryGraphData,
    fetchingGraphs,
    setFetchingGraphs,
    activeDragNode,
    setActiveDragNode,
    activeOverlappingNode,
    setActiveOverlappingNode,
    handleSaveGraph,
  };

  return (
    <GraphContext.Provider value={value}>{children}</GraphContext.Provider>
  );
};

export const useGraph = () => {
  const context = useContext(GraphContext);
  if (!context) {
    throw new Error("useGraph must be used within a GraphProvider");
  }
  return context;
};
