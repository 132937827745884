import React, { useState, useContext, useEffect } from "react";
import TagsTable from "../../../../SavedTags/components/TagTables/TagsTable";
import { metadataService } from "../../../../../../services/api";
import { toast } from "react-hot-toast";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import { DB_OPTIONS } from "../../../../DeasyConfig/ConfigElements/utils";

import UseCaseOverview from "./UseCaseOverview";

const UseCaseSummaryScreen = ({ setSelectedStep, useCase }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  // Find matching config from vdbmConfigs based on useCase's vector DB config
  const matchingConfig = Object.values(vdbmConfigs).find(
    (config) =>
      config.type === useCase?.vector_db_config?.type &&
      config.collection === useCase?.vector_db_config?.collection_name,
  );
  const vectorDBConfiguration = matchingConfig || vdbmConfigs[vdbmLastActive];

  const [isOverviewOpen, setIsOverviewOpen] = React.useState(true);
  const [isExportOpen, setIsExportOpen] = React.useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(true);

  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportFormat, setSelectedExportFormat] = useState("csv");
  const [showVDBForm, setShowVDBForm] = useState(false);
  const [metadataLevel, setMetadataLevel] = useState("both");
  const [availableTags, setAvailableTags] = useState([]);
  const [vdbSettings, setVdbSettings] = useState({
    type:
      matchingConfig?.type ||
      vectorDBConfiguration?.type ||
      "PineconeVectorDBManager",
    collection:
      matchingConfig?.collection || vectorDBConfiguration?.collection || "",
    apiKey: matchingConfig?.apiKey || vectorDBConfiguration?.apiKey || "",
    url: matchingConfig?.url || vectorDBConfiguration?.url || "",
    database_name:
      matchingConfig?.database_name ||
      vectorDBConfiguration?.database_name ||
      "",
    password: matchingConfig?.password || vectorDBConfiguration?.password || "",
    db_user: matchingConfig?.db_user || vectorDBConfiguration?.db_user || "",
    port: matchingConfig?.port || vectorDBConfiguration?.port || "",
  });

  const [isLoadingTags, setIsLoadingTags] = useState(false);

  const handleTagSelect = (tag) => {
    if (selectedTags.find((t) => t.tag_id === tag.tag_id)) {
      setSelectedTags(selectedTags.filter((t) => t.tag_id !== tag.tag_id));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);

      if (selectedExportFormat === "vdb") {
        const selectedDB = DB_OPTIONS.find(
          (opt) => opt.value === vdbSettings.type,
        );
        const vectorDBConfig = prepareVectorDBConfiguration(
          selectedDB.fields.reduce(
            (config, field) => ({
              ...config,
              [field]: vdbSettings[field],
            }),
            { type: vdbSettings.type },
          ),
        );

        const requestBody = {
          usecase_id: useCase?.id,
          target_vector_db_config: vectorDBConfig,
          export_tags: selectedTags.map((tag) => tag.tag_id),
          export_level: metadataLevel,
        };

        console.log("Request body:", requestBody);
        await metadataService.exportToVectorDB(requestBody, deasyApiKey);
        toast.success("Successfully exported to vector database");
        return;
      }

      const requestBody = {
        usecase_id: useCase?.id,
        export_level: metadataLevel,
        export_format: selectedExportFormat,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        selected_metadata_fields: selectedTags.map((tag) => tag.tag_id),
      };

      const response = await metadataService.exportMetadata(
        requestBody,
        deasyApiKey,
      );

      const content =
        selectedExportFormat === "json"
          ? JSON.stringify(response.data, null, 2)
          : response.data;

      const blob = new Blob([content], {
        type: selectedExportFormat === "json" ? "application/json" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `usecase_metadata.${selectedExportFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Export completed successfully");
    } catch (error) {
      console.error("Export failed:", error);
      toast.error("Failed to export data");
    } finally {
      setIsExporting(false);
    }
  };

  // Load available tags when the component mounts
  useEffect(() => {
    const loadAvailableTags = async () => {
      setIsLoadingTags(true);
      try {
        const response = await metadataService.getUniqueTags(
          deasyApiKey,
          null,
          prepareVectorDBConfiguration(vectorDBConfiguration),
          useCase?.id,
        );

        if (response?.data?.tags) {
          const formattedTags = response.data.tags.map((tagId) => ({
            tag_id: tagId,
            name: tagId,
          }));
          setAvailableTags(formattedTags);
        }
      } catch (error) {
        console.error("Failed to load available tags:", error);
        toast.error("Failed to load available tags");
      } finally {
        setIsLoadingTags(false);
      }
    };

    if (useCase?.id) {
      loadAvailableTags();
    } else {
      setAvailableTags([]);
    }
  }, [useCase?.id, vectorDBConfiguration, deasyApiKey]);

  const isVDBFormValid = () => {
    const selectedDB = DB_OPTIONS.find((opt) => opt.value === vdbSettings.type);
    if (!selectedDB) return false;
    return selectedDB.fields.every((field) => vdbSettings[field]);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-auto p-6 pt-4 mb-10">
        <div className="mx-auto">
          {/* Back Button */}
          <div className="mb-6">
            <button
              onClick={() => setSelectedStep(0)}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back to Data Slices
            </button>
          </div>

          {/* Overview Section (Usecase Metrics) */}
          <UseCaseOverview
            isOverviewOpen={isOverviewOpen}
            setIsOverviewOpen={setIsOverviewOpen}
            usecase_id={useCase?.id}
            usecase_name={useCase?.name}
            useCase={useCase}
          />

          {/* Export Use Case Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 mt-6">
            <button
              onClick={() => setIsExportOpen(!isExportOpen)}
              className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
            >
              <div className="flex items-center gap-3">
                <h2 className="text-xl font-semibold text-gray-800">
                  Export Use Case
                </h2>
              </div>
              <span
                className="transform transition-transform duration-200 text-gray-400"
                style={{ transform: isExportOpen ? "rotate(180deg)" : "" }}
              >
                ▼
              </span>
            </button>
            {isExportOpen && (
              <div className="p-6 border-t">
                <div className="space-y-8">
                  {/* Export Format Section */}
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 mb-2 text-left">
                        Export Format
                      </h3>
                      <p className="text-sm text-gray-600 mb-4 text-left">
                        Choose how you want to export your metadata. Download as
                        a file for local analysis, or export directly to a
                        vector database for advanced querying.
                      </p>
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={selectedExportFormat}
                        onChange={(e) => {
                          setSelectedExportFormat(e.target.value);
                          setShowVDBForm(e.target.value === "vdb");
                        }}
                      >
                        <option value="csv">CSV File</option>
                        <option value="json">JSON File</option>
                        <option value="vdb">Vector Database</option>
                      </select>
                    </div>
                  </div>

                  {/* Metadata Level Section */}
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 mb-2 text-left">
                        Metadata Level
                      </h3>
                      <p className="text-sm text-gray-600 mb-4 text-left">
                        Select which level of metadata to export. File-level
                        metadata provides document-wide insights, while
                        chunk-level gives detailed section-by-section
                        information.
                      </p>
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={metadataLevel}
                        onChange={(e) => setMetadataLevel(e.target.value)}
                        disabled={isExporting}
                      >
                        <option value="both">
                          Both Levels - Complete metadata export
                        </option>
                        <option value="file">
                          File Level Only - Document-wide metadata
                        </option>
                        <option value="chunk">
                          Chunk Level Only - Section-by-section metadata
                        </option>
                      </select>
                    </div>
                  </div>

                  {/* Tag Selection Section */}
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 mb-2 text-left">
                        Tag Selection
                      </h3>
                      <p className="text-sm text-gray-600 mb-4 text-left">
                        Choose which metadata tags to include in the export. By
                        default, all tags from your use case will be exported.
                        Select specific tags to customize your export.
                      </p>
                      <div className="bg-gray-50 p-4 rounded-lg mb-4">
                        <div className="flex items-center justify-between mb-4">
                          <span className="text-sm font-medium text-gray-700">
                            Selected Tags: {selectedTags.length || "All"}
                          </span>
                          <button
                            onClick={() => setSelectedTags([])}
                            className="text-sm text-primary hover:text-primary-dark"
                          >
                            Reset Selection
                          </button>
                        </div>
                        <div className="bg-white border rounded-lg">
                          <TagsTable
                            hideNewTagButton={true}
                            compressedTagTable={true}
                            forceExpanded={false}
                            tagInEditor={tagInEditor}
                            setTagInEditor={setTagInEditor}
                            isTagEditorCollapsed={isTagEditorCollapsed}
                            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
                            onTagSelect={handleTagSelect}
                            selectedTags={selectedTags}
                            allowMultiSelect={true}
                            showDeleteButton={false}
                            availableTags={availableTags}
                            isLoading={isLoadingTags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Vector Database Configuration */}
                  {showVDBForm && (
                    <div className="space-y-4">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                          Vector Database Configuration
                        </h3>
                        <p className="text-sm text-gray-600 mb-4">
                          Configure the target vector database where your
                          metadata will be exported.
                        </p>
                        <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Database Type
                            </label>
                            <select
                              className="w-full border border-gray-300 rounded-md px-3 py-2"
                              value={vdbSettings.type}
                              onChange={(e) =>
                                setVdbSettings((prev) => ({
                                  ...prev,
                                  type: e.target.value,
                                }))
                              }
                            >
                              {DB_OPTIONS.filter(
                                (opt) => opt.value !== "S3DataSourceManager",
                              ).map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          {DB_OPTIONS.find(
                            (opt) => opt.value === vdbSettings.type,
                          )?.fields.map((field) => (
                            <div key={field}>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                {field.charAt(0).toUpperCase() +
                                  field
                                    .slice(1)
                                    .replace(/([A-Z])/g, " $1")
                                    .trim()}
                              </label>
                              <input
                                type={
                                  field.toLowerCase().includes("password") ||
                                  field.toLowerCase().includes("key")
                                    ? "password"
                                    : "text"
                                }
                                className="w-full border border-gray-300 rounded-md px-3 py-2"
                                value={vdbSettings[field] || ""}
                                onChange={(e) =>
                                  setVdbSettings((prev) => ({
                                    ...prev,
                                    [field]: e.target.value,
                                  }))
                                }
                                placeholder={`Enter ${field
                                  .replace(/([A-Z])/g, " $1")
                                  .toLowerCase()
                                  .trim()}`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Export Button */}
                  <div className="pt-4">
                    <button
                      className={`w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-90 transition-colors ${
                        isExporting || (showVDBForm && !isVDBFormValid())
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      onClick={handleExport}
                      disabled={
                        isExporting || (showVDBForm && !isVDBFormValid())
                      }
                    >
                      {isExporting ? (
                        <span className="flex items-center justify-center">
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Exporting...
                        </span>
                      ) : showVDBForm ? (
                        "Export to Vector Database"
                      ) : (
                        `Export as ${selectedExportFormat.toUpperCase()}`
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCaseSummaryScreen;
