import React, { useState, useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { handleSaveDeasyApiKey } from "../TokenDashboard";

function TokenCreationModal({ token, onClose }) {
  const [copied, setCopied] = useState(false);
  const [setAsCurrent, setSetAsCurrent] = useState(false);
  const { deasyUserConfig, setDeasyUserConfig } = useContext(BaseContext);

  if (!token) return null;

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token.token_value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClose = () => {
    if (setAsCurrent) {
      handleSaveDeasyApiKey(
        deasyUserConfig,
        setDeasyUserConfig,
        token.token_value,
      );
    }
    onClose();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "500px",
          position: "relative",
          textAlign: "left",
        }}
      >
        <h3 style={{ marginTop: 0, textAlign: "left" }}>New API key Created</h3>
        <p style={{ textAlign: "left" }}>
          <strong>Key ID:</strong> {token.token_id}
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>Username:</strong> {token.username}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            backgroundColor: "#f8f9fa",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "20px",
            textAlign: "left",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              wordBreak: "break-all",
              fontFamily: "monospace",
            }}
          >
            {token.token_value}
          </div>
          <button onClick={handleCopyToken} className="btn btn-primary">
            {copied ? "Copied!" : "Copy Token"}
          </button>
        </div>
        <div
          style={{ color: "#dc3545", marginBottom: "20px", textAlign: "left" }}
        >
          ⚠️ Make sure to copy this token now. You won't be able to see it
          again!
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={setAsCurrent}
              onChange={(e) => setSetAsCurrent(e.target.checked)}
              className="form-checkbox"
            />
            <span>Set as current Deasy API key</span>
          </label>
        </div>
        <button onClick={handleClose} className="btn btn-secondary w-full">
          Close
        </button>
      </div>
    </div>
  );
}

export default TokenCreationModal;
