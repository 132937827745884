import React from "react";
import { FormInput } from "../ConfigUtils";

export const EditableProfileCard = ({
  name,
  setName,
  children,
  onSave,
  onCancel,
  validationError,
  isValidating,
  saveButtonText,
}) => {
  return (
    <div className="p-7 py-9 rounded-lg border-2 border-gray-200 bg-gray-50">
      <div className="space-y-4">
        <FormInput
          label="Profile Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter profile name"
        />

        {children}

        {validationError && (
          <div className="text-red-600 text-sm font-medium">
            {validationError}
          </div>
        )}

        <div className="flex gap-3 mt-6">
          <button
            onClick={onSave}
            disabled={isValidating}
            className="flex-1 py-2 px-4 bg-primary text-white rounded-md 
                     hover:bg-emerald-700 disabled:bg-gray-400"
          >
            {saveButtonText}
          </button>
          <button
            onClick={onCancel}
            className="py-2 px-4 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
