import React, { useContext } from "react";
import { BaseContext } from "../../contexts/BaseContext";
import { WorkflowType } from "../../models/WorkflowModel";

const WorkflowProgress = ({ currentStep }) => {
  const { setCurrentScreen, setUseCaseScreen } = useContext(BaseContext);
  const steps = [
    { id: WorkflowType.CONNECT_DATA, label: "Connect Data", number: 1 },
    { id: WorkflowType.VIEW_METADATA, label: "View Metadata", number: 2 },
    {
      id: WorkflowType.EXTRACT_METADATA,
      label: "Extract Metadata",
      number: 3,
    },
    { id: WorkflowType.DATA_SLICE_MANAGEMENT, label: "Data Slices", number: 4 },
  ];
  const backScreen = currentStep > 0 ? steps[currentStep - 1] : null;
  const nextScreen =
    currentStep < steps.length - 1 ? steps[currentStep + 1] : null;

  const getStepStatus = (index) => {
    if (index < currentStep) return "completed";
    if (index === currentStep) return "current";
    return "upcoming";
  };

  const onStepClick = (index) => {
    setCurrentScreen(index);
    if (index === WorkflowType.DATA_SLICE_MANAGEMENT) {
      setUseCaseScreen(null);
    }
  };

  return (
    <div className="flex items-center justify-between bg-gray-50 py-2 px-4 border-b">
      <div className="flex justify-start">
        <button
          onClick={() => setCurrentScreen(backScreen.id)}
          disabled={currentStep === 0}
          title={currentStep === 0 ? "This is the first step" : ""}
          className={`
          px-4 py-2 rounded border
          ${
            currentStep === 0
              ? "invisible"
              : "border-gray-300 text-gray-600 hover:bg-gray-50"
          }
        `}
        >
          ← Back
        </button>
      </div>
      <div className="flex flex-1 items-center gap-4 justify-center">
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            {index > 0 && <div className="w-6 h-px bg-gray-300" />}
            <div
              className="flex items-center gap-2 cursor-pointer hover:bg-white/80 px-2 rounded-md"
              onClick={() => onStepClick(step.id)}
            >
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center text-xs text-white
                  ${getStepStatus(index) === "completed" ? "bg-primary" : ""}
                  ${getStepStatus(index) === "current" ? "bg-primary" : ""}
                  ${getStepStatus(index) === "upcoming" ? "bg-gray-200" : ""}`}
              >
                {getStepStatus(index) === "completed" ? "✓" : step.number}
              </div>
              <div
                className={`text-sm ${
                  getStepStatus(index) === "upcoming" ? "text-gray-500" : ""
                }`}
              >
                {step.label}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => setCurrentScreen(nextScreen.id)}
          disabled={currentStep >= steps.length - 1}
          title={currentStep >= steps.length - 1 ? "This is the last step" : ""}
          className={`
          px-4 py-2 rounded bg-primary text-white
          ${currentStep >= steps.length - 1 ? "invisible" : "hover:bg-primary/90"}
        `}
        >
          Continue →
        </button>
      </div>
    </div>
  );
};

export default WorkflowProgress;
