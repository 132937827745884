import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import { TokenProvider } from "./contexts/TokenContext";
import Home from "./Home";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import LoginLoadingScreen from "./components/utils/LoginLoadingScreen";
import { BaseContext } from "./contexts/BaseContext";
import { fetchUserSecrets } from "./services/utils";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Initializing...");
  const { setSavedTags, setDeasyUserConfig } = useContext(BaseContext);

  useEffect(() => {
    const preloadData = async () => {
      try {
        // Initialize
        setLoadingProgress(10);
        setLoadingMessage("Initializing application...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Auth check
        setLoadingProgress(30);
        setLoadingMessage("Checking authentication...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Load configuration
        setLoadingProgress(50);
        setLoadingMessage("Loading configuration...");
        await fetchUserSecrets(setSavedTags, setDeasyUserConfig);
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Load files if we have configuration
        setLoadingProgress(70);
        setLoadingMessage("Loading available files...");

        // Finalize
        setLoadingProgress(90);
        setLoadingMessage("Finalizing...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        setLoadingProgress(100);
        setLoadingMessage("Ready!");
        await new Promise((resolve) => setTimeout(resolve, 300));

        setIsLoading(false);
      } catch (error) {
        console.error("Error during preload:", error);
        setIsLoading(false);
      }
    };

    preloadData();
    // eslint-disable-next-line
  }, [setSavedTags, setDeasyUserConfig]);

  if (isLoading) {
    return (
      <LoginLoadingScreen progress={loadingProgress} message={loadingMessage} />
    );
  }

  return (
    <>
      <Toaster />
      <AuthProvider>
        <TokenProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/token"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflows/connect-data"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflows/data-discovery"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflows/export-metadata"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
          </Router>
        </TokenProvider>
      </AuthProvider>
    </>
  );
}

export default App;
