const cleanSchema = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const cleanedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    if (key === "description" || key === "output_type") {
      continue;
    }
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const available_values = value.TagAvailableValues || [];
      const cleanedValue = cleanSchema(value);

      if ("TagAvailableValues" in value) {
        // If TagAvailableValues is empty, add <ANY>
        if (available_values.length === 0) {
          cleanedValue.TagAvailableValues = ["<ANY>"];
        } else {
          const branches = Object.keys(cleanedValue).filter(
            (k) =>
              k !== "TagAvailableValues" &&
              k !== "description" &&
              k !== "output_type",
          );
          branches.forEach((branch) => {
            if (
              !available_values.includes(branch) &&
              available_values[0] !== "<ANY>"
            ) {
              delete cleanedValue[branch];
            }
          });
        }
      }
      cleanedObj[key] = cleanedValue;
    } else {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
};

const flattenMap = (obj, parentKey = "") => {
  const result = {};
  for (const key in obj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (
        obj[key].description !== undefined &&
        obj[key].output_type !== undefined
      ) {
        result[currentKey] = {
          name: key,
          label: key,
          description: obj[key].description,
          output_type: obj[key].output_type,
          available_values: obj[key]?.TagAvailableValues,
        };
      }
      const nested = flattenMap(obj[key], currentKey);
      Object.assign(result, nested);
    }
  }

  return result;
};

export { cleanSchema, flattenMap };
