import React, { useContext, useEffect } from "react";
import { BaseContext } from "./contexts/BaseContext";
import TokenDashboard from "./components/MainContent/Token/TokenDashboard";
import DeasyConfig from "./components/MainContent/DeasyConfig/DeasyConfig";
import MetadataLibrary from "./components/MainContent/MetadataLibrary/MetadataLibrary";
import AppLayout from "./components/Layout/AppLayout";
import { useSavedTags } from "./hooks/SavedTagsHook";
import { useLocation } from "react-router-dom";
import Workflows from "./components/MainContent/Workflows/Workflows";
import { ProgressTracking } from "./components/MainContent/ProgressTracking";
import TaggingStudio from "./components/MainContent/TaggingStudio/TaggingStudio";

const Home = ({ initialTab = "workflows" }) => {
  const { activePage, setActivePage } = useContext(BaseContext);
  const location = useLocation();
  useSavedTags(); // Hook to fetch saved tags

  useEffect(() => {
    if (initialTab === "token") {
      setActivePage("tokenDashboard");
    } else if (
      initialTab === "workflows" ||
      location.pathname.startsWith("/workflows")
    ) {
      setActivePage("workflows");
    }
  }, [initialTab, location.pathname, setActivePage]);

  const renderContent = () => {
    switch (activePage) {
      case "workflows":
        return <Workflows />;
      case "tokenDashboard":
        return <TokenDashboard />;
      case "vectorDatabase":
        return <DeasyConfig />;
      case "metadata":
        return <MetadataLibrary />;
      case "metadata-studio":
        return <TaggingStudio />;
      case "progress-tracking":
        return <ProgressTracking />;
      default:
        return null;
    }
  };

  return (
    <AppLayout>
      <div className="flex-1 h-screen">{renderContent()}</div>
    </AppLayout>
  );
};

export default Home;
