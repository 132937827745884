import { FaChevronLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Tag } from "lucide-react";

export const TagEditorHeader = ({
  collapseDirection,
  labelStyles,
  labelRotation,
  headerClasses,
  disableCollapse,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  setIsTagSelectorExpanded,
  tagInEditor,
}) => {
  return (
    <>
      <div
        className={headerClasses}
        onClick={() =>
          !disableCollapse && setIsTagEditorCollapsed(!isTagEditorCollapsed)
        }
      >
        {(!isTagEditorCollapsed || collapseDirection === "vertical") && (
          <div className="flex w-full items-center justify-between p-3">
            <div className="flex items-center gap-2">
              <Tag size={24} className="text-primary" />
              <h2 className="text-xl font-semibold text-left">
                Metadata Editor
              </h2>
            </div>
            {!(setIsTagSelectorExpanded === null) && (
              <button
                onClick={() => setIsTagSelectorExpanded(true)}
                className={`p-2 rounded-full shadow-md hover:opacity-80 transition-colors duration-200 truncate px-4 ${
                  tagInEditor?.name
                    ? "w-1/2 bg-white text-gray-500 "
                    : "w-1/2 bg-emerald-50 border-primary text-emerald-600 border-2 font-medium"
                }`}
                title="Collapse"
              >
                {tagInEditor?.name ? tagInEditor.name : "Select a Tag"}
              </button>
            )}
          </div>
        )}
        {!disableCollapse && (
          <button className="text-gray-500 hover:text-gray-700">
            {collapseDirection === "vertical" ? (
              isTagEditorCollapsed ? (
                <FaChevronDown />
              ) : (
                <FaChevronUp />
              )
            ) : isTagEditorCollapsed ? (
              <FaChevronLeft />
            ) : (
              <></>
            )}
          </button>
        )}
      </div>
      {isTagEditorCollapsed && collapseDirection === "horizontal" && (
        <div className="flex justify-center items-center p-6 ">
          <h2
            className={`whitespace-nowrap font-semibold ${labelRotation}`}
            style={labelStyles}
          >
            Metadata Editor
          </h2>
        </div>
      )}
    </>
  );
};
