import React, { useContext } from "react";
import { UseCaseSelect } from "./screens/UseCaseSelect";
import UseCaseSummaryScreen from "./screens/UseCaseDashBoard";
import { BaseContext } from "../../../../../contexts/BaseContext";

const DataSliceManagementScreen = () => {
  const { selectedUseCase, setSelectedUseCase, useCaseStep, setUseCaseStep } =
    useContext(BaseContext);

  const handleUseCaseSelect = (useCase) => {
    setSelectedUseCase(useCase);
    setUseCaseStep(3);
  };

  const renderTabContent = () => {
    switch (useCaseStep) {
      case 0:
        return (
          <UseCaseSelect
            onCreateNew={() => setUseCaseStep(1)}
            onUseCaseSelect={handleUseCaseSelect}
          />
        );
      case 3:
        return (
          <UseCaseSummaryScreen
            setSelectedStep={setUseCaseStep}
            useCase={selectedUseCase}
          />
        );
      default:
        return null;
    }
  };

  return <div className="flex-1 px-2 py-1">{renderTabContent()}</div>;
};

export default DataSliceManagementScreen;
