import React, { useState, useEffect, useCallback } from "react";
import { tokenService } from "../../../services/api";
import { auth } from "../../../config/firebase";
import TokenTable from "./components/TokenTable";
import TokenCreationModal from "./components/TokenCreationModal";
import { useContext } from "react";
import { TokenContext } from "../../../contexts/TokenContext";
import { BaseContext } from "../../../contexts/BaseContext";
import { FormInput } from "../../MainContent/DeasyConfig/ConfigUtils";
import { LoadingScreen } from "../../utils/LoadingScreen";

export const handleSaveDeasyApiKey = async (
  deasyUserConfig,
  setDeasyUserConfig,
  deasyApiKey,
  setSavingApiKey = null,
) => {
  if (setSavingApiKey) setSavingApiKey(true);
  try {
    const newConfig = {
      ...deasyUserConfig,
      deasyApiKey: deasyApiKey.trim(),
    };
    setDeasyUserConfig(newConfig);
    await tokenService.storeSecret(
      "deasyUserConfig",
      JSON.stringify(newConfig),
    );
  } catch (error) {
    console.error("Error saving Deasy API Key:", error);
  } finally {
    if (setSavingApiKey) setSavingApiKey(false);
  }
};

function TokenDashboard() {
  const { tokens, setTokens, setUserCredits } = useContext(TokenContext);
  const { deasyUserConfig, setDeasyUserConfig } = useContext(BaseContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newToken, setNewToken] = useState(null);
  const [deletingToken, setDeletingToken] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [deasyApiKey, setDeasyApiKey] = useState(
    deasyUserConfig?.deasyApiKey || "",
  );
  const [savingApiKey, setSavingApiKey] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  const loadTokens = useCallback(async () => {
    try {
      const [tokensResponse, subscriptionResponse] = await Promise.all([
        tokenService.listTokens(),
        tokenService.getSubscriptionInfo(),
      ]);

      setTokens(
        Array.isArray(tokensResponse.data.tokens)
          ? tokensResponse.data.tokens
          : [],
      );
      setUserCredits(tokensResponse.data.available_credits);
      setSubscriptionInfo(subscriptionResponse.data);
    } catch (err) {
      setError("Failed to load data");
      setTokens([]);
    } finally {
      setLoading(false);
      setInitialLoadDone(true);
    }
  }, [setTokens, setUserCredits]);

  useEffect(() => {
    if (!initialLoadDone) {
      loadTokens();
    }
  }, [loadTokens, initialLoadDone]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      const verifyPayment = async () => {
        try {
          await tokenService.verifyPayment(sessionId);
          await loadTokens();
        } catch (err) {
          setError("Failed to verify payment.");
        } finally {
          window.history.replaceState({}, "", "/token");
        }
      };

      verifyPayment();
    }
  }, [loadTokens]);

  const handleCreateToken = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const response = await tokenService.createToken(userEmail);
      setNewToken(response.data);
      await loadTokens();
    } catch (err) {
      setError("Failed to create an api key");
    }
  };

  const handleDeleteToken = async (tokenId) => {
    if (deletingToken) return;

    if (!window.confirm("Are you sure you want to delete this token?")) {
      return;
    }

    try {
      setDeletingToken(true);
      await tokenService.deleteToken(tokenId);
      await loadTokens(); // Refresh the token list
    } catch (err) {
      console.error("Delete api key error:", err);
      setError("Failed to delete api key");
    } finally {
      setDeletingToken(false);
    }
  };

  if (loading) {
    return (
      <LoadingScreen
        message="Loading your tokens"
        shrimpMessage="Shrimpy is scouring the ocean floor for your tokens"
      />
    );
  }

  return (
    <div className="p-8">
      {/* Error message display */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Tier Section */}
      <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
        Tier
      </h3>
      <div className="max-w-xl mb-8 bg-gray-50 p-6 shadow-md rounded-lg border border-gray-200">
        <div className="flex flex-col gap-4">
          <div className="flex items-center">
            <span className="inline-block font-medium text-left mr-1">
              User:
            </span>
            <span className="text-gray-700">{auth.currentUser?.email}</span>
          </div>

          {subscriptionInfo && (
            <>
              <div className="flex items-center">
                <span className="inline-block font-medium text-left mr-1">
                  Tier:
                </span>
                <span className="text-gray-700">
                  {subscriptionInfo.tier_id}
                </span>
              </div>

              <table className="w-full text-left">
                <thead>
                  <tr className="border-b">
                    <th className="py-2">Metric</th>
                    <th className="py-2 text-right">Current Usage</th>
                    <th className="py-2 text-right">Limit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="py-2">Tags created this month</td>
                    <td className="py-2 text-right">
                      {subscriptionInfo.monthly_usage.toLocaleString()}
                    </td>
                    <td className="py-2 text-right">
                      {subscriptionInfo.monthly_limit.toLocaleString()}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Tags stored</td>
                    <td className="py-2 text-right">
                      {subscriptionInfo.storage_usage.toLocaleString()}
                    </td>
                    <td className="py-2 text-right">
                      {subscriptionInfo.storage_limit.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>

      {/* Deasy API Key Section */}
      <h3 className="text-2xl font-semibold mb-2 text-left text-gray-800">
        Deasy API Key
      </h3>
      <p className="text-gray-600 mb-6 text-left">
        Your Deasy API key is required to access Deasy services. Keep this key
        secure and never share it publicly.
      </p>
      <div className="max-w-xl mb-8 bg-gray-50 p-6 shadow-md rounded-lg border border-gray-200">
        <FormInput
          label="API Key"
          type="password"
          value={deasyApiKey}
          onChange={(e) => setDeasyApiKey(e.target.value)}
          placeholder="Enter your Deasy API key"
          helperText="This key will be used to authenticate your requests to Deasy services"
        />
        <div className="flex justify-start">
          <button
            onClick={async (e) => {
              e.preventDefault();
              await handleSaveDeasyApiKey(
                deasyUserConfig,
                setDeasyUserConfig,
                deasyApiKey,
                setSavingApiKey,
              );
            }}
            disabled={savingApiKey}
            className="mt-4 px-4 py-2 bg-white text-primary  text-md font-semibold
                      disabled:bg-gray-400 rounded-lg shadow-md hover:shadow-lg transition-all duration-200"
          >
            {savingApiKey ? "Saving..." : "Save API Key"}
          </button>
        </div>
      </div>

      {/* API Keys Section */}
      <div>
        <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
          Deasy API keys
        </h3>
        <TokenTable tokens={tokens} onDeleteToken={handleDeleteToken} />
        <div className="text-left">
          <button
            onClick={handleCreateToken}
            className="mt-4 px-4 py-2 bg-white text-teal-600 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center space-x-2 font-medium"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>New API key</span>
          </button>
        </div>
      </div>

      {/* Existing modals */}
      <TokenCreationModal token={newToken} onClose={() => setNewToken(null)} />
    </div>
  );
}

export default TokenDashboard;
