import React, { useState } from "react";

const GeneralSection = ({
  formData,
  setFormData,
  handleInputChange,
  setTagInEditor,
}) => {
  const [newValue, setNewValue] = useState("");
  const [isAddingValue, setIsAddingValue] = useState(false);

  const handleOutputTypeChange = (e) => {
    const newOutputType = e.target.value;
    setFormData((prev) => ({
      ...prev,
      output_type: newOutputType,
    }));
  };

  const handleAddValue = (e) => {
    e.preventDefault();
    if (newValue.trim()) {
      setFormData((prev) => ({
        ...prev,
        available_values: [...prev.available_values, newValue.trim()],
      }));
      setNewValue("");
      setIsAddingValue(false);
    }
  };

  const handleRemoveValue = (indexToRemove) => {
    setFormData((prev) => ({
      ...prev,
      available_values: prev.available_values.filter(
        (_, index) => index !== indexToRemove,
      ),
    }));
  };

  return (
    <div className="space-y-6 relative">
      <button
        onClick={() => setTagInEditor({})}
        className=" py-2.5 px-4 rounded-lg  hover:bg-primary/5 transition-colors"
      >
        <div className="absolute top-2 right-2 items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
      </button>
      {/* Name Section */}
      <div className="flex flex-col">
        <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
          Name
        </label>
        <input
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={handleInputChange}
          placeholder="Enter a name for the tag"
          className="p-3 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
        />
      </div>

      {/* Description Section */}
      <div className="flex flex-col">
        <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
          Description
        </label>
        <textarea
          name="description"
          value={formData.description || ""}
          onChange={handleInputChange}
          placeholder="Enter a description for the tag"
          className="p-3 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out resize-none"
          rows="4"
        />
      </div>

      {/* Output Type Section */}
      <div className="flex flex-col">
        <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
          Output Type
        </label>
        <select
          name="output_type"
          value={formData.output_type || "word"}
          onChange={handleOutputTypeChange}
          className="p-3 border border-gray-300 rounded-lg bg-white focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
        >
          <option value="word">Word</option>
          <option value="number">Number</option>
          <option value="date">Date</option>
          <option value="boolean">Boolean</option>
        </select>
      </div>

      {/* Available Values Section */}
      {formData.output_type !== "boolean" && (
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1 text-left">
            Available Values
          </label>
          <div className="flex flex-wrap gap-2">
            {formData.available_values.map((value, index) => (
              <div key={index} className="flex items-center">
                <div className="flex items-center gap-1 bg-emerald-100 text-emerald-800 px-3 py-1 rounded-full shadow-md transition duration-200 ease-in-out hover:bg-emerald-200">
                  <span className="text-sm">{value}</span>
                  <button
                    onClick={() => handleRemoveValue(index)}
                    className="text-emerald-500 hover:text-emerald-700 focus:outline-none"
                    aria-label="Remove value"
                  >
                    ×
                  </button>
                </div>
              </div>
            ))}
            {isAddingValue ? (
              <form
                onSubmit={handleAddValue}
                className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg shadow-inner"
              >
                <input
                  type="text"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  className="p-2 border border-gray-300 rounded-md text-sm focus:ring-emerald-500 focus:border-emerald-500 transition duration-200 ease-in-out"
                  placeholder="Enter value"
                  autoFocus
                />
                <div className="flex gap-1">
                  <button
                    type="submit"
                    className="text-emerald-600 hover:text-emerald-800 font-semibold"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsAddingValue(false)}
                    className="text-gray-500 hover:text-gray-700 font-semibold"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <button
                onClick={() => setIsAddingValue(true)}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-100 hover:bg-emerald-200 text-emerald-600 transition duration-200 ease-in-out shadow-md"
                aria-label="Add new value"
              >
                +
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralSection;
