import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import { Settings, Database, Zap, Info } from "lucide-react";
import { cleanSchema, flattenMap } from "./AutoSuggestUtils";
import { metadataService } from "../../../../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../../../../services/utils";
import { ChevronDown } from "lucide-react";
import toast from "react-hot-toast";
import React from "react";
import { LinearProgress, Box, Typography, Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

export const AutoSuggestedMetadata = ({ setOpenFilePicker, selectedFiles }) => {
  const {
    deasyUserConfig,
    vdbFilesCount,
    unsyncedVDBFilesCount,
    metadataReadinessStatus,
    setSavedTags,
    savedTags,
    uniqueTags,
  } = useContext(BaseContext);
  const {
    discoveryGraphData,
    selectedNodeData,
    setDiscoveryGraphData,
    hierarchyStats,
    handleSaveGraph,
  } = useContext(GraphContext);

  const getFileCountForNode = (hierarchyStats, nodePath) => {
    if (!nodePath?.length) {
      return hierarchyStats?.file_count || 0;
    }
    let current = hierarchyStats;
    if (!hierarchyStats) return 0;

    for (let i = 0; i < nodePath.length; i++) {
      const pathSegment = nodePath[i];
      const matchingNode = current.children?.find(
        (child) => child.name?.toLowerCase() === pathSegment?.toLowerCase(),
      );
      if (!matchingNode) return 0;
      current = matchingNode;
    }
    return current.file_count || 0;
  };

  const getTotalDatasetsCount = useCallback(
    (hierarchyStats, nodePath) => {
      if (!nodePath?.length) {
        return vdbFilesCount?.total_files || hierarchyStats?.file_count || 0;
      }
      return getFileCountForNode(hierarchyStats, nodePath);
    },
    [vdbFilesCount],
  );

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;
  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];
  const llmEndpointConfiguration = llmConfigs[llmLastActive];

  const [scope, setScope] = useState("File");
  const [useExtracted, setUseExtracted] = useState(false);
  const [useExisting, setUseExisting] = useState(false);
  const [useNetNew, setUseNetNew] = useState(true);

  useMemo(() => {
    if (useExtracted) return "Extracted Tags";
    if (useExisting) return "Existing Tags";
    return scope;
  }, [scope, useExtracted, useExisting]);

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [inputTextContext, setInputTextContext] = useState("");
  const [maxDepth, setMaxDepth] = useState(2);
  const [tagType, setTagType] = useState("unrestricted");
  const [disableAutoSuggest, setDisableAutoSuggest] = useState(false);
  const [autoSuggesting, setAutoSuggesting] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);

  const [selectedSource, setSelectedSource] = useState("netNew");

  const [resolution, setResolution] = useState(1);

  const [classifyProgress, setClassifyProgress] = useState(0.0);
  const [classifyTagsCreated, setClassifyTagsCreated] = useState(0);
  const [jobId, setJobId] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showMiniWarning, setShowMiniWarning] = useState(false);

  const synthesizedFilesCount = getFileCountForNode(
    hierarchyStats,
    selectedNodeData?.nodePath,
  );

  const allFilesSynthesized = useMemo(() => {
    const totalCount = getTotalDatasetsCount(
      hierarchyStats,
      selectedNodeData?.nodePath,
    );
    return synthesizedFilesCount === totalCount || totalCount === 0;
  }, [
    hierarchyStats,
    selectedNodeData,
    getTotalDatasetsCount,
    synthesizedFilesCount,
  ]);

  const handleSourceSelect = (source) => {
    setSelectedSource(source);
    setUseExisting(source === "existing");
    setUseExtracted(source === "extracted");
    setUseNetNew(source === "netNew");
  };

  const updateGraphData = (suggestion, nodePath) => {
    if (Object.keys(discoveryGraphData).length === 0) {
      const cleanedData = cleanSchema(suggestion);
      setDiscoveryGraphData(cleanedData);
      return;
    }

    let currentNode = discoveryGraphData;
    for (let i = 0; i < nodePath.length - 1; i++) {
      currentNode = currentNode[nodePath[i]];
    }

    const cleanedSuggestion = cleanSchema(suggestion);
    if (nodePath.length === 0) {
      Object.keys(cleanedSuggestion).forEach((key) => {
        currentNode[key] = cleanedSuggestion[key];
      });
    } else {
      currentNode[nodePath.at(-1)] = cleanedSuggestion;
    }

    setDiscoveryGraphData({ ...discoveryGraphData });
  };

  const createTagsFromSuggestion = async (suggestion) => {
    const individualNodes = Object.values(flattenMap(suggestion));
    await Promise.all(
      individualNodes.map((tag) =>
        metadataService.createTag(
          {
            name: tag.name,
            label: tag.name,
            description: tag.description,
            available_values: tag.available_values,
            output_type: tag.output_type,
          },
          deasyApiKey,
        ),
      ),
    );
    const tags = await metadataService.getSavedTags(deasyApiKey);
    setSavedTags(tags.data.tags || []);
  };

  const handleAutoSuggest = async () => {
    if (autoSuggesting) return;

    try {
      setAutoSuggesting(true);

      const nodeLabel =
        selectedNodeData?.nodeType === "value"
          ? selectedNodeData.nodePath.at(-2)
          : selectedNodeData?.label;

      let conditions = {};
      if (selectedNodeData?.nodePath?.length) {
        for (let i = 0; i < selectedNodeData.nodePath.length; i += 2) {
          const tag = selectedNodeData.nodePath[i];
          const value = selectedNodeData.nodePath[i + 1];
          if (tag && value) {
            conditions[tag] = [value];
          }
        }
      }

      const response = await metadataService.suggestTags(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        discoveryGraphData,
        {
          label: nodeLabel,
          path: selectedNodeData.nodePath,
        },
        inputTextContext,
        scope,
        [],
        tagType,
        maxDepth,
        deasyApiKey,
        {
          use_existing_tags: useExisting,
          use_extracted_tags: useExtracted,
          use_net_new_tags: useNetNew,
        },
        conditions,
        resolution,
      );

      if (!response?.data?.suggestion) {
        throw new Error("Invalid response format from suggestion service");
      }

      updateGraphData(response.data.suggestion, selectedNodeData.nodePath);
      await createTagsFromSuggestion(response.data.suggestion);
      await handleSaveGraph();
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Unknown error occurred";
      toast.error(errorMessage);
      console.error("Auto-suggest failed:", error);
    } finally {
      setAutoSuggesting(false);
    }
  };

  const handleClassify = async () => {
    if (isClassifying) return;
    setIsClassifying(true);
    setClassifyProgress(0.0);
    setClassifyTagsCreated(0);
    const jobID = uuidv4();
    try {
      metadataService.classifyAll(
        prepareVectorDBConfiguration(vectorDBConfiguration), // vectorDBConfiguration
        prepareEndpointManagerConfig(llmEndpointConfiguration), // llmEndpointConfiguration
        null, // tags
        deasyApiKey, // deasyApiKey
        jobID, // jobID
        null, // usecaseId
        vdbFilesCount?.total_files || 0, // totalDataSets
        true, // generateFileLevelTags
      );
      setJobId(jobID);
      toast.success("Started synthesizing datasets");
    } catch (error) {
      console.error(error);
      toast.error("Failed to start synthesizing datasets");
      setIsClassifying(false);
    }
  };

  useEffect(() => {
    let pollInterval;

    const trackJobProgress = async () => {
      try {
        if (classifyProgress >= 1.0) {
          if (isClassifying) {
            setIsClassifying(false);
          }
          return;
        }
        if (!jobId) return;

        const response = await metadataService.trackJob(jobId, deasyApiKey);
        const progress = Number(
          (
            response.data.completed_progress_increments /
            response.data.total_progress_increments
          ).toFixed(4),
        );
        setClassifyProgress(progress);
        setClassifyTagsCreated(response?.data.tags_created || 0);

        if (response.data.status !== "in_progress") {
          setIsClassifying(false);
          setClassifyProgress(0.0);
          setClassifyTagsCreated(0);
          setJobId(null);
        }
      } catch (error) {
        console.error(error);
        setIsClassifying(false);
        setClassifyProgress(0.0);
        setClassifyTagsCreated(0);
        setJobId(null);
      }
    };

    if (isClassifying && jobId) {
      trackJobProgress();
      pollInterval = setInterval(trackJobProgress, 7000); // 7 seconds
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [jobId, deasyApiKey, classifyProgress, isClassifying]);

  useEffect(() => {
    const readyFilesCount = vdbFilesCount - unsyncedVDBFilesCount;
    setDisableAutoSuggest(readyFilesCount === 0);
  }, [vdbFilesCount, unsyncedVDBFilesCount, metadataReadinessStatus]);

  const handleAutoSuggestClick = () => {
    if (autoSuggesting || disableAutoSuggest) return;

    // Check if using GPT-4o-mini
    const currentLLMConfig =
      deasyUserConfig?.llmConfig?.Configs[
        deasyUserConfig?.llmConfig?.LastActive
      ];
    if (currentLLMConfig?.llmModel === "gpt-4o-mini") {
      setShowMiniWarning(true);
      return;
    }

    setShowConfirmDialog(true);
  };

  return (
    <div className="flex flex-col p-4 pt-0 space-y-6">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center space-x-3 mt-4">
          <h2 className="text-xl font-semibold text-left">
            Suggest Schema Tree based on Node{" "}
            <span className="text-primary">
              {selectedNodeData?.label || ""}
            </span>
          </h2>
        </div>
        <div className="text-gray-600 text-base text-left italic">
          Auto generate hierarchical schema tree based on your dataset context
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Database size={24} className="text-primary" />
          <h3 className="text-lg font-medium text-left">Dataset Context</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-emerald-50 border border-emerald-100 rounded-lg p-4">
            <div className="text-sm text-emerald-600 font-medium mb-1 text-center flex items-center justify-center gap-1">
              Datasets Synthesized
              <div className="group relative">
                <Info size={16} className="text-emerald-400 cursor-help" />
                <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
                  Number of files tagged with default Deasy metadata
                </div>
              </div>
            </div>
            <div className="flex items-baseline justify-center">
              <span className="text-2xl font-bold text-emerald-700">
                {getFileCountForNode(
                  hierarchyStats,
                  selectedNodeData?.nodePath,
                )}
              </span>
            </div>
          </div>

          <div className="bg-gray-50 border border-gray-100 rounded-lg p-4">
            <div className="text-sm text-gray-600 font-medium mb-1 text-center">
              Total Datasets
            </div>
            {!vdbFilesCount?.total_files ? (
              <div className="flex items-center justify-center gap-2 text-gray-600">
                <span className="w-4 h-4 border-2 border-gray-400 border-t-transparent rounded-full animate-spin"></span>
                <span>Loading...</span>
              </div>
            ) : (
              <div className="flex items-baseline justify-center">
                <span className="text-2xl font-bold text-gray-700">
                  {synthesizedFilesCount}
                </span>
              </div>
            )}
          </div>
        </div>
        <p className="text-base text-gray-600 italic text-left">
          Note: more files synthesized, more inclusive and accurate the schema
          tree will be.
        </p>
        {isClassifying && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body2" color="text.secondary">
                Synthesizing datasets...
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {Math.round(classifyProgress * 100)}%{" "}
                {classifyTagsCreated > 0
                  ? `(${classifyTagsCreated.toLocaleString()} tag value pairs created)`
                  : ""}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={classifyProgress * 100}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: "#E0E0E0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#4FA892",
                  borderRadius: 4,
                },
              }}
            />
          </Box>
        )}
        <button
          onClick={handleClassify}
          disabled={isClassifying || allFilesSynthesized}
          className={`w-full mt-2 py-2 px-4 border rounded-md flex items-center justify-center gap-2 ${
            isClassifying || allFilesSynthesized
              ? "bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-gray-50 border-gray-300 text-gray-700 hover:bg-gray-100"
          }`}
        >
          <Zap
            className={`w-4 h-4 ${allFilesSynthesized ? "text-gray-400" : "text-amber-500"}`}
          />
          <span className="font-bold text-left">
            {isClassifying
              ? "Preparing..."
              : allFilesSynthesized
                ? "All datasets prepared"
                : "Prepare datasets for metadata suggestion"}
          </span>
        </button>
        {allFilesSynthesized && (
          <p className="text-sm text-gray-500 italic text-center">
            All available datasets have been prepared for suggestion. No further
            synthesis needed.
          </p>
        )}
      </div>

      {/* Advanced Features Section */}
      <div className="border rounded-lg">
        <button
          onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          className="w-full flex items-center justify-between p-4 hover:bg-gray-50 text-left"
        >
          <div className="flex items-center space-x-3">
            <Settings size={20} className="text-gray-400" />
            <span className="font-medium">Settings</span>
          </div>
          <ChevronDown
            className={`transform transition-transform ${
              isAdvancedOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {isAdvancedOpen && (
          <div className="p-4 space-y-4 border-t">
            {/* Tree Depth */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-center flex-1">
                  Max Tree Depth
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Controls how many levels deep the schema tree should be
                    generated. Recommend Depth 2~5.
                  </div>
                </div>
              </div>
              <input
                type="number"
                min={1}
                className="w-2/3 rounded-md p-2 border border-gray-300"
                value={maxDepth}
                onChange={(e) => setMaxDepth(e.target.value)}
              />
            </div>

            {/* Tag Type */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-center flex-1">
                  Tag Type
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Determines the type of tags to generate. Mixed for both
                    open-ends and yes/no tags.
                  </div>
                </div>
              </div>
              <select
                className="w-2/3 border border-gray-300 rounded-md p-2"
                value={tagType}
                onChange={(e) => setTagType(e.target.value)}
              >
                <option value="open_ended">Open-Ended</option>
                <option value="yes_no">Yes/No</option>
                <option value="unrestricted">Unrestricted</option>
              </select>
            </div>

            {/* Context Level Selection */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-center flex-1">
                  Dataset Context Level
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    Synthesized context level used for auto .
                  </div>
                </div>
              </div>
              <select
                className="w-2/3 border border-gray-300 rounded-md p-2"
                value={scope}
                onChange={(e) => setScope(e.target.value)}
              >
                <option value="File">File</option>
                <option value="Chunk">Chunk</option>
              </select>
            </div>

            {/* Resolution Slider */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-center flex-1">
                  Resolution
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    1 means covering all datasets in the synthesized context,
                    more accurate but costing more time and tokens to process.
                  </div>
                </div>
              </div>
              <div className="w-2/3 flex flex-col space-y-1">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={resolution}
                  onChange={(e) => setResolution(parseFloat(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary"
                />
                <div className="flex justify-between text-xs text-gray-500">
                  <span>{resolution.toFixed(2)}</span>
                </div>
              </div>
            </div>

            {/* Select Base Tags */}
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-2 w-1/3">
                <label className="text-sm font-medium text-gray-700 text-center flex-1">
                  Base Tags
                </label>
                <div className="group relative">
                  <Info size={16} className="text-gray-400 cursor-help" />
                  <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded shadow-lg">
                    The tags the hierarchical tree will be primarily composed of
                    in the output.
                  </div>
                </div>
              </div>
              <div className="w-2/3">
                <select
                  className="w-full border border-gray-300 rounded-md p-2"
                  value={selectedSource}
                  onChange={(e) => handleSourceSelect(e.target.value)}
                >
                  <option value="existing">Tags from Library</option>
                  <option value="extracted">
                    Extracted Tags (from documents)
                  </option>
                  <option value="netNew">Undefined (AI generate)</option>
                </select>

                {/* Tags List Dropdown */}
                {(selectedSource === "existing" ||
                  selectedSource === "extracted") && (
                  <div className="mt-2 border border-gray-200 rounded-md max-h-32 overflow-y-auto">
                    {selectedSource === "existing" &&
                      savedTags.map((tag, idx) => (
                        <div
                          key={idx}
                          className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                        >
                          {tag.name}
                        </div>
                      ))}
                    {selectedSource === "extracted" &&
                      uniqueTags.map((tag, idx) => (
                        <div
                          key={idx}
                          className="p-2 text-sm hover:bg-gray-50 border-b border-gray-100"
                        >
                          {tag.name}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Input Context */}
            <div className="flex items-center justify-between space-x-4">
              <label className="text-sm font-medium text-gray-700 w-1/3 text-center">
                Other Context
              </label>
              <textarea
                className="w-2/3 rounded-md p-2 border border-gray-300"
                value={inputTextContext}
                onChange={(e) => setInputTextContext(e.target.value)}
                placeholder="Other context that you think would be helpful to generate schemas"
                rows={3}
              />
            </div>
          </div>
        )}
      </div>

      {/* Action Button */}
      <button
        onClick={handleAutoSuggestClick}
        className={`w-full mt-3 px-6 py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
          autoSuggesting || disableAutoSuggest || !synthesizedFilesCount
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-primary text-white hover:bg-emerald-700 shadow-lg active:transform active:scale-[0.98]"
        }`}
        title={!synthesizedFilesCount ? "Please synthesize datasets first" : ""}
        disabled={
          autoSuggesting || disableAutoSuggest || !synthesizedFilesCount
        }
      >
        {autoSuggesting ? (
          <>
            <span className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
            <span>Thinking...</span>
          </>
        ) : (
          <>
            <span>Suggest</span>
          </>
        )}
      </button>

      {/* Mini Model Warning Dialog */}
      <Modal
        open={showMiniWarning}
        onClose={() => setShowMiniWarning(false)}
        aria-labelledby="mini-warning-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4 text-red"
            id="mini-warning-title"
          >
            Model Not Recommended
          </h2>
          <p className="text-gray-600 mb-6">
            GPT-4o-mini is not recommended for tag suggestion as it may produce
            less accurate and comprehensive results. Please switch to another
            endpoint in your LLM configuration for better results.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowMiniWarning(false)}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowMiniWarning(false);
                setShowConfirmDialog(true);
              }}
              className="px-4 py-2 rounded-lg bg-amber-700 text-white font-semibold hover:bg-amber-700"
            >
              Proceed Anyway
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Dialog */}
      <Modal
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-auto-suggest-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[500px]">
          <h2
            className="text-xl font-semibold mb-4"
            id="confirm-auto-suggest-title"
          >
            Confirm Auto-Suggestion
          </h2>
          <p className="text-gray-600 mb-6">
            This will generate a hierarchical schema tree based on your dataset
            context. The process may take a few seconds depending on the size of
            your dataset. Do you want to proceed?
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowConfirmDialog(false)}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                setShowConfirmDialog(false);
                await handleAutoSuggest();
              }}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
