import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../../services/api";
import MetadataTable from "../../MetadataTable/MetadataTable";

const InfoTooltip = () => (
  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 flex items-center gap-1 text-xs text-gray-500 bg-white/90 px-2 py-0.5 rounded-full shadow-sm border border-gray-100 backdrop-blur-sm">
    <svg
      className="w-3 h-3"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <span>Click on bars to view files</span>
  </div>
);

export const NodeInfoModal = ({
  nodeData,
  nodeType,
  onClose,
  portalTarget = document.body,
}) => {
  const { deasyUserConfig, savedTags } = useContext(BaseContext);
  const [tagStats, setTagStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [valueSearchQuery, setValueSearchQuery] = useState("");
  const [valueLimit, setValueLimit] = useState(10);
  const [selectedValue, setSelectedValue] = useState(null);
  const [tableFilters, setTableFilters] = useState({});

  // Find the tag details from savedTags
  const tagDetails = savedTags.find((tag) => tag.name === nodeData.label);

  useEffect(() => {
    const fetchTagStatistics = async () => {
      try {
        setLoading(true);
        const activeVdbConfig =
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ] || {};

        const nodePathToConditions = (nodePath) => {
          // Handle array input
          if (Array.isArray(nodePath)) {
            const conditions = [];
            // Skip root node if present
            const startIndex = nodePath[0] === "root" ? 1 : 0;
            // Process pairs of elements (tag and value)
            for (let i = startIndex; i < nodePath.length - 1; i += 2) {
              conditions.push({
                tag_id: nodePath[i],
                values: [nodePath[i + 1]],
              });
            }
            // For first tag (when no value is selected yet)
            if (nodePath.length === 1 && nodePath[0] !== "root") {
              conditions.push({
                tag_id: nodePath[0],
                values: [],
              });
            }
            return conditions;
          }

          // Handle string input with ">" separator
          const conditions = [];
          const pathParts = nodePath.split(">");
          // Skip root node if present
          const startIndex = pathParts[0] === "root" ? 1 : 0;
          for (let i = startIndex; i < pathParts.length - 1; i += 2) {
            conditions.push({
              tag_id: pathParts[i],
              values: [pathParts[i + 1]],
            });
          }
          // For first tag (when no value is selected yet)
          if (pathParts.length === 2 && pathParts[0] !== "root") {
            conditions.push({
              tag_id: pathParts[0],
              values: [],
            });
          }
          return conditions;
        };

        const response = await metadataService.getTagStatistics(
          activeVdbConfig,
          [nodeData.label],
          deasyUserConfig.deasyApiKey,
          nodePathToConditions(nodeData.nodePath),
        );

        setTagStats(response.data.tag_statistics[nodeData.label]);
      } catch (error) {
        console.error("Error fetching tag statistics:", error);
      } finally {
        setLoading(false);
      }
    };

    if (nodeData.label) {
      fetchTagStatistics();
    }
  }, [nodeData, deasyUserConfig]);

  const prepareExtractionData = () => {
    if (!tagStats?.extraction_stats) return [];

    return [
      {
        name: "Extracted",
        value: tagStats.extraction_stats.extracted.count,
        color: "#4FA892",
        percentage: tagStats.extraction_stats.extracted.percentage,
      },
      {
        name: "Not Extracted",
        value: tagStats.extraction_stats.not_extracted.count,
        color: "#6c757d",
        percentage: tagStats.extraction_stats.not_extracted.percentage,
      },
    ];
  };

  const getFilteredChartData = () => {
    if (!tagStats?.value_distribution) return [];

    let data = Object.entries(tagStats.value_distribution)
      .map(([value, stats]) => ({
        value,
        count: stats.count,
        percentage: stats.percentage,
      }))
      // Filter based on search query
      .filter((item) =>
        item.value.toLowerCase().includes(valueSearchQuery.toLowerCase()),
      )
      // Sort by count in descending order
      .sort((a, b) => b.count - a.count);

    // Apply limit if no search query
    if (!valueSearchQuery && valueLimit > 0) {
      data = data.slice(0, valueLimit);
    }

    return data;
  };

  const truncateValue = (value, maxLength = 15) => {
    if (value.length <= maxLength) return value;
    return value.slice(0, maxLength) + "...";
  };

  const handleBarClick = (data) => {
    const newValue = {
      tagName: nodeData.label,
      value: data.value,
    };
    setSelectedValue(newValue);
    // Initialize filters when bar is clicked
    setTableFilters({
      [nodeData.label]: [String(data.value)],
    });
  };

  const modalContent = (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] animate-fadeIn"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div
        className="bg-white rounded-lg p-6 w-[78vw] max-h-[90vh] overflow-y-auto mx-4 animate-slideIn"
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">
                Tag: {nodeData.label}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600 p-1"
              >
                ✕
              </button>
            </div>
            {tagDetails && (
              <div className="mb-6 space-y-4">
                {tagDetails.description && (
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Description</p>
                    <p className="text-sm text-gray-700">
                      {tagDetails.description}
                    </p>
                  </div>
                )}
                {tagDetails.available_values &&
                  tagDetails.available_values.length > 0 && (
                    <div>
                      <p className="text-sm text-gray-500 mb-1">
                        Available Values
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {tagDetails.available_values.map((value, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary/10 text-primary"
                          >
                            {value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            )}

            <div className="mb-4">
              <h2 className="text-lg font-medium mb-2">Node Information</h2>
              <p className="text-gray-600">
                View and manage metadata associated with this node. Track
                coverage and tagging progress.
              </p>
            </div>

            <div className="space-y-8">
              {/* Overview Section */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="grid grid-cols-3 gap-8">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Files Tagged</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {tagStats?.total_files_with_tag?.toLocaleString() || 0}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Total Files</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {(
                        tagStats?.extraction_stats?.extracted.count +
                        tagStats?.extraction_stats?.not_extracted.count
                      )?.toLocaleString() || 0}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Coverage</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {tagStats?.percentage_of_total?.toFixed(1)}%
                    </p>
                  </div>
                </div>

                {/* Extraction Status Chart */}
                <div className="mt-6">
                  <p className="text-sm text-gray-500 mb-4">
                    Extraction Status
                  </p>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={prepareExtractionData()}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          label={({ name, percent }) =>
                            `${name} (${(percent * 100).toFixed(0)}%)`
                          }
                        >
                          {prepareExtractionData().map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              {/* Value Distribution Chart */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
                    Value Distribution
                  </h3>
                  <div className="flex gap-4 items-center">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search values..."
                        value={valueSearchQuery}
                        onChange={(e) => setValueSearchQuery(e.target.value)}
                        className="px-3 py-1 border rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-primary"
                      />
                      {valueSearchQuery && (
                        <button
                          onClick={() => setValueSearchQuery("")}
                          className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        >
                          ✕
                        </button>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="text-sm text-gray-600">Show top:</label>
                      <select
                        value={valueLimit}
                        onChange={(e) => setValueLimit(Number(e.target.value))}
                        className="px-2 py-1 border rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-primary"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={0}>All</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="h-96">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={getFilteredChartData()}
                      margin={{ bottom: 70, right: 20 }}
                      className="group"
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                      <XAxis
                        dataKey="value"
                        tick={(props) => {
                          const { x, y, payload } = props;
                          return (
                            <g transform={`translate(${x},${y})`}>
                              <text
                                x={0}
                                y={0}
                                dy={16}
                                textAnchor="end"
                                fill="#343a40"
                                transform="rotate(-45)"
                                className="cursor-pointer hover:fill-primary"
                              >
                                {truncateValue(payload.value)}
                              </text>
                            </g>
                          );
                        }}
                        interval={0}
                        height={90}
                      />
                      <YAxis tick={{ fill: "#343a40" }} />
                      <Tooltip
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <div className="bg-white border border-gray-200 p-2 rounded shadow-lg">
                                <p className="text-sm font-medium break-all">
                                  {payload[0].payload.value}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Count: {payload[0].value}
                                </p>
                                <p className="text-xs text-primary italic mt-1">
                                  Click to view files
                                </p>
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar
                        dataKey="count"
                        fill="#4FA892"
                        radius={[4, 4, 0, 0]}
                        className="hover:opacity-80 transition-opacity duration-200"
                        onClick={(data) => handleBarClick(data.payload)}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  <InfoTooltip />
                </div>
              </div>
            </div>

            {selectedValue && (
              <div className="mt-8">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium">
                    Files tagged with {selectedValue.tagName}:{" "}
                    {selectedValue.value}
                  </h3>
                  <button
                    onClick={() => {
                      setSelectedValue(null);
                      setTableFilters({}); // Clear filters when closing
                    }}
                    className="text-gray-400 hover:text-gray-600 p-1"
                  >
                    ✕
                  </button>
                </div>
                <MetadataTable
                  backendFilters={tableFilters}
                  onFiltersChange={setTableFilters}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, portalTarget);
};
