import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { metadataService } from "../../../../services/api";

export const useJobProgress = ({
  jobId,
  isRunning,
  onComplete,
  onError,
  pollInterval = 7000,
  apiKey,
  successMessage = "Task completed successfully",
}) => {
  const [progress, setProgress] = useState(0.0);
  const [additionalStats, setAdditionalStats] = useState({});

  useEffect(() => {
    const trackJobProgress = async () => {
      try {
        if (!jobId || !isRunning) return;

        const response = await metadataService.trackJob(jobId, apiKey);

        // Calculate progress as a number between 0 and 1
        const currentProgress = Number(
          (
            response.data.completed_progress_increments /
            response.data.total_progress_increments
          ).toFixed(4),
        );

        // Only update if there's actual progress
        if (currentProgress > progress) {
          setProgress(currentProgress);
          // Store any additional stats from the response
          setAdditionalStats(response.data);
        }

        // Check if job is complete
        if (response.data.status !== "in_progress") {
          // Reset states
          setProgress(0.0);
          setAdditionalStats({});

          // Show success message
          toast.success(successMessage);

          // Call completion callback
          if (onComplete) {
            onComplete(response.data);
          }
        }
      } catch (error) {
        console.error("Job tracking error:", error);
        // Reset states
        setProgress(0.0);
        setAdditionalStats({});

        // Call error callback
        if (onError) {
          onError(error);
        }
      }
    };

    // Only set up polling if we have an active job
    if (jobId && isRunning) {
      // Initial check
      trackJobProgress();
      // Set up polling interval
      const pollTimer = setInterval(trackJobProgress, pollInterval);
      return () => clearInterval(pollTimer);
    }
  }, [
    jobId,
    isRunning,
    progress,
    apiKey,
    onComplete,
    onError,
    pollInterval,
    successMessage,
  ]);

  return {
    progress,
    additionalStats,
  };
};
