import { useState, useContext } from "react";
import { AiOutlineTag } from "react-icons/ai";
import { FaFileAlt, FaSearch } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { BaseContext } from "../../../../contexts/BaseContext";

// Icons for copy buttons
const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
    />
  </svg>
);

const CopyableField = ({
  label,
  icon: Icon,
  value,
  onChange,
  placeholder = "—",
  copyKey,
  copySuccess,
  handleCopy,
  readOnly = false,
}) => {
  return (
    <div className="flex flex-col gap-y-1 whitespace-pre-wrap max-h-[10vh] overflow-y-auto w-full">
      <div className="flex items-center gap-x-2">
        <Icon className="text-emerald-500 text-xl" />
        <h4 className="text-xs font-semibold text-gray-700 uppercase tracking-wider">
          {label}
        </h4>
      </div>

      <div
        className={`relative text-left text-gray-700 p-2 rounded-md text-sm mt-1 border border-gray-200 shadow-sm
          ${readOnly ? "bg-gray-100 text-gray-400 select-none" : "bg-gray-50"}`}
      >
        {readOnly ? (
          <span>{value || placeholder}</span>
        ) : (
          <div className="overflow-y-auto whitespace-normal max-h-[10vh]">
            <textarea
              className="w-full bg-transparent border-none focus:outline-none p-0 resize-none"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              rows={1}
              style={{ minHeight: "5vh" }}
            />
          </div>
        )}

        {value && (
          <button
            className={`absolute top-1/2 -translate-y-1/2 right-2 p-1 rounded-md transition-all duration-200 
              ${
                copySuccess[copyKey]
                  ? "bg-green-100 text-green-600"
                  : "bg-gray-100/70 text-gray-500 hover:bg-gray-200/70 hover:text-gray-700"
              }`}
            onClick={() => handleCopy(copyKey)}
          >
            {copySuccess[copyKey] ? <CheckIcon /> : <CopyIcon />}
          </button>
        )}
      </div>
    </div>
  );
};

export default function TSOutputCard({
  value,
  evidence,
  filename,
  pointId,
  setActiveTagEditorSection,
  setTagInEditor,
  tagInEditor,
}) {
  // Track copy states
  const [copySuccess, setCopySuccess] = useState({
    value: false,
    evidence: false,
    filename: false,
  });

  // Local states for "value" and "evidence" to make them editable
  const [editableValue, setEditableValue] = useState(value || "");
  const [editableEvidence, setEditableEvidence] = useState(evidence || "");

  const { handleSaveMetadata } = useContext(BaseContext);

  /**
   * Writes the appropriate text to clipboard based on the key,
   * and triggers a brief success visual.
   */
  const handleCopy = (textKey) => {
    navigator.clipboard.writeText(
      textKey === "value"
        ? editableValue
        : textKey === "evidence"
          ? editableEvidence
          : filename,
    );
    setCopySuccess((prev) => ({ ...prev, [textKey]: true }));

    // Reset after 2 seconds
    setTimeout(() => {
      setCopySuccess((prev) => ({ ...prev, [textKey]: false }));
    }, 2000);
  };

  /**
   * Handle adding a new example to the tag and auto-save.
   */
  const handleAddExample = async (e, isPositive) => {
    setActiveTagEditorSection("examples");
    e.preventDefault();
    const exampleEvidence = `TEXT:${editableEvidence}\nFILENAME:${filename}`;

    // Create the new example
    const newExample = {
      id: uuidv4(),
      value: editableValue,
      evidence: exampleEvidence,
      isPositive,
    };

    // Create updated tag with new example
    const updatedTag = {
      ...tagInEditor,
      tuned: true,
      examples: [...tagInEditor.examples, newExample],
    };

    // Update tag state first
    setTagInEditor(updatedTag);

    // Then save metadata
    await handleSaveMetadata(updatedTag);
  };

  return (
    <div className="flex flex-col gap-y-4 p-4 border border-gray-300 transition-transform duration-200">
      <div className="flex justify-between items-start mb-2 flex-col w-full text-left">
        <span className="text-xs text-gray-500 font-mono w-full mb-[1vh]">
          Chunk ID {pointId}
        </span>
        <CopyableField
          label="Tagged Value"
          icon={AiOutlineTag}
          value={editableValue}
          onChange={(e) => setEditableValue(e.target.value)}
          copyKey="value"
          copySuccess={copySuccess}
          handleCopy={handleCopy}
        />
      </div>

      <CopyableField
        label="Evidence"
        icon={FaSearch}
        value={editableEvidence}
        onChange={(e) => setEditableEvidence(e.target.value)}
        copyKey="evidence"
        copySuccess={copySuccess}
        handleCopy={handleCopy}
      />

      <CopyableField
        label="File"
        icon={FaFileAlt}
        value={filename ? filename.split("/").pop() : ""}
        readOnly
        copyKey="filename"
        copySuccess={copySuccess}
        handleCopy={handleCopy}
      />

      <div className="flex flex-row w-full space-x-2">
        <button
          className="w-1/2 px-2 py-1 text-xs text-emerald-600 bg-emerald-50 border border-emerald-200 rounded hover:bg-emerald-100 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={(e) => handleAddExample(e, true)}
        >
          + Positive
        </button>

        <button
          className="px-2 py-1 w-1/2 text-xs text-red-600 bg-red-50 border border-red-200 rounded hover:bg-red-100 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={(e) => handleAddExample(e, false)}
        >
          + Negative
        </button>
      </div>
    </div>
  );
}
