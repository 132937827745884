import React, { useContext, useEffect } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { updateTokenServiceSecret } from "../../DeasyConfig/ConfigUtils";
import { ProfileCard } from "../../DeasyConfig/ConfigElements/ProfileCard";
import { WorkflowType } from "../../../../models/WorkflowModel";
import { auth } from "../../../../config/firebase";
import { metadataService } from "../../../../services/api";
import { prepareVectorDBConfiguration } from "../../../../services/utils";
import { GraphContext } from "../../../../contexts/GraphContext";
import {
  getDisplayType,
  DB_OPTIONS,
} from "../../DeasyConfig/ConfigElements/utils";

const ConnectDataScreen = () => {
  const {
    deasyUserConfig,
    setDeasyUserConfig,
    setCurrentScreen,
    vdbFilesCount,
    setVdbFilesCount,
    loadingVdbFilesCount,
    setLoadingVdbFilesCount,
    setFiles,
    setOffset,
    setOutOfData,
  } = useContext(BaseContext);

  const { setHierarchyStats, discoveryGraphData } = useContext(GraphContext);

  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const llmConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];
  const profiles = deasyUserConfig?.vdbmConfig?.Configs || {};
  const activeProfile = deasyUserConfig?.vdbmConfig?.LastActive;

  const handleConfigLoad = (name) => {
    if (name === activeProfile) {
      setCurrentScreen(WorkflowType.VIEW_METADATA);
      return;
    }
    if (deasyUserConfig?.vdbmConfig?.Configs?.[name]) {
      // Update LastActive in deasyUserConfig
      const newConfig = {
        ...deasyUserConfig,
        vdbmConfig: {
          ...deasyUserConfig.vdbmConfig,
          LastActive: name,
        },
      };
      setDeasyUserConfig(newConfig);
      setFiles([]);
      setOffset(0);
      setOutOfData(false);
      updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
    }
  };

  // 1) Fetch total files exactly once when VDB config changes (and only if valid).
  useEffect(
    () => {
      const fetchTotalVdbFiles = async () => {
        // Skip if already loading or we already have a count
        if (loadingVdbFilesCount || vdbFilesCount?.profile === activeProfile)
          return;

        // Validate configuration first
        const dbOption = DB_OPTIONS.find(
          (opt) => opt.value === vectorDBConfiguration?.type,
        );
        const hasRequiredFields = dbOption?.fields
          .filter((field) => !field.toLowerCase().includes("key"))
          .every((field) => vectorDBConfiguration?.[field]);

        if (!dbOption || !hasRequiredFields) {
          console.log(
            "Skipping VDB files count fetch - incomplete vector DB config",
          );
          return;
        }

        try {
          setLoadingVdbFilesCount(true);
          console.log("Fetching VDB files count...");
          const response = await metadataService.getVdbTotalFiles(
            prepareVectorDBConfiguration({
              ...vectorDBConfiguration,
              user: auth.currentUser.email,
            }),
            deasyApiKey,
          );
          console.log("VDB files count fetched:", response.data.total_files);
          setVdbFilesCount({
            total_files: response.data.total_files,
            profile: activeProfile,
          });
        } catch (error) {
          console.error("Error fetching total VDB files:", error);
          // Don't set to null on error, keep previous state
        } finally {
          setLoadingVdbFilesCount(false);
        }
      };

      if (deasyApiKey && vectorDBConfiguration) {
        fetchTotalVdbFiles();
      }
    },
    // eslint-disable-next-line
    [
      deasyApiKey,
      vectorDBConfiguration,
      setVdbFilesCount,
      setLoadingVdbFilesCount,
      DB_OPTIONS,
    ],
  );

  // 2) Fetch initial stats only if there's an actual graph
  useEffect(() => {
    const fetchInitialStats = async () => {
      try {
        const dbOption = DB_OPTIONS.find(
          (opt) => opt.value === vectorDBConfiguration?.type,
        );
        const hasRequiredFields = dbOption?.fields
          .filter((field) => !field.toLowerCase().includes("key")) // Skip sensitive fields
          .every((field) => vectorDBConfiguration?.[field]);

        if (
          !dbOption ||
          !hasRequiredFields ||
          !discoveryGraphData ||
          Object.keys(discoveryGraphData).length === 0
        ) {
          console.log(
            "Skipping hierarchy stats fetch - no graph or incomplete VDB config",
          );
          return;
        }

        const activeLlmConfig = llmConfiguration || {};
        // Build conditions for all nodes in the tree
        const buildTreeConditions = (data) => {
          const conditions = [];
          const processNode = (node, tagId) => {
            if (!node || typeof node !== "object") return;
            // If node has TagAvailableValues, it's a tag node
            if (node.TagAvailableValues) {
              conditions.push({
                tag_id: tagId,
                values: node.TagAvailableValues,
              });
            }
            // Process child nodes
            Object.entries(node).forEach(([key, value]) => {
              if (key !== "TagAvailableValues" && typeof value === "object") {
                processNode(value, key);
              }
            });
          };
          Object.entries(data).forEach(([key, value]) => {
            processNode(value, key);
          });
          return conditions;
        };

        const conditions = buildTreeConditions(discoveryGraphData);

        const data = {
          vector_db_config: prepareVectorDBConfiguration({
            ...vectorDBConfiguration,
            user: auth.currentUser.email,
          }),
          endpoint_manager_config: activeLlmConfig,
          conditions: conditions,
          current_tree: JSON.stringify(discoveryGraphData) || [],
          include_extraction_stats: true,
        };

        const response = await metadataService.getHierarchyCountDistributions(
          data,
          deasyApiKey,
        );

        if (response.data?.hierarchy) {
          setHierarchyStats(response.data.hierarchy);
        }
      } catch (err) {
        console.error("Error fetching initial stats:", err);
      }
    };

    if (deasyApiKey && vectorDBConfiguration) {
      fetchInitialStats();
    }
  }, [
    deasyApiKey,
    vectorDBConfiguration,
    llmConfiguration,
    discoveryGraphData,
    setHierarchyStats,
  ]);

  return (
    <div className="flex-1 p-8">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(profiles).map(([name, config]) => (
            <ProfileCard
              key={name}
              type={getDisplayType(config.type)}
              name={name}
              isActive={name === activeProfile}
              onSelect={handleConfigLoad}
              showActions={false}
              details={Object.fromEntries([
                ["Type", getDisplayType(config.type)],
                ...DB_OPTIONS.find((opt) => opt.value === config.type)
                  ?.fields.filter(
                    (field) => !field.toLowerCase().includes("key"),
                  ) // Skip sensitive fields
                  .map((field) => [
                    // Convert camelCase to Title Case
                    field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase()),
                    config[field] || "N/A",
                  ]),
              ])}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConnectDataScreen;
