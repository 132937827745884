export const DB_OPTIONS = [
  {
    value: "QdrantVectorDBManager",
    label: "Qdrant",
    fields: ["apiKey", "collection", "url"],
  },
  {
    value: "S3DataSourceManager",
    label: "S3",
    fields: ["bucketName", "awsAccessKeyId", "awsSecretAccessKey"],
  },
  {
    value: "PSQLVectorDBManager",
    label: "PostgreSQL",
    fields: [
      "database_name",
      "collection",
      "url",
      "password",
      "db_user",
      "port",
    ],
  },
];

export const getDisplayType = (type) => {
  const option = DB_OPTIONS.find((opt) => opt.value === type);
  return option ? option.label : type;
};
