import React, { useContext } from "react";
import { FaVial } from "react-icons/fa";
import { BaseContext } from "../../contexts/BaseContext";

const TestTagButton = ({
  tagId,
  className = "",
  setIsTagSelectorExpanded = null,
}) => {
  const { setTagInEditor, savedTags, setActivePage } = useContext(BaseContext);

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling

    // Find the tag in savedTags
    const tag = savedTags.find((t) => t.tag_id === tagId || t.name === tagId);
    if (tag) {
      // Clear any previous tagging studio output

      // Set the tag in editor
      setTagInEditor(tag);
      // Switch to tagging studio screen
      setActivePage("metadata-studio");
      if (setIsTagSelectorExpanded) {
        setIsTagSelectorExpanded(false);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`inline-flex items-center justify-center gap-1.5 px-2 py-1 text-sm font-medium 
          text-primary hover:bg-primary/30 bg-primary bg-opacity-20
          transition-colors rounded-md border border-emerald-500 ${className}`}
      title="Test this tag"
    >
      <FaVial className="w-4 h-4" />
      Test
    </button>
  );
};

export default TestTagButton;
