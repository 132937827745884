import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../services/api";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  Fade,
} from "@mui/material";
import { FaFilter, FaSync } from "react-icons/fa";
import MetadataColumnSelector from "../DataDiscovery/ViewMetadata/MetadataColumnSelector";
import CreateDataSlice from "../../../../utils/CreateDataSlice";
import { WorkflowType } from "../../../../../models/WorkflowModel";
import DistributionChart from "./Charts/DistributionChart";
import {
  processTagValue,
  getHighlightTerms,
  highlightText,
  useDebounce,
} from "./metadataTableUtils";

const COLUMN_WIDTHS = {
  file_name: 300,
  default: 200,
};

const ExpandableSection = ({
  title,
  icon,
  isExpanded,
  onToggle,
  children,
  description,
}) => {
  const contentRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight);
    }
  }, [children]);

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div
        className="px-2 py-1 flex items-center justify-between cursor-pointer hover:bg-gray-50 transition-colors duration-200"
        onClick={onToggle}
      >
        <div className="flex flex-col items-left">
          <div className="flex items-center gap-2">
            <div className="text-primary">{icon}</div>
            <div className="font-medium text-gray-700">{title}</div>
          </div>
          {description && (
            <div className="pb-2 text-sm text-gray-500 text-left">
              {description}
            </div>
          )}
        </div>
        <span
          className="text-gray-400 transition-transform duration-300 ease-in-out"
          style={{
            transform: `rotate(${isExpanded ? 180 : 0}deg)`,
          }}
        >
          ▼
        </span>
      </div>

      <div
        className="transition-all duration-300 ease-in-out"
        style={{
          height: isExpanded ? `${height}px` : 0,
          opacity: isExpanded ? 1 : 0,
          overflow: "hidden",
        }}
      >
        <div ref={contentRef} className="border-t border-gray-100">
          {children}
        </div>
      </div>
    </div>
  );
};

const ActiveFilters = ({ filters, onRemove }) => {
  if (Object.keys(filters).length === 0) return null;

  return (
    <div className="mb-4">
      <div className="flex items-center gap-2 mb-2">
        <span className="text-sm text-gray-600">Active Filters</span>
        <span className="text-xs bg-gray-100 text-gray-500 px-2 py-0.5 rounded">
          All conditions must match (AND)
        </span>
      </div>
      <div className="flex flex-wrap gap-2 max-w-[200px]">
        {Object.entries(filters).map(([column, values]) =>
          values.map((value) => (
            <div
              key={`${column}-${value}`}
              className="flex items-center gap-2 bg-green-50 text-primary px-3 py-1 rounded-sm text-sm "
            >
              <span className="font-medium">{column}:</span>
              <span>{value}</span>
              <button
                onClick={() => onRemove(column, value)}
                className="hover:text-red-500 transition-colors"
              >
                ×
              </button>
            </div>
          )),
        )}
      </div>
    </div>
  );
};

const MetadataTable = ({
  backendFilters,
  onFiltersChange,
  usecase_id = null,
  compact = false,
}) => {
  const { deasyUserConfig, isConfigRefreshing } = useContext(BaseContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [refreshKey, setRefreshKey] = useState(0);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [loadingValues, setLoadingValues] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [globalDistributions, setGlobalDistributions] = useState({});
  const [filteredDistributions, setFilteredDistributions] = useState({});
  const [chunkData, setChunkData] = useState({});
  const [loadingChunks, setLoadingChunks] = useState({});
  const [loadingDistributions, setLoadingDistributions] = useState(true);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const initialColumns = ["file_name"];
    Object.keys(backendFilters).forEach((column) => {
      if (!initialColumns.includes(column)) {
        initialColumns.push(column);
      }
    });
    return initialColumns;
  });
  const [availableColumns, setAvailableColumns] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    controls: false,
    filters: false,
    help: false,
  });
  const [distributionLimit] = useState(10);
  const [showDistributions] = useState(true);
  const [showCreateDataSlice, setShowCreateDataSlice] = useState(false);
  const { setCurrentScreen, setSelectedUseCase } = useContext(BaseContext);
  const [activeEvidence, setActiveEvidence] = useState(null);
  const [activeFullText, setActiveFullText] = useState(null);
  const [, setVisibleItemsCount] = useState({});
  const [evidenceRefs] = useState(() => new Map());

  const debouncedExpandedSections = useDebounce(expandedSections, 100);

  // Add a ref to track if the component is mounted
  const isMounted = useRef(true);

  const handleSectionToggle = React.useCallback((section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  }, []);

  const handleColumnSelection = React.useCallback((columns) => {
    // Ensure file_name is always included in the selection
    const updatedColumns = columns.includes("file_name")
      ? columns
      : ["file_name", ...columns];
    setSelectedColumns(updatedColumns);
  }, []);

  const handleShowAllColumns = React.useCallback((show) => {
    setShowAllColumns(show);
  }, []);

  const columnSelectorProps = React.useMemo(
    () => ({
      availableColumns,
      selectedColumns,
      setSelectedColumns: handleColumnSelection,
      showAllColumns,
      setShowAllColumns: handleShowAllColumns,
    }),
    [
      availableColumns,
      selectedColumns,
      showAllColumns,
      handleColumnSelection,
      handleShowAllColumns,
    ],
  );

  const displayColumns = useMemo(() => {
    if (showAllColumns) return availableColumns;
    return selectedColumns.includes("file_name")
      ? selectedColumns
      : ["file_name", ...selectedColumns];
  }, [showAllColumns, availableColumns, selectedColumns]);

  const fetchChunkMetadata = async (fileName) => {
    try {
      setLoadingChunks((prev) => ({ ...prev, [fileName]: true }));

      // Add a minimum loading time to prevent flickering
      const startTime = Date.now();

      const activeVdbConfig =
        prepareVectorDBConfiguration(
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ],
        ) || {};

      const response = await metadataService.listMetadataPostgres(
        activeVdbConfig,
        deasyUserConfig.deasyApiKey,
        "node",
        null,
        [fileName],
        null,
        null,
      );

      // Ensure minimum loading time of 300ms
      const elapsed = Date.now() - startTime;
      if (elapsed < 300) {
        await new Promise((resolve) => setTimeout(resolve, 300 - elapsed));
      }

      // Transform the response data to match the expected format
      const transformedMetadata = {};
      if (response.metadata && response.metadata[fileName]) {
        Object.entries(response.metadata[fileName]).forEach(
          ([chunkId, chunkData]) => {
            const transformedTags = {};
            Object.entries(chunkData.tags || {}).forEach(
              ([tagName, tagData]) => {
                transformedTags[tagName] = tagData.value;
              },
            );
            transformedMetadata[chunkId] = {
              tags: transformedTags,
            };
          },
        );
      }

      setChunkData((prev) => ({
        ...prev,
        [fileName]: transformedMetadata,
      }));
    } catch (error) {
      console.error("Error fetching chunk metadata:", error);
      setError(`Failed to load chunks for ${fileName}`);
    } finally {
      setLoadingChunks((prev) => ({ ...prev, [fileName]: false }));
    }
  };

  const toggleRowExpansion = (fileName) => {
    setExpandedRow((prev) => {
      // If clicking the same row, collapse it
      if (prev === fileName) {
        return null;
      }
      // If expanding a new row, fetch its chunk metadata
      if (!chunkData[fileName]) {
        fetchChunkMetadata(fileName);
      }
      return fileName;
    });
  };

  const handleRemoveFilter = (column, value) => {
    const newFilters = { ...backendFilters };
    const valueIndex = newFilters[column].indexOf(value);

    if (valueIndex !== -1) {
      newFilters[column] = [
        ...newFilters[column].slice(0, valueIndex),
        ...newFilters[column].slice(valueIndex + 1),
      ];

      if (newFilters[column].length === 0) {
        delete newFilters[column];
      }
    }
    onFiltersChange(newFilters);
    setPage(0);
  };

  useEffect(() => {
    const fetchDistributionsAndMetadata = async () => {
      try {
        setLoadingDistributions(true);
        setLoading(true);
        setError(null);

        const activeVdbConfig =
          prepareVectorDBConfiguration(
            deasyUserConfig.vdbmConfig?.Configs?.[
              deasyUserConfig.vdbmConfig?.LastActive
            ],
          ) || {};

        // Fetch all data in parallel with usecase_id
        const [globalResponse, filteredMetadataResponse] = await Promise.all([
          metadataService.getDistributions(
            activeVdbConfig,
            deasyUserConfig.deasyApiKey,
            usecase_id,
          ),
          metadataService.getFilteredMetadata(
            activeVdbConfig,
            Object.entries(backendFilters).map(([tagId, values]) => ({
              tag_id: tagId,
              values: values,
            })),
            page * rowsPerPage,
            rowsPerPage,
            null,
            "asc",
            deasyUserConfig.deasyApiKey,
            usecase_id,
          ),
        ]);

        if (!isMounted.current) return;

        if (globalResponse?.data) {
          setGlobalDistributions(globalResponse.data);
          setFilteredDistributions(globalResponse.data);

          // Update unique values for filters
          const uniqueValues = {};
          Object.entries(globalResponse.data).forEach(
            ([tagId, distribution]) => {
              uniqueValues[tagId] = Object.keys(distribution);
            },
          );
          setAvailableColumns(Array.from(uniqueValues));
        }

        if (filteredMetadataResponse?.data?.metadata) {
          const transformedData = Object.entries(
            filteredMetadataResponse.data.metadata,
          ).map(([fileName, metadata]) => ({
            file_name: fileName,
            ...metadata,
            chunks: [],
          }));

          // Set columns before setting table data
          if (transformedData.length > 0) {
            const firstRow = transformedData[0];
            const cols = ["file_name"];
            Object.keys(firstRow).forEach((key) => {
              if (key !== "file_name" && key !== "chunks") {
                cols.push(key);
              }
            });
            setAvailableColumns(cols);
          }

          setTableData(transformedData);
          setTotalRows(filteredMetadataResponse.data.total_matches);
          setHasMore(filteredMetadataResponse.data.next_offset !== null);

          // Calculate filtered distributions
          const distributions = {};
          transformedData.forEach((row) => {
            Object.entries(row).forEach(([key, value]) => {
              if (key !== "file_name" && key !== "chunks") {
                if (!distributions[key]) distributions[key] = {};

                // Handle array values by counting each element separately
                if (Array.isArray(value)) {
                  value.forEach((item) => {
                    const processedItem = processTagValue(item);
                    if (!distributions[key][processedItem]) {
                      distributions[key][processedItem] = 0;
                    }
                    distributions[key][processedItem]++;
                  });
                } else {
                  // Handle non-array values
                  const processedValue = processTagValue(value);
                  if (!distributions[key][processedValue]) {
                    distributions[key][processedValue] = 0;
                  }
                  distributions[key][processedValue]++;
                }
              }
            });
          });
          setFilteredDistributions(distributions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted.current) {
          setError(error.message || "Failed to fetch metadata");
        }
      } finally {
        if (isMounted.current) {
          setLoadingDistributions(false);
          setLoading(false);
        }
      }
    };

    fetchDistributionsAndMetadata();
  }, [
    deasyUserConfig,
    page,
    rowsPerPage,
    refreshKey,
    backendFilters,
    usecase_id,
  ]);

  // Cleanup function
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchUniqueValues = async (column) => {
    try {
      setLoadingValues(true);
      const activeVdbConfig =
        prepareVectorDBConfiguration(
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ],
        ) || {};

      const response = await metadataService.getDistinctValues(
        activeVdbConfig,
        deasyUserConfig.deasyApiKey,
        column,
      );

      if (response?.data) {
        setAvailableColumns((prev) => [...prev, column]);
      }
    } catch (error) {
      console.error("Error fetching unique values:", error);
      setError(`Failed to fetch values for ${column}`);
    } finally {
      setLoadingValues(false);
    }
  };

  const handleFilterClick = async (event, column) => {
    setAnchorEl(event.currentTarget);
    setActiveColumn(column);
    await fetchUniqueValues(column);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn(null);
  };

  const handleFilterSelect = async (value) => {
    if (!activeColumn) return;

    const newFilters = { ...backendFilters };
    if (!newFilters[activeColumn]) {
      newFilters[activeColumn] = [];
    }
    if (!newFilters[activeColumn].includes(value)) {
      newFilters[activeColumn] = [...newFilters[activeColumn], value];
    }
    onFiltersChange(newFilters);

    // Force expand the filters section
    setExpandedSections((prev) => ({
      ...prev,
      filters: true,
    }));

    handleFilterClose();
  };

  const handleClearFilters = () => {
    if (activeColumn) {
      onFiltersChange({});
    }
    handleFilterClose();
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    // Clear all filters
    onFiltersChange({});
    // Reset page
    setPage(0);
    // Reset expanded sections
    setExpandedSections((prev) => ({
      ...prev,
      filters: false,
    }));
    // Refresh data
    setRefreshKey((prev) => prev + 1);
  };

  const handleDistributionClick = async (column, value) => {
    try {
      setLoading(true);

      const newFilters = { ...backendFilters };
      if (!newFilters[column]) {
        newFilters[column] = [];
      }

      const valueIndex = newFilters[column].indexOf(value);
      if (valueIndex === -1) {
        newFilters[column] = [...newFilters[column], value];
        // Force expand the filters section when adding a new filter
        setExpandedSections((prev) => ({
          ...prev,
          filters: true,
        }));
      } else {
        newFilters[column] = [
          ...newFilters[column].slice(0, valueIndex),
          ...newFilters[column].slice(valueIndex + 1),
        ];

        if (newFilters[column].length === 0) {
          delete newFilters[column];
        }
      }

      onFiltersChange(newFilters);
      setPage(0);
    } catch (error) {
      console.error("Error applying distribution filter:", error);
      setError("Failed to apply distribution filter");
    } finally {
      setLoading(false);
    }
  };

  const toggleDistributionExpansion = (column, direction = "more") => {
    setVisibleItemsCount((prev) => {
      const currentCount = prev[column] || distributionLimit;

      if (direction === "more") {
        // Simply add 10 more without checking total
        return { ...prev, [column]: currentCount + 10 };
      } else {
        // Reduce by 10, but don't go below minimum
        return {
          ...prev,
          [column]: Math.max(currentCount - 10, distributionLimit),
        };
      }
    });
  };

  const renderDistributionChart = (column) => {
    if (!showDistributions) return null;

    if (loadingDistributions) {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <CircularProgress size={20} sx={{ color: "#9ca3af" }} />
        </div>
      );
    }

    const currentDistributions = filteredDistributions[column] || {};
    const globalColumnDistributions = globalDistributions[column] || {};

    if (
      !currentDistributions ||
      Object.keys(currentDistributions).length === 0
    ) {
      return (
        <div className="h-full w-full flex items-center justify-center text-gray-400 text-xs">
          No data
        </div>
      );
    }

    return (
      <DistributionChart
        column={column}
        currentDistributions={currentDistributions}
        globalColumnDistributions={globalColumnDistributions}
        backendFilters={backendFilters}
        distributionLimit={distributionLimit}
        compactMode={compact}
        onDistributionClick={handleDistributionClick}
        onToggleExpansion={toggleDistributionExpansion}
      />
    );
  };

  const renderTableCell = (row, column) => (
    <TableCell
      key={column}
      sx={{
        width:
          column === "file_name"
            ? COLUMN_WIDTHS.file_name
            : COLUMN_WIDTHS.default,
        minWidth:
          column === "file_name"
            ? COLUMN_WIDTHS.file_name
            : COLUMN_WIDTHS.default,
        maxWidth:
          column === "file_name"
            ? COLUMN_WIDTHS.file_name
            : COLUMN_WIDTHS.default,
        padding: "8px 16px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        position: "relative",
        ...(column === "file_name" && {
          position: "sticky",
          left: 0,
          backgroundColor: "white",
          zIndex: 1,
        }),
      }}
    >
      <div
        className={`flex items-start gap-2`}
        style={{
          maxHeight: "120px",
          overflowY: "auto",
          msOverflowStyle: "none", // Hide scrollbar in IE/Edge
          scrollbarWidth: "none", // Hide scrollbar in Firefox
          "&::-webkit-scrollbar": {
            // Hide scrollbar in Chrome/Safari
            display: "none",
          },
        }}
      >
        {column === "file_name" && row.chunks?.length > 0 && (
          <span
            style={{
              fontSize: "0.75rem",
              color: "#6B7280",
              transition: "transform 0.2s",
              transform:
                expandedRow === row.file_name ? "rotate(90deg)" : "none",
              flexShrink: 0,
              marginTop: "2px",
            }}
          >
            ▶
          </span>
        )}
        {Array.isArray(row[column]) ? (
          <div className="flex flex-wrap gap-1">
            {row[column].map((item, index) => (
              <span
                key={index}
                className="bg-gray-100 px-2 py-1 rounded-sm text-sm"
              >
                {processTagValue(item)}
              </span>
            ))}
          </div>
        ) : processTagValue(row[column]) === "Untagged" ? (
          <div className="bg-red-50 text-red-600 px-2 py-1 rounded-md text-xs font-medium">
            Untagged
          </div>
        ) : (
          <span className="break-words whitespace-pre-wrap">
            {processTagValue(row[column])}
          </span>
        )}
      </div>
    </TableCell>
  );

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {displayColumns.map((column) => (
          <TableCell
            key={column}
            sx={{
              width:
                column === "file_name"
                  ? COLUMN_WIDTHS.file_name
                  : COLUMN_WIDTHS.default,
              minWidth:
                column === "file_name"
                  ? COLUMN_WIDTHS.file_name
                  : COLUMN_WIDTHS.default,
              maxWidth:
                column === "file_name"
                  ? COLUMN_WIDTHS.file_name
                  : COLUMN_WIDTHS.default,
              padding: "8px 16px",
              backgroundColor: "#f9fafb",
              fontWeight: 500,
              fontSize: "0.8125rem",
              height: column !== "file_name" ? "120px" : "48px",
              lineHeight: 1.2,
              color: "#374151",
              borderBottom: "1px solid #e5e7eb",
              position: "sticky",
              top: 0,
              ...(column === "file_name" && {
                left: 0,
                zIndex: 3,
              }),
              ...(column !== "file_name" && {
                zIndex: 2,
              }),
              overflow: "visible",
            }}
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between gap-2 mb-2">
                {column === "file_name" ? (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-6">
                      <span className="text-sm font-medium">File Name</span>
                      <div className="flex items-center gap-4 bg-gray-50 px-4 py-2 rounded-lg border border-gray-200">
                        <div className="flex items-center gap-3">
                          <span className="text-lg font-semibold text-gray-900">
                            {loading ? "..." : totalRows}
                          </span>
                          <span className="text-sm text-gray-600">
                            total files
                          </span>
                        </div>
                        <div className="h-8 w-[1px] bg-gray-200"></div>
                        <Tooltip title="Refresh data and clear filters">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRefresh();
                            }}
                            disabled={loading}
                            sx={{
                              padding: 1,
                              backgroundColor: "white",
                              border: "1px solid #E5E7EB",
                              "&:hover": { backgroundColor: "#F3F4F6" },
                            }}
                          >
                            <FaSync
                              className={loading ? "animate-spin" : ""}
                              size={14}
                              color="#374151"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="truncate">
                    {column
                      .replace(/_/g, " ")
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")}
                  </div>
                )}
                <div className="flex-shrink-0">
                  <Tooltip title="Filter">
                    <IconButton
                      size="small"
                      onClick={(e) => handleFilterClick(e, column)}
                      sx={{
                        padding: 1,
                        backgroundColor: backendFilters[column]
                          ? "#ecfdf5"
                          : "transparent",
                        "&:hover": { backgroundColor: "#f9fafb" },
                        zIndex: 10,
                      }}
                    >
                      <FaFilter
                        size={12}
                        color={backendFilters[column] ? "#4FA892" : "#9ca3af"}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {column !== "file_name" && (
                <div className="h-[80px] relative">
                  {renderDistributionChart(column)}
                </div>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {tableData.map((row) => (
        <React.Fragment key={row.file_name}>
          {/* Main row */}
          <TableRow
            hover
            onClick={() => toggleRowExpansion(row.file_name)}
            sx={{
              cursor: "pointer",
              backgroundColor:
                expandedRow === row.file_name ? "#F9FAFB" : "inherit",
              "&:hover": { backgroundColor: "#F3F4F6" },
              "& td": {
                borderBottom:
                  expandedRow === row.file_name ? "none" : "1px solid #E5E7EB",
              },
            }}
          >
            {displayColumns.map((column) => renderTableCell(row, column))}
          </TableRow>

          {/* Expanded chunk rows */}
          {expandedRow === row.file_name && (
            <TableRow>
              <TableCell colSpan={displayColumns.length} sx={{ padding: 0 }}>
                <Fade in={true} timeout={150}>
                  <div>
                    {loadingChunks[row.file_name] ? (
                      <div className="py-2 bg-gray-50">
                        <div className="animate-pulse space-y-2">
                          {[...Array(3)].map((_, idx) => (
                            <div
                              key={idx}
                              className="flex items-center space-x-2 px-4"
                            >
                              {displayColumns.map((column) => (
                                <div
                                  key={column}
                                  style={{
                                    width:
                                      column === "file_name"
                                        ? COLUMN_WIDTHS.file_name
                                        : COLUMN_WIDTHS.default,
                                    height: "32px",
                                  }}
                                  className="bg-gray-200 rounded"
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : chunkData[row.file_name] ? (
                      <div className="bg-gray-50">
                        <table
                          className="w-full"
                          style={{ tableLayout: "fixed" }}
                        >
                          <colgroup>
                            {displayColumns.map((column) => (
                              <col
                                key={column}
                                style={{
                                  width:
                                    column === "file_name"
                                      ? COLUMN_WIDTHS.file_name
                                      : COLUMN_WIDTHS.default,
                                  minWidth:
                                    column === "file_name"
                                      ? COLUMN_WIDTHS.file_name
                                      : COLUMN_WIDTHS.default,
                                  maxWidth:
                                    column === "file_name"
                                      ? COLUMN_WIDTHS.file_name
                                      : COLUMN_WIDTHS.default,
                                }}
                              />
                            ))}
                          </colgroup>
                          <tbody>
                            {Object.entries(chunkData[row.file_name]).map(
                              ([chunkId, chunk]) => (
                                <React.Fragment key={chunkId}>
                                  <tr className="hover:bg-gray-50 border-b border-gray-100 last:border-0">
                                    {displayColumns.map((column) => {
                                      let value = "";
                                      if (column === "file_name") {
                                        value = (
                                          <div className="flex items-center justify-between w-full">
                                            <div className="flex items-center">
                                              <span className="text-xs text-gray-400 mr-2">
                                                └─
                                              </span>
                                              <span>Chunk {chunkId}</span>
                                            </div>
                                            {chunk.text && (
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  toggleChunkText(chunkId);
                                                }}
                                                className="text-xs text-gray-400 hover:text-primary px-2"
                                              >
                                                {activeFullText === chunkId
                                                  ? "Hide Text"
                                                  : "Show Text"}
                                              </button>
                                            )}
                                          </div>
                                        );
                                      } else {
                                        const tag = chunk.tags?.[column];
                                        if (tag) {
                                          if (
                                            typeof tag === "object" &&
                                            "value" in tag
                                          ) {
                                            value = (
                                              <div className="relative">
                                                <div className="break-words whitespace-pre-wrap">
                                                  {Array.isArray(tag.value)
                                                    ? tag.value.join(", ")
                                                    : tag.value}
                                                </div>
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    fetchChunkEvidence(
                                                      row.file_name,
                                                      chunkId,
                                                      column,
                                                      tag.value,
                                                    );
                                                  }}
                                                  className="text-xs text-gray-400 hover:text-primary mt-1 text-left"
                                                >
                                                  {activeEvidence?.chunkId ===
                                                  chunkId
                                                    ? "Hide Evidence"
                                                    : "Show Evidence"}
                                                </button>
                                                {activeEvidence?.chunkId ===
                                                  chunkId &&
                                                  activeEvidence?.tagName ===
                                                    column &&
                                                  renderEvidenceRow(
                                                    chunk,
                                                    chunkId,
                                                    row,
                                                  )}
                                              </div>
                                            );
                                          } else {
                                            value = (
                                              <div className="relative">
                                                <div className="break-words whitespace-pre-wrap">
                                                  {Array.isArray(tag)
                                                    ? tag.join(", ")
                                                    : tag}
                                                </div>
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    fetchChunkEvidence(
                                                      row.file_name,
                                                      chunkId,
                                                      column,
                                                      tag,
                                                    );
                                                  }}
                                                  className="text-xs text-gray-400 hover:text-primary mt-1 text-left"
                                                >
                                                  {activeEvidence?.chunkId ===
                                                  chunkId
                                                    ? "Hide Evidence"
                                                    : "Show Evidence"}
                                                </button>
                                                {activeEvidence?.chunkId ===
                                                  chunkId &&
                                                  activeEvidence?.tagName ===
                                                    column &&
                                                  renderEvidenceRow(
                                                    chunk,
                                                    chunkId,
                                                    row,
                                                  )}
                                              </div>
                                            );
                                          }
                                        }
                                      }

                                      return (
                                        <td
                                          key={column}
                                          className="px-4 py-2 text-xs text-gray-600 relative"
                                          style={{
                                            width:
                                              column === "file_name"
                                                ? COLUMN_WIDTHS.file_name
                                                : COLUMN_WIDTHS.default,
                                            minWidth:
                                              column === "file_name"
                                                ? COLUMN_WIDTHS.file_name
                                                : COLUMN_WIDTHS.default,
                                            maxWidth:
                                              column === "file_name"
                                                ? COLUMN_WIDTHS.file_name
                                                : COLUMN_WIDTHS.default,
                                          }}
                                        >
                                          {typeof value === "string" ? (
                                            <div
                                              className="break-words whitespace-pre-wrap"
                                              title={value || "-"}
                                            >
                                              {value || "-"}
                                            </div>
                                          ) : (
                                            value
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>

                                  {/* Text row */}
                                  {activeFullText === chunkId && chunk.text && (
                                    <tr key={`${chunkId}-text`}>
                                      <td
                                        colSpan={displayColumns.length}
                                        className="px-4 py-2 bg-white border-t border-gray-100"
                                      >
                                        <div className="text-xs text-gray-600 whitespace-pre-wrap">
                                          {chunk.text}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="py-4 text-center text-gray-500 text-sm bg-gray-50">
                        No chunk metadata available
                      </div>
                    )}
                  </div>
                </Fade>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </TableBody>
  );

  const renderFilterMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleFilterClose}
      PaperProps={{
        elevation: 2,
        sx: {
          maxHeight: 300,
          width: 240,
          borderRadius: "8px",
          mt: 1,
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "#f9fafb",
          },
        },
      }}
    >
      <div className="px-3 py-2 border-b border-gray-100">
        <div className="flex items-center justify-between mb-2">
          <Typography variant="subtitle2" sx={{ color: "#374151" }}>
            Filter by {activeColumn}
          </Typography>
          <Tooltip title="Clear filters">
            <IconButton
              size="small"
              onClick={handleClearFilters}
              disabled={!backendFilters[activeColumn]}
            >
              <FaSync sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className="text-xs text-gray-500">
          Select multiple values to filter with OR condition
        </div>
      </div>
      {loadingValues ? (
        <div className="flex justify-center p-3">
          <CircularProgress size={20} sx={{ color: "#6366F1" }} />
        </div>
      ) : activeColumn && availableColumns.length > 0 ? (
        availableColumns.map((value) => (
          <MenuItem
            key={value}
            onClick={() => handleFilterSelect(value)}
            sx={{
              minHeight: "40px",
              fontSize: "0.875rem",
              padding: "4px 8px",
              "&:hover": {
                backgroundColor: "#F3F4F6",
              },
            }}
          >
            <Checkbox
              checked={Boolean(backendFilters[activeColumn]?.includes(value))}
              size="small"
              sx={{
                color: "#d1d5db",
                "&.Mui-checked": {
                  color: "#4FA892",
                },
              }}
            />
            <ListItemText
              primary={value}
              primaryTypographyProps={{
                fontSize: "0.875rem",
                color: "#1F2937",
              }}
            />
          </MenuItem>
        ))
      ) : (
        <MenuItem
          disabled
          sx={{
            minHeight: "40px",
            fontSize: "0.875rem",
            color: "#6B7280",
          }}
        >
          <ListItemText primary="No values found" />
        </MenuItem>
      )}
    </Menu>
  );

  const toggleChunkText = (chunkId) => {
    setActiveFullText((prev) => (prev === chunkId ? null : chunkId));
  };

  const CreateUseCaseButton = () => {
    if (Object.keys(backendFilters).length === 0) return null;

    return (
      <div className="flex flex-col items-end gap-2">
        <button
          onClick={() => setShowCreateDataSlice(true)}
          className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
        >
          <span>Create Data Slice</span>
        </button>
        <p className="text-sm text-gray-500 max-w-md text-right">
          Create a data slice to manage and
        </p>
        <p className="text-sm text-gray-500 max-w-md text-right">
          analyze this specific subset of files.
        </p>
      </div>
    );
  };

  const fetchChunkEvidence = async (fileName, chunkId, tagName, value) => {
    try {
      // If evidence is already loaded, just toggle visibility
      if (chunkData[fileName]?.[chunkId]?.evidence?.[tagName]?.[value]) {
        toggleEvidenceChunk(chunkId, tagName, value);
        return;
      }

      // Show evidence box with loading state
      toggleEvidenceChunk(chunkId, tagName, value);

      const activeVdbConfig =
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ] || {};
      const tagValue = Array.isArray(value) ? value[0] : value;

      const [evidenceResponse, textResponse] = await Promise.all([
        metadataService.getTagEvidence(
          activeVdbConfig,
          fileName,
          tagName,
          tagValue,
          deasyUserConfig.deasyApiKey,
        ),
        metadataService.listMetadata(
          prepareVectorDBConfiguration(activeVdbConfig),
          deasyUserConfig.deasyApiKey,
          "node",
          null,
          [fileName],
          [chunkId],
          null,
        ),
      ]);

      if (evidenceResponse?.data?.evidence || textResponse?.metadata) {
        setChunkData((prev) => {
          const newChunkData = { ...prev };
          if (!newChunkData[fileName]) newChunkData[fileName] = {};
          if (!newChunkData[fileName][chunkId])
            newChunkData[fileName][chunkId] = {};

          const chunkObj = newChunkData[fileName][chunkId];

          // Add evidence
          if (!chunkObj.evidence) chunkObj.evidence = {};
          if (!chunkObj.evidence[tagName]) chunkObj.evidence[tagName] = {};
          chunkObj.evidence[tagName][value] = evidenceResponse.data.evidence;

          // Store full text
          const fullText = textResponse?.metadata?.[chunkId]?.text;
          chunkObj.fullText = fullText;

          return newChunkData;
        });
      }
    } catch (error) {
      console.error("Error fetching evidence:", error);
      setError(`Failed to load evidence for chunk ${chunkId}`);
      // Close evidence box on error
      toggleEvidenceChunk(chunkId, tagName, value);
    }
  };

  const toggleEvidenceChunk = (chunkId, tagName, value) => {
    setActiveEvidence((prev) =>
      prev?.chunkId === chunkId &&
      prev?.tagName === tagName &&
      prev?.value === value
        ? null
        : { chunkId, tagName, value },
    );
  };

  // Update the useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedInside = false;
      evidenceRefs.forEach((ref) => {
        if (ref && ref.contains(event.target)) {
          clickedInside = true;
        }
      });

      if (!clickedInside) {
        setActiveEvidence(null);
        setActiveFullText(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [evidenceRefs]);

  // Update the renderEvidenceRow function
  const renderEvidenceRow = (chunk, chunkId, row) => {
    if (!chunk.evidence || !activeEvidence?.chunkId === chunkId) return null;

    const evidenceKey = `${chunkId}-${activeEvidence.tagName}`;

    return (
      <div
        ref={(el) => {
          if (el) {
            evidenceRefs.set(evidenceKey, el);
          } else {
            evidenceRefs.delete(evidenceKey);
          }
        }}
        className="absolute left-0 bg-white shadow-lg border border-gray-200 rounded-lg mt-2 z-10"
        style={{
          minWidth: COLUMN_WIDTHS.default,
          width: activeFullText === chunkId ? "600px" : COLUMN_WIDTHS.default,
          transition: "all 0.3s ease-in-out",
          overflow: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div className="p-3 text-xs space-y-3">
          <div className="flex items-center justify-between">
            <div className="font-medium text-gray-700">
              Evidence for {activeEvidence.tagName}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleChunkText(chunkId);
              }}
              className={`px-3 py-1 text-xs rounded-full border transition-colors ${
                activeFullText === chunkId
                  ? "bg-primary text-white border-primary hover:bg-primary/90"
                  : "bg-white text-gray-600 border-gray-200 hover:bg-gray-50"
              }`}
            >
              {activeFullText === chunkId ? "Hide" : "View"} Full Text
            </button>
          </div>

          <div className="space-y-3">
            <div className="bg-yellow-50 p-3 rounded-lg border border-yellow-100">
              <div className="whitespace-pre-wrap text-gray-700 break-words">
                {
                  chunk.evidence[activeEvidence.tagName][activeEvidence.value][
                    chunkId
                  ]
                }
              </div>
            </div>

            {activeFullText === chunkId && (
              <div className="bg-white p-3 rounded-lg border border-gray-200">
                <div
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      chunk.fullText || "...",
                      getHighlightTerms(
                        chunkId,
                        activeEvidence.value,
                        chunk.evidence[activeEvidence.tagName][
                          activeEvidence.value
                        ],
                      ),
                    ),
                  }}
                  className="whitespace-pre-wrap text-gray-700 break-words"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col w-full relative ${compact ? "min-h-fit" : "min-h-[85vh]"}`}
    >
      {/* Loading overlay */}
      {isConfigRefreshing && (
        <div className="absolute inset-0 bg-white/80 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <CircularProgress size={24} className="text-primary" />
            <span className="text-sm text-gray-600">Updating data...</span>
          </div>
        </div>
      )}

      {/* Controls sections */}
      <div className="flex flex-row gap-4">
        {/* Controls Section */}
        <div className="mb-4 flex-1">
          <ExpandableSection
            title="Add Metadata Filters"
            icon="⚙️"
            isExpanded={debouncedExpandedSections.controls}
            onToggle={() => handleSectionToggle("controls")}
            description="Filter your data using metadata as columns in the table."
          >
            <div>
              <MetadataColumnSelector {...columnSelectorProps} />
            </div>
          </ExpandableSection>
        </div>

        {/* Active Filters Section */}
        <div className="mb-4 flex-1">
          <ExpandableSection
            title="Active Filters"
            icon="🔍"
            isExpanded={debouncedExpandedSections.filters}
            onToggle={() => handleSectionToggle("filters")}
            description="View and manage your currently applied filters. Click on a filter to remove it."
          >
            <div className="flex justify-between items-start p-4">
              {Object.keys(backendFilters).length > 0 ? (
                <>
                  <ActiveFilters
                    filters={backendFilters}
                    onRemove={handleRemoveFilter}
                  />
                  <CreateUseCaseButton />
                </>
              ) : (
                <div className="text-gray-500 text-sm py-2 px-4">
                  No filters applied. Click the filter icon in any column header
                  to add filters.
                </div>
              )}
            </div>
          </ExpandableSection>
        </div>
      </div>

      {/* Existing MetadataTable content */}
      <div className="flex flex-col w-full">
        {/* Error message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-sm">
            <p className="font-medium">Error: {error}</p>
          </div>
        )}

        {/* Table section */}
        <Paper
          className="metadata-table-paper"
          sx={{
            boxShadow: "0 1px 1px rgba(0,0,0,0.01)",
            border: "2px solid #d1d5db",
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            minHeight: compact ? "auto" : "fit-content",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <TableContainer
            sx={{
              flex: 1,
              overflow: "auto",
              position: "relative",
              maxHeight: compact ? "300px" : "calc(100vh - 100px)",
              maxWidth: "88vw",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Table stickyHeader>
              {renderTableHeader()}
              {renderTableBody()}
            </Table>
          </TableContainer>

          {/* Sticky pagination */}
          <TablePagination
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={totalRows}
            labelDisplayedRows={({ from, to }) =>
              tableData.length ? `${from}-${to} of ${totalRows}` : "0-0"
            }
            nextIconButtonProps={{ disabled: !hasMore || loading }}
            showFirstButton
            showLastButton
            sx={{
              borderTop: "1px solid #E5E7EB",
              backgroundColor: "#FAFAFA",
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              "& .MuiToolbar-root": {
                minHeight: "48px",
                padding: "0 16px",
              },
              "& .MuiTablePagination-select": {
                padding: "8px",
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
                {
                  fontSize: "0.875rem",
                  color: "#374151",
                },
              "& .MuiTablePagination-actions": {
                marginLeft: "20px",
                "& .MuiIconButton-root": {
                  padding: "8px",
                  color: "#374151",
                  "&.Mui-disabled": {
                    color: "#D1D5DB",
                  },
                },
              },
            }}
          />
        </Paper>

        {renderFilterMenu()}

        {showCreateDataSlice && (
          <CreateDataSlice
            isOpen={showCreateDataSlice}
            onClose={() => setShowCreateDataSlice(false)}
            conditions={Object.entries(backendFilters).map(
              ([tag_id, values]) => ({
                tag_id,
                values,
              }),
            )}
            fileCount={totalRows}
            onSuccess={(useCase) => {
              setSelectedUseCase(useCase);
              setCurrentScreen(WorkflowType.DATA_SLICE_MANAGEMENT);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MetadataTable;
