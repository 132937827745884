import React, { useState, useContext } from "react";
import { BaseContext } from "../../contexts/BaseContext";
import { metadataService } from "../../services/api";
import { toast } from "react-hot-toast";
import { prepareVectorDBConfiguration } from "../../services/utils";
import { auth } from "../../config/firebase";

export default function CreateDataSlice({
  isOpen,
  onClose,
  conditions,
  fileCount,
  onSuccess,
  isGraphMode,
  discoveryGraphData,
  selectedDiscoveryGraph,
  isAllFiles,
}) {
  const { deasyUserConfig, refreshUseCasesHook } = useContext(BaseContext);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateUseCase = async () => {
    if (!formData.name) {
      toast.error("Please enter a use case name");
      return;
    }

    setIsCreating(true);
    try {
      const vectorDBConfiguration =
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ];

      const useCaseData = {
        id: formData.name.toLowerCase().replace(/\s+/g, "_"),
        name: formData.name,
        description: formData.description,
        status: "active",
        dataPoints: fileCount,
        lastUpdated: new Date().toISOString(),
        latestGraph: isGraphMode ? discoveryGraphData : {},
        graphId: isGraphMode
          ? selectedDiscoveryGraph?.graph_id
          : "table_filters",
        selectedParentNodes: conditions.map((condition) => ({
          data: {
            nodePath: [condition.tag_id, condition.values[0]],
          },
        })),
        vectorDBConfiguration: prepareVectorDBConfiguration({
          ...vectorDBConfiguration,
          user: auth.currentUser.email,
        }),
        isAllFiles,
      };

      await metadataService.createUseCase(
        useCaseData,
        deasyUserConfig.deasyApiKey,
      );
      toast.success("Use case created successfully");
      refreshUseCasesHook();
      onSuccess?.(useCaseData);
      onClose();
    } catch (error) {
      console.error("Failed to create data slice:", error);
      toast.error("Failed to create data slice");
    } finally {
      setIsCreating(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-4">
        <h2 className="text-xl font-semibold mb-4">Create Data Slice</h2>

        <div className="text-sm text-gray-600 mb-6">
          This will create a new data slice that automatically filters files
          based on your selected conditions. You can modify these conditions
          later through the data slice settings.
        </div>

        <div className="space-y-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Data Slice Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="Enter data slice name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
              rows={3}
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Describe the data slice"
            />
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Selected Conditions
            </h3>
            <div className="flex flex-col gap-1 mb-2">
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span className="bg-green-100 text-green-800 px-2 py-0.5 rounded text-xs font-medium">
                  OR
                </span>
                <span>
                  Multiple values for the same tag will match any of those
                  values
                </span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded text-xs font-medium">
                  AND
                </span>
                <span>
                  Different tags must all match their respective values
                </span>
              </div>
            </div>
            <div className="border border-gray-200 rounded-md divide-y divide-gray-100">
              {Object.entries(
                conditions.reduce((acc, condition) => {
                  if (!acc[condition.tag_id]) {
                    acc[condition.tag_id] = new Set();
                  }
                  condition.values.forEach((value) =>
                    acc[condition.tag_id].add(value),
                  );
                  return acc;
                }, {}),
              ).map(([tag_id, valuesSet], index, array) => (
                <div key={index}>
                  <div className="p-4">
                    <div className="font-medium text-gray-700 mb-2">
                      {tag_id}
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      {Array.from(valuesSet).map((value, i, arr) => (
                        <React.Fragment key={i}>
                          <span className="bg-gray-100 px-2 py-1 rounded text-sm">
                            {value}
                          </span>
                          {i < arr.length - 1 && (
                            <span className="text-xs text-green-800 font-medium">
                              OR
                            </span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {index < array.length - 1 && (
                    <div className="flex justify-center py-2 text-xs text-blue-800 font-medium bg-gray-50">
                      AND
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-md">
            <div className="text-sm text-gray-700">
              <span className="font-medium">Matching Files:</span>{" "}
              <span className="text-primary font-medium">{fileCount}</span>
            </div>
            <div className="text-xs text-gray-500 mt-1">
              Only these files will be included in the use case
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateUseCase}
            disabled={isCreating}
            className={`px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 
              ${isCreating ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {isCreating ? "Creating..." : "Create Data Slice"}
          </button>
        </div>
      </div>
    </div>
  );
}
