export const LoadingScreen = ({
  message,
  shrimpMessage = "Shrimpy is working his tail off!",
}) => {
  return (
    <div className="min-h-[400px] w-full flex flex-col items-center justify-center bg-white/50 backdrop-blur-sm px-4">
      <div className="relative">
        {/* Animated rings - reduced opacity and slower animations */}
        <div className="absolute inset-[-16px] rounded-full bg-primary/5">
          <div className="w-full h-full rounded-full animate-pulse-slow" />
        </div>
        <div className="absolute inset-[-8px] rounded-full bg-primary/3">
          <div className="w-full h-full rounded-full animate-pulse-slower" />
        </div>

        {/* Animated logo - smoother animation */}
        <img
          src="/deasy_shrimp.png"
          alt="Deasy Logo"
          className="w-28 h-28 relative"
          style={{
            animation: "spinAndPause 3s infinite",
          }}
        />
      </div>

      {/* Messages with gentler fade-in animation */}
      <div className="mt-12 space-y-3 text-center animate-fadeIn">
        <p className="text-gray-700 font-medium text-xl">
          {message}
          {"  "}
          <span className="inline-block animate-fade-1 text-3xl">.</span>
          <span className="inline-block animate-fade-2 text-3xl">.</span>
          <span className="inline-block animate-fade-3 text-3xl">.</span>
        </p>
        <p className="text-gray-400 text-base">{shrimpMessage}</p>
      </div>

      {/* Custom animations with smoother transitions */}
      <style jsx global>{`
        @keyframes spinAndPause {
          0% {
            transform: rotate(0deg);
          }
          30% {
            transform: rotate(360deg);
          }
          45%,
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes pulse-slow {
          0%,
          100% {
            opacity: 0.1;
          }
          50% {
            opacity: 0.3;
          }
        }

        @keyframes pulse-slower {
          0%,
          100% {
            opacity: 0.05;
          }
          50% {
            opacity: 0.2;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(5px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes dotFade {
          0%,
          100% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
        }

        .animate-fade-1 {
          animation: dotFade 1.5s infinite;
        }

        .animate-fade-2 {
          animation: dotFade 1.5s infinite;
          animation-delay: 0.3s;
        }

        .animate-fade-3 {
          animation: dotFade 1.5s infinite;
          animation-delay: 0.6s;
        }

        .animate-pulse-slow {
          animation: pulse-slow 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        .animate-pulse-slower {
          animation: pulse-slower 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        .animate-fadeIn {
          animation: fadeIn 0.8s ease-out forwards;
        }
      `}</style>
    </div>
  );
};
