import { useState, useEffect, useContext } from "react";
import {
  ProgressBar,
  formatTimeRemaining,
  formatElapsedTime,
  formatDateTime,
  getCurrentTimeInUTC,
} from "./utils";
import { progressTrackingService } from "../../../services/api";
import { BaseContext } from "../../../contexts/BaseContext";
import { FaTrash } from "react-icons/fa";
import { LoadingScreen } from "../../utils/LoadingScreen";

// Mock data
export const ProgressTracking = () => {
  // Get deasyApiKey
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  // State
  const [progressTrackers, setProgressTrackers] = useState([]);
  const [selectedTrackers, setSelectedTrackers] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [filters, setFilters] = useState({
    status: "",
    taskType: "",
    timeRange: "all",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Add these state declarations at the component level (with other state declarations)
  const [abortingTrackers, setAbortingTrackers] = useState(new Set());
  const [deletingTrackers, setDeletingTrackers] = useState(new Set());

  // Handlers
  const toggleRowExpansion = (trackerId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(trackerId)) {
        newSet.delete(trackerId);
      } else {
        newSet.add(trackerId);
      }
      return newSet;
    });
  };

  const handleAbort = async (trackerId, e) => {
    e.stopPropagation();

    // Add confirmation dialog
    const confirmed = window.confirm(
      "Are you sure you want to abort this task? This action cannot be undone.",
    );
    if (!confirmed) return;

    try {
      setAbortingTrackers((prev) => new Set([...prev, trackerId]));
      await progressTrackingService.abortProgressTracker(
        trackerId,
        deasyApiKey,
      );
      // Refresh the list
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to abort task:", error);
    } finally {
      setAbortingTrackers((prev) => {
        const newSet = new Set(prev);
        newSet.delete(trackerId);
        return newSet;
      });
    }
  };

  const handleRowClick = (trackerId) => {
    toggleRowExpansion(trackerId);
    setSelectedTrackers((prev) =>
      prev.includes(trackerId)
        ? prev.filter((id) => id !== trackerId)
        : [...prev, trackerId],
    );
  };

  const handleDelete = async (trackerIds, e) => {
    if (e) e.stopPropagation();
    if (isDeleting) return;

    const itemCount = trackerIds.length;
    const confirmMessage =
      itemCount === 1
        ? "Are you sure you want to delete this tracker?"
        : `Are you sure you want to delete these ${itemCount} trackers?`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    setIsDeleting(true);
    try {
      await progressTrackingService.deleteProgressTrackers(
        trackerIds,
        deasyApiKey,
      );
      setSelectedTrackers([]);
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to delete trackers:", error);
      alert("Failed to delete trackers. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTrackers(filteredAndSortedTrackers.map((t) => t.tracker_id));
    } else {
      setSelectedTrackers([]);
    }
  };

  const handleCheckboxClick = (e, trackerId) => {
    e.stopPropagation();
    setSelectedTrackers((prev) => {
      if (prev.includes(trackerId)) {
        return prev.filter((id) => id !== trackerId);
      } else {
        return [...prev, trackerId];
      }
    });
  };

  // Filter and sort helpers
  const filterTrackers = (trackers) => {
    return Object.values(trackers).filter((tracker) => {
      // Check if tracker is stale (in_progress for > 24h)
      const isStaleInProgress =
        tracker.status === "in_progress" &&
        Date.now() - new Date(tracker.start_time).getTime() >
          24 * 60 * 60 * 1000;

      // Use failed status if tracker is stale
      const effectiveStatus = isStaleInProgress ? "failed" : tracker.status;

      const matchesStatus =
        !filters.status || effectiveStatus === filters.status;
      const matchesTaskType =
        !filters.taskType || tracker.task_type === filters.taskType;

      let matchesTimeRange = true;
      if (filters.timeRange !== "all") {
        const startTime = new Date(tracker.start_time).getTime();
        const now = Date.now();
        const dayInMs = 24 * 60 * 60 * 1000;

        switch (filters.timeRange) {
          case "24h":
            matchesTimeRange = now - startTime <= dayInMs;
            break;
          case "7d":
            matchesTimeRange = now - startTime <= 7 * dayInMs;
            break;
          case "30d":
            matchesTimeRange = now - startTime <= 30 * dayInMs;
            break;
          default:
            matchesTimeRange = true;
        }
      }

      return matchesStatus && matchesTaskType && matchesTimeRange;
    });
  };

  const sortTrackers = (trackers) => {
    return trackers.sort((a, b) => {
      // First sort by status (in_progress first)
      if (a.status === "in_progress" && b.status !== "in_progress") return -1;
      if (b.status === "in_progress" && a.status !== "in_progress") return 1;

      // Then sort by start time (newest first)
      return new Date(b.start_time) - new Date(a.start_time);
    });
  };

  const paginateTrackers = (trackers) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return trackers.slice(startIndex, endIndex);
  };

  // Effects
  useEffect(() => {
    const fetchProgressTrackers = async () => {
      setIsLoading(true);
      try {
        const progressTrackers =
          await progressTrackingService.getProgressTrackers(deasyApiKey);
        setProgressTrackers(progressTrackers.data.progress_trackers);
      } catch (error) {
        console.error("Failed to fetch trackers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgressTrackers();
  }, [deasyApiKey]);

  // Render helpers
  const renderTimelineInfo = (tracker) => {
    const startTime = new Date(tracker.start_time);

    const elapsedMs = getCurrentTimeInUTC() - startTime;
    const elapsedStr = formatElapsedTime(elapsedMs);
    const progress = isTrackerActive(tracker)
      ? tracker.completed_progress_increments /
        tracker.total_progress_increments
      : null;

    return (
      <div className="text-xs text-gray-500 text-center">
        {/* Only show elapsed time for active trackers */}
        {isTrackerActive(tracker) && <div>Time elapsed: {elapsedStr}</div>}

        {/* Show remaining time or calculation message */}
        {progress !== null && (
          <div>
            {progress > 0
              ? `~${formatTimeRemaining(elapsedMs, progress)} remaining`
              : "Calculating remaining time..."}
          </div>
        )}

        {/* Show tag creation count if available */}
        {tracker.tags_created !== undefined && (
          <div>
            {tracker.tags_created.toLocaleString()} new tag value pair
            {tracker.tags_created !== 1 && "s"}
          </div>
        )}
      </div>
    );
  };

  const renderFiles = (files) => {
    if (Array.isArray(files)) {
      return files.map((file, index) => (
        <div className="flex flex-wrap gap-2">
          <span key={index} className="px-2 py-1 bg-gray-100 rounded text-sm">
            {file}
          </span>
        </div>
      ));
    } else {
      return (
        <div className="flex flex-wrap gap-2 justify-center w-full">
          <span className="px-2 py-1 bg-gray-100 rounded text-sm">
            All Files
          </span>
        </div>
      );
    }
  };

  // Get unique task types and statuses for filters
  const taskTypes = [
    ...new Set(Object.values(progressTrackers).map((t) => t.task_type)),
  ];
  const statuses = [
    ...new Set(Object.values(progressTrackers).map((t) => t.status)),
  ];

  const filteredAndSortedTrackers = sortTrackers(
    filterTrackers(progressTrackers),
  );
  const paginatedTrackers = paginateTrackers(filteredAndSortedTrackers);
  const totalPages = Math.ceil(filteredAndSortedTrackers.length / itemsPerPage);

  const isTrackerStale = (tracker) => {
    return (
      tracker.status === "in_progress" &&
      Date.now() - new Date(tracker.start_time).getTime() > 24 * 60 * 60 * 1000
    );
  };

  const isTrackerActive = (tracker) => {
    return (
      tracker.status === "in_progress" &&
      Date.now() - new Date(tracker.start_time).getTime() <= 24 * 60 * 60 * 1000
    );
  };

  const renderProgressCell = (tracker) => {
    // If stale, show as failed instead
    if (isTrackerStale(tracker)) {
      return (
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-red-600 mr-2">✗</span>
            <span className="text-gray-500">Failed (Timeout)</span>
          </div>
        </td>
      );
    }

    const isComplete = tracker.status === "completed";
    const progress =
      tracker.completed_progress_increments /
      Math.max(tracker.total_progress_increments, 1);

    if (isComplete && progress === 1) {
      return (
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-green-600 mr-2">✓</span>
            <span className="text-gray-500">Complete</span>
          </div>
        </td>
      );
    }

    return (
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-full">
        <ProgressBar
          value={progress}
          active={tracker.status === "in_progress"}
        />
      </td>
    );
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="flex items-center">
          <select
            className="mr-4 rounded-md border border-gray-300 p-1"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
          </select>
          <span className="text-sm text-gray-700">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(
              currentPage * itemsPerPage,
              filteredAndSortedTrackers.length,
            )}{" "}
            of {filteredAndSortedTrackers.length} results
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            First
          </button>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-1 text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Next
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded-md border border-gray-300 bg-white text-sm disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>
    );
  };

  const handleRefresh = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const progressTrackers =
        await progressTrackingService.getProgressTrackers(deasyApiKey);
      setProgressTrackers(progressTrackers.data.progress_trackers);
    } catch (error) {
      console.error("Failed to refresh trackers:", error);
      alert("Failed to refresh trackers. Please try again.");
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <div className="px-6 py-5">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Progress Tracking</h1>
        <button
          onClick={handleRefresh}
          disabled={isRefreshing}
          className="px-3 py-1.5 rounded-md border border-gray-300 bg-white text-sm hover:bg-gray-50 flex items-center gap-2 disabled:opacity-50"
        >
          <svg
            className={`w-4 h-4 ${isRefreshing ? "animate-spin" : ""}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          {isRefreshing ? "Refreshing..." : "Refresh"}
        </button>
      </div>

      {isLoading ? (
        <LoadingScreen message="Loading progress trackers" />
      ) : (
        <>
          {/* Filters */}
          <div className="mb-6 flex gap-4 bg-white p-4 rounded-lg border border-gray-200">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.status}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, status: e.target.value }))
                }
              >
                <option value="">All</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status.replace("_", " ")}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Task Type
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.taskType}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, taskType: e.target.value }))
                }
              >
                <option value="">All</option>
                {taskTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Time Range
              </label>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.timeRange}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, timeRange: e.target.value }))
                }
              >
                <option value="all">All Time</option>
                <option value="24h">Last 24 Hours</option>
                <option value="7d">Last 7 Days</option>
                <option value="30d">Last 30 Days</option>
              </select>
            </div>
          </div>

          {/* Add batch actions */}
          {selectedTrackers.length > 0 && (
            <div className="mb-4 flex items-center gap-2">
              <span className="text-sm text-gray-600">
                {selectedTrackers.length} items selected
              </span>
              <button
                onClick={async () => await handleDelete(selectedTrackers)}
                className="text-red-500 hover:text-red-700 bg-red-50 px-3 py-1.5 rounded-md text-sm"
              >
                Delete Selected
              </button>
            </div>
          )}

          <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="pl-6 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300 text-primary focus:ring-primary"
                        checked={
                          selectedTrackers.length ===
                          filteredAndSortedTrackers.length
                        }
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Task Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Progress
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Files
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Status
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedTrackers.map((tracker) => {
                    const isStale = isTrackerStale(tracker);
                    const isActive = isTrackerActive(tracker);
                    const isAborting = abortingTrackers.has(tracker.tracker_id);
                    const isDeleting = deletingTrackers.has(tracker.tracker_id);
                    return (
                      <>
                        <tr
                          key={tracker.tracker_id}
                          className={`${
                            selectedTrackers.includes(tracker.tracker_id)
                              ? "bg-primary/15"
                              : "hover:bg-gray-50"
                          } ${tracker.status === "completed" ? "h-12" : ""} cursor-pointer`}
                          onClick={() => handleRowClick(tracker.tracker_id)}
                        >
                          <td
                            className="pl-6 pr-2 text-left"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              className="rounded border-gray-300 text-primary focus:ring-primary"
                              checked={selectedTrackers.includes(
                                tracker.tracker_id,
                              )}
                              onChange={(e) =>
                                handleCheckboxClick(e, tracker.tracker_id)
                              }
                            />
                          </td>
                          <td className="pr-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                            <span className="font-semibold rounded-md px-2 py-1 bg-gray-100 shadow-md border border-gray-200">
                              {tracker.task_type}
                            </span>
                          </td>
                          {renderProgressCell(tracker)}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                            {tracker.files === "ALL" ||
                            (Array.isArray(tracker.files) &&
                              tracker.files.length === 0) ||
                            tracker.files === null
                              ? "All Files"
                              : `${Array.isArray(tracker.files) ? tracker.files.length : 0} files`}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-left">
                            <span
                              className={`px-2 py-1 rounded-full text-xs ${
                                isStale || tracker.status === "failed"
                                  ? "bg-red-100 text-red-800"
                                  : tracker.status === "completed"
                                    ? "bg-green-100 text-green-800"
                                    : "bg-blue-100 text-blue-800"
                              }`}
                            >
                              {isStale
                                ? "Failed"
                                : tracker.status.replace("_", " ")}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {isActive ? (
                              <button
                                onClick={async (e) =>
                                  await handleAbort(tracker.tracker_id, e)
                                }
                                className="text-red-500 hover:text-red-700 bg-red-50 px-2 py-1 rounded-md"
                              >
                                {isAborting ? (
                                  <div className="flex items-center gap-2">
                                    <span>Aborting</span>
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                                  </div>
                                ) : (
                                  "Abort"
                                )}
                              </button>
                            ) : (
                              <button
                                onClick={async (e) => {
                                  setDeletingTrackers(
                                    (prev) =>
                                      new Set([...prev, tracker.tracker_id]),
                                  );
                                  await handleDelete([tracker.tracker_id], e);
                                  setDeletingTrackers((prev) => {
                                    const newSet = new Set(prev);
                                    newSet.delete(tracker.tracker_id);
                                    return newSet;
                                  });
                                }}
                                className="text-red-500 hover:text-red-700 px-2 py-1 rounded-md"
                              >
                                {isDeleting ? (
                                  <div className="flex items-center gap-2">
                                    <span>Deleting</span>
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                                  </div>
                                ) : (
                                  <FaTrash />
                                )}
                              </button>
                            )}
                          </td>
                        </tr>
                        {expandedRows.has(tracker.tracker_id) && (
                          <tr className="bg-gray-50">
                            <td colSpan="6" className="px-6 py-4">
                              <div className="space-y-6">
                                {/* Top section with IDs */}
                                <div className="flex gap-6">
                                  {/* Usecase Name section */}
                                  <div className="min-w-[150px] p-4 bg-white rounded-lg shadow-md border border-gray-100">
                                    <p className="text-xs font-bold uppercase tracking-wider text-gray-800 mb-2">
                                      USECASE NAME
                                    </p>
                                    <div className="px-3 py-2 rounded-md">
                                      <span className="px-2 py-1 bg-gray-100 rounded text-sm">
                                        {tracker.usecase_id || "N/A"}
                                      </span>
                                    </div>
                                  </div>

                                  {/* Files section */}
                                  <div className="flex-1 p-4 bg-white rounded-lg shadow-md border border-gray-100">
                                    <p className="text-xs font-bold uppercase tracking-wider text-gray-800 mb-2">
                                      Files Being Processed
                                    </p>
                                    <div className="px-3 py-2 rounded-md">
                                      {renderFiles(tracker.files)}
                                    </div>
                                  </div>
                                </div>

                                {/* Timeline section */}
                                <div className="p-4 bg-white rounded-lg shadow-md">
                                  <p className="text-xs font-bold uppercase tracking-wider text-gray-800 mb-2">
                                    Timeline
                                  </p>
                                  <div className="flex justify-between items-center gap-8">
                                    <div>
                                      <p className="text-xs text-gray-500 mb-1">
                                        Started
                                      </p>
                                      <p className="text-sm">
                                        {formatDateTime(tracker.start_time)}
                                      </p>
                                    </div>
                                    <div className="flex-1">
                                      <div className="h-0.5 bg-gray-200 relative mb-4">
                                        <div
                                          className={`absolute inset-y-0 left-0 ${
                                            tracker.status === "completed"
                                              ? "bg-green-400"
                                              : tracker.status === "failed" ||
                                                  tracker.status === "aborted"
                                                ? "bg-red-400"
                                                : "bg-emerald-400"
                                          }`}
                                          style={{
                                            width: `${(tracker.completed_progress_increments / tracker.total_progress_increments) * 100}%`,
                                          }}
                                        />
                                      </div>

                                      <div className="w-full flex flex-col items-center justify-center">
                                        {renderTimelineInfo(tracker)}
                                      </div>
                                    </div>
                                    <div>
                                      <p className="text-xs text-gray-500 mb-1">
                                        {tracker.status === "completed"
                                          ? "Completed"
                                          : tracker.status === "failed"
                                            ? "Failed"
                                            : tracker.status === "aborted"
                                              ? "Aborted"
                                              : "In Progress"}
                                      </p>
                                      <p className="text-sm">
                                        {tracker.end_time
                                          ? formatDateTime(tracker.end_time)
                                          : "----------------"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* Error Message section - only show for failed status */}
                                {tracker?.error_str && (
                                  <div className="p-4 bg-white rounded-lg shadow-md border border-red-200">
                                    <p className="text-xs font-bold uppercase tracking-wider text-red-500 mb-2">
                                      Error Message
                                    </p>
                                    <p className="text-sm text-red-600 font-mono bg-red-50 p-3 rounded">
                                      {tracker.error_str}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <PaginationControls />
          </div>

          {!progressTrackers ||
            (Object.keys(progressTrackers).length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-500 mb-4">
                  No available progress tracking data
                </p>
              </div>
            ))}
        </>
      )}
    </div>
  );
};
