export const processData = (data, rootDatasetSize = 0) => {
  let nodeId = 0;
  const rootNodeId = `node_${nodeId++}`;

  // Constants for layout
  const HORIZONTAL_SPACING = 250;
  const VERTICAL_SPACING = 150;
  const VALUE_HORIZONTAL_OFFSET = 100;
  const ROOT_Y_OFFSET = -200; // Only for root node

  // Initialize nodes with root at offset position
  const nodes = [
    {
      id: rootNodeId,
      type: "root",
      position: { x: 0, y: ROOT_Y_OFFSET }, // Only root gets the offset
      data: {
        label: "Root",
        datasetSize: rootDatasetSize,
        nodePath: [],
      },
    },
  ];
  const edges = [];

  // Track min and max X positions
  let minX = 0;
  let maxX = 0;

  // Rest of the tracking variables
  const levelRightmostX = new Map();
  const subtreeWidths = new Map();

  // First pass: Calculate subtree widths
  const calculateSubtreeWidth = (node, depth = 0) => {
    if (!node || typeof node !== "object") return 0;

    let width = 0;
    const isQuestion = depth % 2 === 0;

    if (isQuestion) {
      // For question nodes, sum up widths of all children
      Object.entries(node).forEach(([key, value]) => {
        if (key !== "TagAvailableValues") {
          width += calculateSubtreeWidth(value, depth + 1);
        }
      });
      width = Math.max(width, HORIZONTAL_SPACING); // Minimum width
    } else {
      // For value level, count available values
      const valueCount = node.TagAvailableValues?.length || 1;
      width = valueCount * (HORIZONTAL_SPACING + VALUE_HORIZONTAL_OFFSET);
    }

    subtreeWidths.set(nodeId, width);
    return width;
  };

  // Calculate initial subtree widths
  calculateSubtreeWidth(data);

  const processNode = (
    node,
    depth = 0,
    parentId = null,
    parentX = 0,
    currentPath = [],
  ) => {
    const isQuestion = depth % 2 === 0;

    if (!isQuestion) {
      if (node.TagAvailableValues) {
        const values = [
          ...new Set([
            ...Object.keys(node).filter((k) => k !== "TagAvailableValues"),
            ...node.TagAvailableValues,
          ]),
        ].sort();

        const totalWidth = values.length * HORIZONTAL_SPACING;
        const startX = parentX - totalWidth / 2 + HORIZONTAL_SPACING / 2;

        values.forEach((value, index) => {
          const xPos = startX + index * HORIZONTAL_SPACING;
          // Track min and max X positions
          minX = Math.min(minX, xPos);
          maxX = Math.max(maxX, xPos);

          const valueNodeId = `node_${nodeId++}`;
          const valuePath = [...currentPath, value];

          nodes.push({
            id: valueNodeId,
            type: "value",
            position: {
              x: xPos,
              y: depth * VERTICAL_SPACING,
            },
            data: {
              id: valueNodeId,
              label: value,
              isOutOfSync: !node.TagAvailableValues.includes(value),
              nodePath: valuePath,
              parentId,
            },
          });

          levelRightmostX.set(
            depth,
            Math.max(
              levelRightmostX.get(depth) || 0,
              xPos + HORIZONTAL_SPACING,
            ),
          );

          if (parentId) {
            edges.push({
              id: `edge_${parentId}_${valueNodeId}`,
              source: parentId,
              target: valueNodeId,
              type: "step",
            });
          }

          // Process child nodes
          if (value in node) {
            processNode(node[value], depth + 1, valueNodeId, xPos, valuePath);
          }
        });
      }
    } else {
      Object.entries(node).forEach(([key, value], index) => {
        if (key === "TagAvailableValues") return;

        const currentId = `node_${nodeId++}`;
        const newPath = [...currentPath, key];
        const subtreeWidth = subtreeWidths.get(nodeId) || HORIZONTAL_SPACING;

        const xPos =
          parentX +
          index * subtreeWidth -
          ((Object.keys(node).length - 1) * subtreeWidth) / 2;

        // Track min and max X positions
        minX = Math.min(minX, xPos);
        maxX = Math.max(maxX, xPos);

        nodes.push({
          id: currentId,
          type: "question",
          position: {
            x: xPos,
            y: depth * VERTICAL_SPACING,
          },
          data: {
            id: currentId,
            label: key,
            nodePath: newPath,
            parentId,
          },
        });

        levelRightmostX.set(
          depth,
          Math.max(levelRightmostX.get(depth) || 0, xPos + HORIZONTAL_SPACING),
        );

        edges.push({
          id: `edge_${parentId || rootNodeId}_${currentId}`,
          source: parentId || rootNodeId,
          target: currentId,
          type: "step",
        });

        if (value && typeof value === "object") {
          processNode(value, depth + 1, currentId, xPos, newPath);
        }
      });
    }
  };

  processNode(data);

  // Calculate the center point and offset needed
  const graphWidth = maxX - minX;
  const centerOffset = -graphWidth / 2 - minX;

  // Apply the centering offset to all nodes
  nodes.forEach((node) => {
    node.position.x += centerOffset;
  });

  return { nodes, edges };
};
