import { useState } from "react";
import { tokenService } from "../../../../services/api";
import {
  FormInput,
  LoadingSpinner,
  updateTokenServiceSecret,
  useToast,
  Toast,
} from "../ConfigUtils";
import { ProfileCard } from "./ProfileCard";
import { EditableProfileCard } from "./EditableProfileCard";
import { getDisplayType, DB_OPTIONS } from "./utils";
import { prepareVectorDBConfiguration } from "../../../../services/utils";

// Add default values from AbstractVectorDBManager
const DEFAULT_FILENAME_KEY = "filename";
const DEFAULT_TEXT_KEY = "text";
const DEFAULT_TAGS_KEY = "tags";
const DEFAULT_ID_KEY = "id";

// Add at the top with other constants
const POSTGRESQL_TYPE = "PSQLVectorDBManager";

export const VectorDBConfiguration = ({
  formState,
  setFormState,
  handleInputChange,
  handleSaveNewConfig,
  deasyUserConfig,
  setDeasyUserConfig,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const profiles = deasyUserConfig?.vdbmConfig?.Configs || {};
  const activeProfile = deasyUserConfig?.vdbmConfig?.LastActive;

  const { toast, showToast, hideToast } = useToast();

  const handleAddNew = () => {
    setEditingProfile(null);
    setProfileName("");
    setShowForm(true);
    setFormState({
      type: "",
      filename_key: DEFAULT_FILENAME_KEY,
      text_key: DEFAULT_TEXT_KEY,
      tags_key: DEFAULT_TAGS_KEY,
    });
  };

  const handleEdit = (profileName) => {
    const existingConfig = profiles[profileName];
    setEditingProfile(profileName);
    setProfileName(profileName);
    setShowForm(true);

    // Initialize form with existing config and default key values
    setFormState({
      ...existingConfig,
      filename_key: existingConfig.filename_key || DEFAULT_FILENAME_KEY,
      text_key: existingConfig.text_key || DEFAULT_TEXT_KEY,
      ...(existingConfig.type === POSTGRESQL_TYPE && {
        tags_key: existingConfig.tags_key || DEFAULT_TAGS_KEY,
      }),
    });
  };

  // Handlers
  const handleConfigLoad = (name) => {
    if (name === "new") {
      return;
    }

    const config = profiles[name];
    if (!config) return;

    // Update form state with the loaded config
    setFormState({
      ...config,
      filename_key: config.filename_key || DEFAULT_FILENAME_KEY,
      text_key: config.text_key || DEFAULT_TEXT_KEY,
      ...(config.type === POSTGRESQL_TYPE && {
        tags_key: config.tags_key || DEFAULT_TAGS_KEY,
      }),
    });

    const newConfig = {
      ...deasyUserConfig,
      vdbmConfig: {
        ...deasyUserConfig.vdbmConfig,
        LastActive: name,
        Configs: deasyUserConfig.vdbmConfig.Configs,
      },
    };
    setDeasyUserConfig(newConfig);
    updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
  };

  const validateFields = () => {
    const errors = [];

    if (!profileName?.trim()) {
      errors.push("Profile name is required");
    }

    if (
      profiles[profileName?.trim()] &&
      (!editingProfile || profileName.trim() !== editingProfile)
    ) {
      errors.push("A profile with this name already exists");
    }

    if (!formState.type) {
      errors.push("Please select a database type");
      return errors;
    }

    const dbConfig = DB_OPTIONS.find((opt) => opt.value === formState.type);
    if (!dbConfig) {
      errors.push("Invalid database type selected");
      return errors;
    }

    // Add validation for required keys - make these checks more strict
    if (!formState.filename_key?.trim()) {
      errors.push("Filename key is required");
    }
    if (!formState.text_key?.trim()) {
      errors.push("Text key is required");
    }
    // Only validate tags_key for PostgreSQL
    if (formState.type === POSTGRESQL_TYPE && !formState.tags_key?.trim()) {
      errors.push("Tags key is required for PostgreSQL");
    }

    // Validate all required fields from DB_OPTIONS
    dbConfig.fields.forEach((field) => {
      if (!formState[field]?.trim()) {
        errors.push(
          `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1")} is required`,
        );
      }
    });

    return errors;
  };

  const validateAndSave = async () => {
    const validationErrors = validateFields();
    if (validationErrors.length > 0) {
      showToast(validationErrors.join(", "), "error");
      return;
    }

    // Only proceed if we have valid keys
    if (
      !formState.filename_key?.trim() ||
      !formState.text_key?.trim() ||
      (formState.type === POSTGRESQL_TYPE && !formState.tags_key?.trim())
    ) {
      showToast("Required keys are missing", "error");
      return;
    }

    setIsValidating(true);
    setValidationError(null);

    try {
      const dbConfig = DB_OPTIONS.find((opt) => opt.value === formState.type);
      const config = dbConfig.fields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: formState[field]?.trim(),
        }),
        {
          type: formState.type,
          filename_key: formState.filename_key,
          text_key: formState.text_key,
          ...(formState.type === POSTGRESQL_TYPE && {
            tags_key: formState.tags_key,
          }),
        },
      );

      // First validate the configuration works
      const response = await tokenService.validateVectorDB(
        prepareVectorDBConfiguration(config),
      );

      if (!response.data.success) {
        showToast(response.data.message || "Validation failed", "error");
        return;
      }

      // If we get here, validation was successful
      handleSaveNewConfig("vectorDB", profileName.trim(), config);
      setShowForm(false);
      showToast("Vector database configuration saved successfully", "success");
    } catch (error) {
      // Handle error from validation endpoint
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message ||
        "Failed to validate configuration";
      showToast(`Validation failed: ${errorMessage}`, "error");
      return; // Prevent saving on validation failure
    } finally {
      setIsValidating(false);
    }
  };

  const getButtonText = () => {
    if (isValidating) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    }
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  const handleDeleteProfile = (name) => {
    if (
      window.confirm(
        `Are you sure you want to delete the profile "${name}"? This action cannot be undone.`,
      )
    ) {
      handleSaveNewConfig("vectorDB", name, null);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-700">
          Vector Database Profiles
        </h2>
        <button
          onClick={handleAddNew}
          className="px-4 py-2 bg-white text-primary border-2 border-primary rounded-md hover:bg-primary hover:text-white"
        >
          +
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(profiles).map(([name, config]) => {
          return (
            <ProfileCard
              key={name}
              type={getDisplayType(config.type)}
              name={name}
              isActive={name === activeProfile}
              onSelect={(name) => handleConfigLoad(name)}
              onEdit={handleEdit}
              onDelete={handleDeleteProfile}
              details={(() => {
                const dbConfig = DB_OPTIONS.find(
                  (opt) => opt.value === config.type,
                );
                if (!dbConfig) return {};

                return {
                  Type: getDisplayType(config.type),
                  ...dbConfig.fields.reduce(
                    (acc, field) => ({
                      ...acc,
                      [field.charAt(0).toUpperCase() +
                      field.slice(1).replace(/([A-Z])/g, " $1")]: field
                        .toLowerCase()
                        .includes("key")
                        ? "********"
                        : config[field] || "N/A",
                    }),
                    {},
                  ),
                };
              })()}
            />
          );
        })}

        {showForm && (
          <EditableProfileCard
            name={profileName}
            setName={setProfileName}
            isNew={!editingProfile}
            onSave={validateAndSave}
            onCancel={() => setShowForm(false)}
            validationError={validationError}
            isValidating={isValidating}
            saveButtonText={getButtonText()}
          >
            {/* Database Type Selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Database Type
              </label>
              <div className="space-y-2">
                {DB_OPTIONS.map((db) => (
                  <label
                    key={db.value}
                    className="flex items-center space-x-3 cursor-pointer"
                  >
                    <input
                      type="radio"
                      name="database"
                      value={db.value}
                      checked={formState.type === db.value}
                      onChange={(e) =>
                        handleInputChange("type", e.target.value)
                      }
                      className="form-radio h-5 w-5 text-emerald-600"
                    />
                    <span className="text-gray-700">{db.label}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Connection Details */}
            {formState.type && (
              <>
                <div className="mb-6">
                  <h3 className="font-medium text-gray-900 mb-4">
                    Connection Details
                  </h3>
                  {DB_OPTIONS.find(
                    (opt) => opt.value === formState.type,
                  )?.fields.map((field) => (
                    <FormInput
                      key={field}
                      label={
                        field.charAt(0).toUpperCase() +
                        field.slice(1).replace(/([A-Z])/g, " $1")
                      }
                      type={
                        field.toLowerCase().includes("key")
                          ? "password"
                          : "text"
                      }
                      value={formState[field] || ""}
                      onChange={(e) => handleInputChange(field, e.target.value)}
                      placeholder={`Enter your ${field
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()}`}
                    />
                  ))}
                </div>

                {/* Advanced Configuration */}
                {formState.type !== "S3DataSourceManager" && (
                  <div className="mb-6 pt-6 border-t border-gray-200">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="font-medium text-gray-900">
                        Advanced Configuration
                      </h3>
                      <button
                        type="button"
                        onClick={() => {
                          // Reset to defaults
                          handleInputChange(
                            "filename_key",
                            DEFAULT_FILENAME_KEY,
                          );
                          handleInputChange("text_key", DEFAULT_TEXT_KEY);
                          if (formState.type === POSTGRESQL_TYPE) {
                            handleInputChange("tags_key", DEFAULT_TAGS_KEY);
                          }
                        }}
                        className="text-sm text-primary hover:text-primary-dark"
                      >
                        Reset to Defaults
                      </button>
                    </div>
                    <p className="text-sm text-gray-500 mb-4">
                      These settings define how Deasy maps data in your vector
                      database. The default values work for most cases - only
                      modify if you have specific requirements for how document
                      content and metadata should be stored.
                    </p>
                    <FormInput
                      label="Filename Key"
                      type="text"
                      value={formState.filename_key || DEFAULT_FILENAME_KEY}
                      onChange={(e) =>
                        handleInputChange("filename_key", e.target.value)
                      }
                      placeholder="Enter filename key"
                      help="Field name used to store document filenames"
                    />
                    <FormInput
                      label="Text Key"
                      type="text"
                      value={formState.text_key || DEFAULT_TEXT_KEY}
                      onChange={(e) =>
                        handleInputChange("text_key", e.target.value)
                      }
                      placeholder="Enter text key"
                      help="Field name used to store document content"
                    />
                    {formState.type === POSTGRESQL_TYPE && (
                      <>
                        <FormInput
                          label="Tags Key"
                          type="text"
                          value={formState.tags_key || DEFAULT_TAGS_KEY}
                          onChange={(e) =>
                            handleInputChange("tags_key", e.target.value)
                          }
                          placeholder="Enter tags key"
                          help="PostgreSQL column name for storing metadata tags"
                        />
                        <FormInput
                          label="Id Key"
                          type="text"
                          value={formState.id_key || DEFAULT_ID_KEY}
                          onChange={(e) =>
                            handleInputChange("id_key", e.target.value)
                          }
                          placeholder="Enter id key"
                          help="PostgreSQL column name for the ID key"
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </EditableProfileCard>
        )}
      </div>
      <Toast
        message={toast.message}
        type={toast.type}
        show={toast.show}
        onClose={hideToast}
      />
    </div>
  );
};
