import axios from "axios";
import { auth } from "../config/firebase";
import { prepareVectorDBConfiguration } from "./utils";
const API_URL = process.env.REACT_APP_API_URL;

// Create single API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add authentication interceptor for console endpoints
const getAuthHeaders = async () => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
      "x-user": user.email,
    };
  }
  return {};
};

// Metadata specific headers
export const tokenService = {
  listTokens: async () =>
    api.get("/console/token/list", { headers: await getAuthHeaders() }),
  createToken: async (username) =>
    api.post(
      "/console/token/create",
      { username },
      { headers: await getAuthHeaders() },
    ),
  deleteToken: async (tokenId, softDelete = true) =>
    api.delete(`/console/token/${tokenId}`, {
      data: { soft_delete: softDelete },
      headers: await getAuthHeaders(),
    }),
  getSecret: async (secretName) =>
    api.post(
      `/console/secret/get`,
      { secret_name: secretName },
      { headers: await getAuthHeaders() },
    ),
  storeSecret: async (secretName, secretValue) =>
    api.post(
      `/console/secret/store`,
      { secret_name: secretName, secret_value: secretValue },
      { headers: await getAuthHeaders() },
    ),
  createPaymentSession: async (credits) =>
    api.post(
      "/console/payment/create-session",
      { credits },
      { headers: await getAuthHeaders() },
    ),
  verifyPayment: async (sessionId) =>
    api.post(
      "/console/payment/verify",
      { session_id: sessionId },
      { headers: await getAuthHeaders() },
    ),
  validateVectorDB: async (config) =>
    api.post("/console/vector-db/validate", config, {
      headers: await getAuthHeaders(),
    }),
  validateLLMProvider: async (providerConfig) =>
    api.post("/console/llm-provider/validate", providerConfig, {
      headers: await getAuthHeaders(),
    }),
  linkMarketplaceAccount: async (linkData) =>
    api.post("/console/marketplace/signup", linkData, {
      headers: await getAuthHeaders(),
    }),
  getSubscriptionInfo: async () =>
    api.get("/console/subscription", { headers: await getAuthHeaders() }),
};

export const metadataService = {
  getMetadataHealth: async (
    vectorDBConfiguration,
    check_sync,
    file_names,
    node_ids,
    tags,
    usecase_id,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata_health",
      {
        vector_db_config: vectorDBConfiguration,
        check_sync: check_sync,
        file_names: file_names,
        node_ids: node_ids,
        tags: tags,
        usecase_id: usecase_id,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  getUsecaseMetrics: async (
    fileNames = null,
    nodeIds = null,
    tags = null,
    usecaseId = null,
    deasyApiKey,
  ) =>
    api.post(
      "/usecase/metrics",
      {
        file_names: fileNames,
        node_ids: nodeIds,
        tags: tags,
        usecase_id: usecaseId,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getSyncScore: async (usecase_id, deasyApiKey) =>
    api.post(
      "/usecase/sync_score",
      {
        usecase_id: usecase_id,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getTagDistribution: async (usecase_id, deasyApiKey) =>
    api.post(
      "/usecase/tag_vdb_distribution",
      {
        usecase_id: usecase_id,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  createTag: async (tagData, deasyApiKey) =>
    api.post(
      "/tags/create",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getSavedTags: async (deasyApiKey) =>
    api.get("/tags/list", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  /**
   * Suggest tags based on context and selected options
   * @param {Object} vectorDBConfiguration - Vector DB configuration
   * @param {Object} endpointManagerConfig - Endpoint manager configuration
   * @param {Object} currentTree - Current metadata tree
   * @param {Object} node - Selected node information
   * @param {string} context - Input context
   * @param {string} scope - File or Chunk level scope
   * @param {Array} file_names - Selected file names
   * @param {number} numTags - Number of tags to generate
   * @param {string} tagType - Type of tags to generate
   * @param {number} maxDepth - Maximum depth of tag hierarchy
   * @param {string} deasyApiKey - API key
   * @param {Object} tagOptions - Tag generation options
   * @param {boolean} tagOptions.use_existing_tags - Use existing tags
   * @param {boolean} tagOptions.use_extracted_tags - Use extracted tags
   * @param {boolean} tagOptions.use_net_new_tags - Generate new tags
   * @param {string} usecase_id - Optional usecase ID
   */
  suggestTags: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    currentTree,
    node,
    context,
    scope,
    file_names,
    tagType,
    maxDepth,
    deasyApiKey,
    tagOptions = {},
    condition = null,
    resolution = 1,
  ) =>
    api.post(
      "/suggest_hierarchy",
      {
        vector_db_config: vectorDBConfiguration,
        endpoint_manager_config: endpointManagerConfig,
        current_tree: currentTree,
        node: node,
        context: context,
        use_file_level_tags: scope === "File",
        file_names: file_names,
        tag_type: tagType,
        max_height: maxDepth,
        use_existing_tags: tagOptions.use_existing_tags ?? false,
        use_extracted_tags: tagOptions.use_extracted_tags ?? false,
        condition: condition,
        resolution: resolution,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  updateTag: async (tagData, deasyApiKey) =>
    api.put(
      "/tags/update",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteTag: async (tagId, deasyApiKey) =>
    api.delete(`/tags/delete?tag_id=${tagId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  listPaginated: async (
    deasyApiKey,
    vectorDBConfiguration,
    limit = 50,
    offset = 0,
    groupBy = "file",
    entitiesAlreadyScrolled = [],
    filters = {},
  ) =>
    api
      .post(
        "/data/list_paginated",
        {
          vector_db_config: vectorDBConfiguration,
          limit,
          offset,
          group_by: groupBy,
          entities_already_scrolled: entitiesAlreadyScrolled,
          filters,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => ({
        entities: response.data.entities,
        next_offset: response.data.next_offset,
        total: response.data.total,
        error_str: response.data.error_str,
      })),
  trackJob: async (uuid, deasyApiKey, maxRetries = 5) => {
    let retryCount = 0;
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    while (true) {
      try {
        const response = await api.get(
          `/progress_tracker/task_status/${uuid}`,
          {
            headers: {
              "x-token": deasyApiKey,
              "x-user": auth.currentUser.email,
            },
          },
        );
        return response;
      } catch (error) {
        // If we've exhausted all retries, throw the error
        if (retryCount >= maxRetries) {
          console.error(
            `Failed to fetch task status after ${maxRetries} retries:`,
            error,
          );
          throw error;
        }

        // Calculate delay with exponential backoff and jitter
        const baseDelay = Math.pow(2, retryCount) * 1000; // 1s, 2s, 4s, 8s...
        const jitter = Math.random() * 1000; // Random delay between 0-1000ms
        const delay = baseDelay + jitter;

        console.warn(
          `Retry attempt ${retryCount + 1} for task ${uuid}.`,
          `Waiting ${delay}ms before next attempt.`,
          `Error: ${error.message}`,
        );

        // Wait before retrying
        await sleep(delay);
        retryCount++;
      }
    }
  },
  classify: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
    soft_run = false,
    overwrite = true,
    currentTree = {},
    jobId = null,
    generateFileLevelTags = false,
    usecaseId = null,
    blockingCall = false,
    filesFromUsecase = false,
  ) => {
    const requestBody = {
      vector_db_config: vectorDBConfiguration,
      endpoint_manager_config: endpointManagerConfig,
      file_names: fileNames,
      tags: tags,
      soft_run: soft_run,
      job_id: jobId,
      overwrite: overwrite,
      hierarchy: currentTree,
      usecase_id: usecaseId,
      generate_file_tags: generateFileLevelTags,
      files_from_usecase: filesFromUsecase,
    };

    const headers = {
      headers: {
        "x-token": deasyApiKey,
        "x-user": auth.currentUser.email,
      },
    };

    // The only difference was the 'await', so we can simplify this
    const request = api.post("/classify", requestBody, headers);

    if (blockingCall) {
      return await request;
    }
    return request;
  },
  generateFileLevelTags: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
  ) =>
    api
      .post(
        "/generate_file_tags",
        {
          vector_db_config: vectorDBConfiguration,
          endpoint_manager_config: endpointManagerConfig,
          file_names: fileNames,
          tags: tags,
          job_id: "client",
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        console.log("Headers:", {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        });
        return response;
      }),

  exportMetadata: async (requestBody, deasyApiKey) =>
    api
      .post("/usecase/export/metadata", requestBody, {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      })
      .then((response) => {
        console.log("Headers:", {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        });
        return response;
      }),
  getHierarchyCountDistributions: async (data, deasyApiKey) =>
    api.post("/metadata/count_distributions", data, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  exportToVectorDB: async (requestBody, deasyApiKey) =>
    api.post(
      "/usecase/export/vdb",
      {
        ...requestBody,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),

  createUseCase: async (useCaseData, deasyApiKey) => {
    // Transform selectedParentNodes to database format, only if not selecting all files
    const conditions = useCaseData.isAllFiles
      ? [] // Send empty array for conditions when selecting all files
      : useCaseData.selectedParentNodes.map((node) => {
          const nodePath = node.data.nodePath;
          let condition = {};
          // Process pairs of tag and value
          for (let i = 0; i < nodePath.length; i += 2) {
            const tag = nodePath[i];
            const value = nodePath[i + 1];
            if (tag && value) {
              condition[tag] = [value];
            }
          }
          return condition;
        });

    return api
      .post(
        "/usecase/create",
        {
          usecase_id: useCaseData.id,
          usecase_name: useCaseData.name,
          user_id: auth.currentUser.email,
          description: useCaseData.description,
          status: useCaseData.status,
          data_points: useCaseData.dataPoints,
          latest_graph: useCaseData.latestGraph,
          graph_id: useCaseData.graphId,
          last_updated: useCaseData.lastUpdated,
          condition: conditions, // Use the transformed conditions
          vector_db_config: useCaseData.vectorDBConfiguration,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      });
  },

  deleteUsecase: async (usecaseId, deasyApiKey) =>
    api.delete(`/usecase/delete?usecase_id=${usecaseId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  getUsecaseFileCount: async (
    vectorDBConfiguration,
    condition,
    usecaseId = null,
    deasyApiKey,
  ) =>
    api.post(
      "/usecase/file_count",
      {
        vector_db_config: vectorDBConfiguration,
        condition: condition,
        usecase_id: usecaseId,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getUniqueTags: async (
    deasyApiKey,
    fileNames = null,
    vector_db_config = null,
    usecase_id = null,
  ) =>
    api.post(
      "/metadata/get_unique_tags",
      {
        file_names: fileNames,
        vector_db_config: vector_db_config,
        usecase_id: usecase_id,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  classifyAll: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    tags,
    deasyApiKey,
    jobId = null,
    usecaseId = null,
    totalDataSets = null,
    generateFileLevelTags = true,
    hierarchy = null,
    conditions = null,
  ) => {
    const requestBody = {
      vector_db_config: vectorDBConfiguration,
      endpoint_manager_config: endpointManagerConfig,
      tags: tags,
      job_id: jobId,
      generate_file_level_tags: generateFileLevelTags,
      usecase_id: usecaseId,
      hierarchy: hierarchy,
      conditions: conditions,
    };

    // Only add total_data_sets if it's not null
    if (totalDataSets !== null) {
      requestBody.total_data_sets = totalDataSets;
    }

    api.post("/classify/all", requestBody, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    });
    return jobId;
  },

  listUsecases: async (deasyApiKey) => {
    const response = await api.get(`/usecase/list`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    });

    const transformedUsecases = response.data.usecases.map((usecase) => {
      const selectedParentNodes =
        usecase.condition?.map((condition) => {
          const nodePath = Object.entries(condition).reduce(
            (path, [key, values]) => {
              return [...path, key, values[0]];
            },
            [],
          );

          return {
            data: { nodePath: nodePath },
            stats: {},
          };
        }) || [];

      return {
        id: usecase.usecase_id,
        name: usecase.usecase_name,
        description: usecase.description,
        status: usecase.status || "active",
        dataPoints: usecase.data_points,
        graphId: usecase.graph_id,
        latestGraph: usecase.latest_graph,
        lastUpdated: usecase.last_updated,
        selectedParentNodes,
        vdb_collection_name: usecase.vdb_collection_name,
        vector_db_config: usecase.vector_db_config,
      };
    });

    return transformedUsecases;
  },

  getTagStatistics: async (
    vectorDBConfiguration,
    tagIds,
    deasyApiKey,
    conditions = [],
  ) =>
    api.post(
      "/metadata/tag_statistics",
      {
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        tag_ids: tagIds,
        conditions: conditions,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  filterByConditions: async (
    vectorDBConfiguration,
    conditions,
    analyzeRemainingTags,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/conditional_filter",
      {
        vector_db_config: vectorDBConfiguration,
        conditions: conditions,
        analyze_remaining_tags: analyzeRemainingTags,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getFileTags: async (vectorDBConfiguration, fileNames, deasyApiKey) =>
    api.post(
      "/metadata/file/list",
      {
        file_names: fileNames,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),

  getDocumentText: async (vectorDBConfiguration, fileNames, deasyApiKey) =>
    api.post(
      "/data/document_text",
      {
        file_names: fileNames,
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getVdbTotalFiles: async (
    vectorDBConfiguration,
    deasyApiKey,
    limit = 50,
    scrollLimit = 5000,
    offset = 0,
    groupBy = "file",
    hardLimit = null,
  ) =>
    api.post(
      "/data/file_count",
      {
        vector_db_config: vectorDBConfiguration,
        limit: limit,
        scroll_limit: scrollLimit,
        offset: offset,
        group_by: groupBy,
        hard_limit: hardLimit,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getTagEvidence: async (
    vectorDBConfiguration,
    fileName,
    tagName,
    value,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/get_evidence",
      {
        vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
        filename: fileName,
        tag_id: tagName,
        tag_value: value,
      },
      {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      },
    ),

  getUsecaseFiles: async (usecaseId, apiKey) => {
    return await api.post(
      "/usecase/files",
      {
        usecase_id: usecaseId,
      },
      {
        headers: {
          "x-token": apiKey,
          "x-user": auth.currentUser.email,
        },
      },
    );
  },

  listMetadata: async (
    vectorDBConfiguration,
    deasyApiKey,
    groupBy = "file",
    metadataKeys = null,
    fileNames = null,
    pointIds = null,
    limit = null,
  ) => {
    const response = await api.post(
      "/data/metadata/list",
      {
        vector_db_config: vectorDBConfiguration,
        group_by: groupBy,
        metadata_keys: metadataKeys,
        file_names: fileNames,
        point_ids: pointIds,
        limit: limit,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    );
    return response.data;
  },

  getDistinctValues: async (vectorDBConfiguration, deasyApiKey, tagId) =>
    api.post(
      "/metadata/distinct_values",
      {
        vector_db_config: vectorDBConfiguration,
        tag_id: tagId,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getBasicMetadata: async (vectorDBConfiguration, deasyApiKey) =>
    api.post(
      "/metadata/basic",
      {
        vector_db_config: vectorDBConfiguration,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),

  getFilteredMetadata: async (
    vectorDBConfiguration,
    conditions,
    offset = 0,
    limit = 50,
    sortBy = null,
    sortOrder = "asc",
    deasyApiKey,
    usecase_id = null,
  ) =>
    api.post(
      "/metadata/filtered",
      {
        vector_db_config: vectorDBConfiguration,
        conditions: conditions,
        offset: offset,
        limit: limit,
        sort_by: sortBy,
        sort_order: sortOrder,
        usecase_id: usecase_id,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getDistributions: async (
    vectorDBConfiguration,
    deasyApiKey,
    usecase_id = null,
  ) =>
    api.post(
      "/metadata/distributions",
      {
        vector_db_config: vectorDBConfiguration,
        usecase_id: usecase_id,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  listMetadataPostgres: async (
    vectorDBConfiguration,
    deasyApiKey,
    groupBy = "file",
    metadataKeys = null,
    fileNames = null,
    pointIds = null,
    limit = null,
  ) => {
    const response = await api.post(
      "/metadata/chunk/list",
      {
        vector_db_config: vectorDBConfiguration,
        group_by: groupBy,
        metadata_keys: metadataKeys,
        file_names: fileNames,
        point_ids: pointIds,
        limit: limit,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    );
    return response.data;
  },
};

export const graphService = {
  createUpdateGraph: async (
    deasyApiKey,
    graph_id = null,
    graph_name = null,
    graph_description = null,
    graph_data = null,
  ) =>
    api
      .post(
        "/graph/upsert",
        {
          graph_id,
          graph_name,
          graph_description,
          graph_data,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  getGraphs: async (deasyApiKey, graphIds = null) =>
    api
      .post(
        `/graph/list`,
        { graph_ids: graphIds },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  deleteGraph: async (graphId, deasyApiKey) =>
    api.delete(`/graph/delete?graph_id=${graphId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
};

export const progressTrackingService = {
  getProgressTrackers: async (deasyApiKey) =>
    api
      .get("/progress_tracker/get_progress_trackers", {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      })
      .then((response) => {
        return response;
      }),
  abortProgressTracker: async (trackerId, deasyApiKey) =>
    api
      .put(
        `/progress_tracker/abort_progress_tracker/${trackerId}`,
        {},
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => response),
  deleteProgressTrackers: async (trackerIds, deasyApiKey) =>
    api
      .delete(`/progress_tracker/delete_progress_trackers`, {
        data: { tracker_ids: trackerIds },
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      })
      .then((response) => {
        return response;
      }),
};

export const unstructuredService = {
  ingestUnstructuredData: async (
    deasyApiKey,
    llmEndpointConfiguration,
    dataSourceDBConfiguration,
    jobId,
    fileNames = null,
  ) =>
    api
      .post(
        "/ocr/ingest",
        {
          endpoint_manager_config: llmEndpointConfiguration,
          data_source_manager_config: dataSourceDBConfiguration,
          file_names: fileNames,
          job_id: jobId,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => response),
  getSyncStats: async (deasyApiKey, dataSourceDBConfiguration) =>
    api.post(
      "/ocr/sync_stats",
      {
        data_source_manager_config: dataSourceDBConfiguration,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};
