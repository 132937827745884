import React, { useState, useContext, useEffect } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { metadataService } from "../../../../../../services/api";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import MetadataTable from "../../MetadataTable/MetadataTable";
import LoadingSpinner from "../../../../Utils/LoadingSpinner";

const ViewMetadataTab = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const { selectedDiscoveryGraph } = useContext(GraphContext);
  const [loading, setLoading] = useState(true);
  const [metadataData, setMetadataData] = useState(null);
  const [error, setError] = useState(null);
  const [tableFilters, setTableFilters] = useState({});

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        setLoading(true);
        setError(null);

        const activeVdbConfig =
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ];
        if (!activeVdbConfig) {
          throw new Error("No active Vector DB configuration found");
        }

        // Use the new lightweight endpoint
        const response = await metadataService.getBasicMetadata(
          prepareVectorDBConfiguration(activeVdbConfig),
          deasyUserConfig.deasyApiKey,
        );

        setMetadataData(response.data);
      } catch (err) {
        console.error("Error fetching metadata:", err);
        setError(err.message || "Failed to fetch metadata");
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [deasyUserConfig, selectedDiscoveryGraph]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="text-red-500 mb-2">Error: {error}</div>
          <button
            onClick={() => window.location.reload()}
            className="text-primary hover:text-primary/80"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col -mx-6 -my-5">
      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <LoadingSpinner text="Loading metadata..." />
        </div>
      ) : (
        <div className="flex h-full px-6 py-5 w-full">
          <MetadataTable
            data={metadataData}
            backendFilters={tableFilters}
            onFiltersChange={setTableFilters}
          />
        </div>
      )}
    </div>
  );
};

export default ViewMetadataTab;
